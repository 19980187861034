import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MaterialEditModal = ({ isEOpen, onEClose, fetchStockData }) => {
  const [formData, setFormData] = useState({
    materialtype: '', // Set when a material is selected
    newMaterialName: '', // User-typed input
  });
  const [selectedMaterialOldName, setSelectedMaterialOldName] = useState('');
  const [materialOptions, setMaterialOptions] = useState([]);
  const [materialTypeOptions, setMaterialTypeOptions] = useState([]);
  const [allMaterialData, setAllMaterialData] = useState([]);

  // Fetch material data when the modal is opened
  useEffect(() => {
    if (isEOpen) {
      const fetchMaterialData = async () => {
        try {
          const response = await axios.get(`${config.apiUrl}/stock/getOilData`);

          if (response.data) {
            setAllMaterialData(response.data);

            const materials = response.data.map(item => item.oilname);
            const types = [...new Set(response.data.map(item => item.materialtype))];

            setMaterialOptions(materials);
            setMaterialTypeOptions(types);
          } else {
            console.error('Unexpected response format for materials and types:', response.data);
          }
        } catch (error) {
          console.error('Error fetching material data:', error);
        }
      };

      fetchMaterialData();
    }
  }, [isEOpen]);

  const handleMaterialChange = (event) => {
    const selectedMaterialName = event.target.value;
    const materialData = allMaterialData.find(item => item.oilname === selectedMaterialName) || {};

    setFormData({
      ...formData,
      materialtype: materialData.materialtype || '',
      newMaterialName: '' // Clear user-typed input
    });
    setSelectedMaterialOldName(selectedMaterialName);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'newMaterialName' && /\d/.test(value)) {
      return; // Prevent numeric values in material name
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.newMaterialName || selectedMaterialOldName === formData.newMaterialName) {
      toast.error('Material name must be changed and cannot be empty.');
      return;
    }

    try {
      const materialData = allMaterialData.find(item => item.oilname === selectedMaterialOldName) || {};
      await axios.put(`${config.apiUrl}/stock/updateMaterial/${materialData.id}`, {
        oilname: formData.newMaterialName,
        materialtype: formData.materialtype
      });
      toast.success('Material Edited successfully!');
      fetchStockData(); // Refetch stock data to update the list
      onEClose(); // Close the modal
    } catch (error) {
      console.error('Error updating material:', error);
    }
  };

  if (!isEOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg relative">
        <h3 className="text-lg font-semibold mb-4">Edit Material</h3>
        <button onClick={onEClose} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1" htmlFor="materialname">Material</label>
            <select
              id="materialname"
              name="materialname"
              value={selectedMaterialOldName}
              onChange={handleMaterialChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            >
              <option value="">Select Material</option>
              {materialOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1" htmlFor="materialtype">Material Type</label>
            <select
              id="materialtype"
              name="materialtype"
              value={formData.materialtype}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Material Type</option>
              {materialTypeOptions.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1" htmlFor="newMaterialName">Edit Material Name</label>
            <input
              id="newMaterialName"
              name="newMaterialName"
              value={formData.newMaterialName}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-3 py-1.5 text-sm  bg-rose-500 hover:bg-rose-600 transition text-white rounded-md"
            >
              Save Changes
            </button>
            
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaterialEditModal;
