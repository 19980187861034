import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faMapMarkerAlt, faReceipt, faEnvelope, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ksr from '../../assets/ksr.jpg';

const PurchaseTemplate = ({ showTemplate, setShowTemplate, setShowPoForm,Data}) => {
  const templateRef = useRef(null);

  const handleDownload = async () => {
    const element = templateRef.current;
    const canvas = await html2canvas(element, { scale: 2 }); 
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('invoice-template.pdf');
  };

  const formatCurrency = (value) => {
    return typeof value === 'number' ? `₹${value.toFixed(2)}` : '₹0.00';
  };
  const reset = () => {
    setShowTemplate(false)
    setShowPoForm(false)
};
  return (
    showTemplate && Data && (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex justify-center items-center">
        <div
          className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl mx-4 border border-gray-300"
          ref={templateRef}
        >
          <button
            onClick={() => 
              reset()
            }
            className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition duration-150"
            title="Close"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <div className="p-4 flex justify-between items-center bg-blue-800 text-white rounded-md mb-4">
            <div className="flex items-center space-x-2">
              <img src={ksr} alt="Company Logo" className="h-10 w-10" />
            </div>
            <h1 className="text-2xl font-bold">Invoice</h1>
          </div>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-1">Bill To:</h3>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faUser} className="mr-1" />
                {Data.billTo.name}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                {Data.billTo.address}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faReceipt} className="mr-1" />
                {Data.billTo.gst}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                {Data.billTo.email}
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-1">Ship To:</h3>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faUser} className="mr-1" />
                {Data.shipTo.name}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                {Data.shipTo.address}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faReceipt} className="mr-1" />
                {Data.shipTo.gst}
              </p>
              <p className="text-sm text-gray-700">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                {Data.shipTo.email}
              </p>
            </div>
          </div>
          <div className="overflow-x-auto mt-4 mb-4">
            <h3 className="text-lg font-semibold text-blue-800 mb-2">Order Items:</h3>
            <table className="min-w-full bg-white border text-xs">
              <thead className="bg-gray-200 text-gray-600 uppercase font-medium">
                <tr>
                  <th className="py-2 px-2 border-b">Description</th>
                  <th className="py-2 px-2 border-b">HSN Code</th>
                  <th className="py-2 px-2 border-b">Qty</th>
                  <th className="py-2 px-2 border-b">UOM</th>
                  <th className="py-2 px-2 border-b">Rate</th>
                  <th className="py-2 px-2 border-b">Tax %</th>
                  <th className="py-2 px-2 border-b">Amount</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-center">
                {Data.orderItems.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-1 px-2 border-b">{item.description}</td>
                    <td className="py-1 px-2 border-b">{item.hsnCode}</td>
                    <td className="py-1 px-2 border-b">{item.qty}</td>
                    <td className="py-1 px-2 border-b">{item.uom}</td>
                    <td className="py-1 px-2 border-b">{formatCurrency(item.itemRate)}</td>
                    <td className="py-1 px-2 border-b">{item.taxPercent || 0}</td>
                    <td className="py-1 px-2 border-b">
                      {formatCurrency(parseFloat(item.qty || 0) * parseFloat(item.itemRate || 0))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="bg-blue-50 p-4 rounded-lg text-sm mb-4">
            <p className="text-gray-800 mb-1">
              <strong>Total Quantity:</strong> {Data.totalQty || 0}
            </p>
            <p className="text-gray-800 mb-1">
              <strong>Total Amount:</strong> {formatCurrency(Data.totalAmount)}
            </p>
            <p className="text-gray-800 mb-1">
              <strong>Total Tax:</strong> {formatCurrency(Data.totalTax)}
            </p>
            <p className="text-gray-800 mb-1">
              <strong>Order Total:</strong> {formatCurrency(Data.orderTotal)}
            </p>
          </div>
          <div className="flex justify-between items-center mt-4">
            <button
              type="button"
              onClick={handleDownload}
              className="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded-md shadow-md text-sm transition duration-150"
            >
              <FontAwesomeIcon icon={faArrowDown} className="mr-2" />
              Download PDF
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PurchaseTemplate;
