import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const [formData, setFormData] = useState({
    sendersName: '',
    sendersContactNo: '',
    sendersMailAddress: '',
    authorizationNumber: '',
    manifestDocumentNo: '',
    transportersName: '',
    transportersContactNo: '',
    transportersMailAddress: '',
    typeOfVehicle: '',
    transporterRegistrationNumber: '',
    vehicleRegistrationNo: '',
    receiversName: '',
    receiversContactNo: '',
    receiversMailAddress: '',
    receiversAuthorizationNumber: '',
    physicalForm: '',
    totalQuantity: '',
    noOfContainers: '',
    wasteDescriptionName: '',
    specialHandlingInstructions: '',
    additionalInformation: '',
    attachedFile: null,
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
        
      await  axios.post(`${config.apiUrl}/kspcb/submit`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast.success('Form submitted successfully!');
    } catch (error) {
      toast.error('Failed to submit the form.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit} className="space-y-6">

        <div className="card p-6 bg-gradient-to-r from-blue-100 to-blue-200 shadow-lg rounded-lg text-gray-700">
          <h2 className="text-2xl font-bold mb-4">Sender Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium">Sender's Name
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="sendersName"
                value={formData.sendersName}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Sender's Contact No
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="sendersContactNo"
                value={formData.sendersContactNo}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Sender's Mail Address
              <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="sendersMailAddress"
                value={formData.sendersMailAddress}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Authorization Number
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="authorizationNumber"
                value={formData.authorizationNumber}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Manifest Document No
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="manifestDocumentNo"
                value={formData.manifestDocumentNo}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
          </div>
        </div>

        <div className="card p-6 bg-gradient-to-r from-green-100 to-green-200 shadow-lg rounded-lg text-gray-700">
          <h2 className="text-2xl font-bold mb-4">Transporters Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium">Transporter's Name
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="transportersName"
                value={formData.transportersName}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Transporter's Contact No
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="transportersContactNo"
                value={formData.transportersContactNo}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Transporter's Mail Address
              <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="transportersMailAddress"
                value={formData.transportersMailAddress}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Type of vehicle
              <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="radio"
                  name="typeOfVehicle"
                  value="Truck"
                  onChange={handleChange}
                  required
                /> Truck
                <input
                  type="radio"
                  name="typeOfVehicle"
                  value="Tanker"
                  onChange={handleChange}
                  className="ml-4"
                /> Tanker
                <input
                  type="radio"
                  name="typeOfVehicle"
                  value="Special Vehicle"
                  onChange={handleChange}
                  className="ml-4"
                /> Special Vehicle
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium">Transporter Registration Number
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="transporterRegistrationNumber"
                value={formData.transporterRegistrationNumber}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Vehicle Registration No
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="vehicleRegistrationNo"
                value={formData.vehicleRegistrationNo}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
          </div>
        </div>

        <div className="card p-6 bg-gradient-to-r from-red-100 to-red-200 shadow-lg rounded-lg text-gray-700">
          <h2 className="text-2xl font-bold mb-4">Receiver Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium"> Receiver's Name
              <span className="text-red-500">*</span></label>
              <input
                type="text"
                name="receiversName"
                value={formData.receiversName}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Receiver's Contact No
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="receiversContactNo"
                value={formData.receiversContactNo}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Receiver's Mail Address
              <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="receiversMailAddress"
                value={formData.receiversMailAddress}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Receiver's Authorization Number
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="receiversAuthorizationNumber"
                value={formData.receiversAuthorizationNumber}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
          </div>
        </div>

        <div className="card p-6 bg-gradient-to-r from-yellow-200 to-yellow-200 shadow-lg rounded-lg text-gray-700">
          <h2 className="text-2xl font-bold mb-4">Waste Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
              <label className="block text-sm font-medium text-gray-700">Physical Form
              <span className="text-red-500">*</span>
              </label>
              <div className="mt-4 text-sm">
                <input
                  type="radio"
                  name="physicalForm"
                  value="Solid"
                  onChange={handleChange}
                  required
                /> Solid
                <input
    type="radio"
    name="physicalForm"
    value="Semi-Solid"
    onChange={handleChange}
     className="ml-4"
  /> Semi-Solid
   <input
    type="radio"
    name="physicalForm"
    value="Sludge"
    onChange={handleChange}
     className="ml-4"
  /> Sludge
  <input
    type="radio"
    name="physicalForm"
    value="Oily"
    onChange={handleChange}
     className="ml-4"
  /> Oily
   <input
    type="radio"
    name="physicalForm"
    value="Tarry"
    onChange={handleChange}
     className="ml-4"
  /> Tarry
  <input
    type="radio"
    name="physicalForm"
    value="Slurry"
    onChange={handleChange}
     className="ml-4"
  /> Slurry
                <input
                  type="radio"
                  name="physicalForm"
                  value="Liquid"
                  onChange={handleChange}
                  className="ml-4"
                /> Liquid
               
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium">Total Quantity
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="totalQuantity"
                value={formData.totalQuantity}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">No. of Containers
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="noOfContainers"
                value={formData.noOfContainers}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Waste Description Name
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="wasteDescriptionName"
                value={formData.wasteDescriptionName}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Special Handling Instructions
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="specialHandlingInstructions"
                value={formData.specialHandlingInstructions}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Additional Information
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="additionalInformation"
                value={formData.additionalInformation}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-white"
              />
            </div>
            
          </div>
        </div>

        <div className="w-full bg-white border-gray-300 pt-4">
  <div className="container mx-auto px-4 flex items-center space-x-2">
    <div className="flex-1">
      <label className="block text-sm font-medium mb-2">Upload File</label>
      <input
        type="file"
        name="attachedFile"
        onChange={handleChange}
        className="w-1/4 p-2 border border-gray-300 rounded-md bg-white"
      />
    </div>
    <div className='mt-6'>
      <button
        type="submit"
        className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition duration-300"
      >
        Submit
      </button>
    </div>
  </div>
</div>


        
      </form>
    </div>
  );
};

export default Form;
