import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuotationSubmission = () => {
  const [piDetails, setPiDetails] = useState([]);
  const [selectedPi, setSelectedPi] = useState(null);
  const [quotations, setQuotations] = useState([null, null, null]); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Refs for file inputs
  const fileInputRefs = [useRef(null), useRef(null), useRef(null)];


    const fetchPiDetails = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/purchase/pidetails`);
        setPiDetails(response.data);
        console.log(response.data,'pidetails');
        
      } catch (err) {
        console.error('Error fetching PI details:', err);
      }
    };
    useEffect(() => {
    fetchPiDetails();
  }, []);

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const newQuotations = [...quotations];
      newQuotations[index] = file;
      setQuotations(newQuotations);
    }
  };

  const handlePiSelect = (pi) => {
    setSelectedPi(pi);
    setQuotations([null, null, null]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const selectedQuotations = quotations.filter(Boolean);
  
    if (selectedQuotations.length < 1 || selectedQuotations.length > 3) {
      toast.error('Please upload at least 1 file and a maximum of 3 files.');
      return;
    }
  
    if (!selectedPi) {
      toast.error('Please select a Purchase Intent (PI) to submit quotations.');
      return;
    }
  
    const formData = new FormData();
    formData.append('piDetails', JSON.stringify({
      id:selectedPi.ID,
      RawMaterial: selectedPi.RawMaterial,
      Qty: selectedPi.Qty,
      TankName: selectedPi.TankName,
      RequestTime: selectedPi.RequestTime,
      PiNo: selectedPi.PiNo,
      Planthead: selectedPi.Planthead, 
    }));
  
    selectedQuotations.forEach((quotation, index) => {
      formData.append(`quotation`, quotation);
    });
  
    setIsLoading(true);
    try {
      const response = await axios.post(`${config.apiUrl}/purchasedep/submit-quotations`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Quotations submitted successfully:', response.data);
      toast.success('Quotations submitted successfully!');
      setQuotations([null, null, null]);
      setSelectedPi(null);
      fetchPiDetails()
    } catch (error) {
      console.error('Error submitting quotations:', error);
      toast.error('Failed to submit quotations. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleRemoveFile = (index) => {
    const newQuotations = [...quotations];
    newQuotations[index] = null; 
    setQuotations(newQuotations);

    if (fileInputRefs[index].current) {
      fileInputRefs[index].current.value = '';
    }
  };
  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }
  
  return (
    <div className="container mx-auto p-2 m-2 bg-white shadow-md rounded-lg">
      <h1 className="text-center text-2xl font-bold mb-4 text-[#5F071C] font-montserrat">Quotation Submission</h1>
  
      <h2 className="font-bold mb-2 text-[#3E3E85]">Purchase Intent Request</h2>
  
      <table className="min-w-full bg-white border border-gray-300 rounded-lg text-xs text-center">
        <thead className='bg-teal-600 text-gray-100'>
          <tr>
            <th className="border px-4 py-2">Select</th>
            <th className="border px-4 py-2">Raw Material</th>
            <th className="border px-4 py-2">Quantity</th>
            <th className="border px-4 py-2">Tank Name</th>
            <th className="border px-4 py-2">Request Time</th>
            <th className="border px-4 py-2">Plant Head</th>
            <th className="border px-4 py-2">PI.No</th>
          </tr>
        </thead>
        <tbody>
          {piDetails.length === 0 ? (
            <tr>
              <td colSpan={7} className="text-center text-sm p-2">
              Purchase Intent Request Not Found
              </td>
            </tr>
          ) : (
            piDetails.map((pi) => (
              <tr key={pi.ID} 
              className={`${selectedPi && selectedPi.ID === pi.ID ? 'bg-blue-100' : ''} ${pi.is_Q_Ad  ? 'bg-gray-100' : ''}`}>
                <td className="border px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={selectedPi && selectedPi.ID === pi.ID}
                    onChange={() => handlePiSelect(pi)}
                    disabled={pi.is_Q_Ad} // Disable the checkbox if is_Q_Ad is true
                  />
                </td>
                <td className="border px-4 py-2">{pi.RawMaterial}</td>
                <td className="border px-4 py-2">{pi.Qty}</td>
                <td className="border px-4 py-2">{pi.TankName}</td>
                <td className="border px-4 py-2">{new Date(pi.RequestTime).toLocaleString()}</td>
                <td className="border px-4 py-2">{pi.Planthead}</td>
                <td className="border px-4 py-2">{pi.PiNo}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
  
      {selectedPi && (
        <div className="mt-6">
          <h2 className="font-bold text-lg mb-4 text-[#aca3a3] text-center">Upload Quotations for {selectedPi.RawMaterial}</h2>
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="flex flex-col gap-4 mb-4">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="flex items-center">
                  <label className="mr-2 text-[#084373] font-semibold text-sm">Upload File {index + 1}</label>
                  <input
                    ref={fileInputRefs[index]} 
                    type="file"
                    accept=".pdf,image/*"
                    onChange={(e) => handleFileChange(index, e)}
                    className="w-46 p-1 border border-gray-300 rounded text-sm"
                  />
                  {quotations[index] && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                      className="ml-2 text-red-500 text-sm underline"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className={`px-2 py-1.5 font-bold text-xs text-white bg-[#438FD1] hover:bg-blue-900 rounded shadow transition duration-200 ease-in-out ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
  
}  

export default QuotationSubmission;
