import React from 'react';
import Chart from 'react-apexcharts';

const TankChart = ({ stockData, lastUpdatedDate }) => {
  const seriesData = [
    {
      name: "Used Space",
      type: 'column',
      data: stockData.map(tank => tank.currentquantity || 0),
      color: '#FF8C00',
    },
    {
      name: "Free Space",
      type: 'column',
      data: stockData.map(tank => (tank.capacity || 0) - (tank.currentquantity || 0)),
      color: '#00CED1',
    },
    {
      name: "Tank Capacity",
      type: 'line',
      data: stockData.map(tank => tank.capacity || 0),
      color: '#8b77da',
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      height: '90%',
      width: '90%',
      toolbar: {
        show: true, 
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: stockData.length > 20 ? '100%' : '100%', // Adjust column width based on data length
        borderRadius: 4,
        dataLabels: {
          position: 'center',
        },
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#FF8C00', '#00CED1', '#8b77da'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: stockData.map(tank => `${tank.tankname} (${tank.oilname})`),
      labels: {
        rotate: -60,
        rotateAlways: true,
        style: {
          fontSize: stockData.length > 20 ? '8px' : '8px', // Adjust font size for large data
          fontWeight: 'bold',
          colors: '#333',
        },
      },
      tickPlacement: 'on', // Align ticks with bars
    },
    
    yaxis: {
      title: {
        text: 'Quantity (Kilo Litres)',
        style: {
          fontSize: '11px',
          fontWeight: 'bold',
          color: '#333',
        },
      },
      labels: {
        formatter: function (val) {
          return val !== null && val !== undefined ? `${val.toFixed(2)}` : '0.00';
        },
      },
      min: 0,
      max: Math.max(...stockData.map(tank => tank.capacity || 0)) + 5,
      tickAmount: 12,
    },
    legend: {
      // position: 'bottom',
      // horizontalAlign: 'center',
      // floating: true,
      // fontSize: '10px', // Adjust font size for better fit
      // fontWeight: 'bold',
      // labels: {
      //   colors: ['#333'],
      //   useSeriesColors: true,
      // },
      // itemMargin: {
      //   horizontal: 10,
      // },
      show:false
    },
    title: {
      text: 'Tank Capacity',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function (val, opts) {
          const tankData = stockData[opts.dataPointIndex];
          return `${tankData.tankname} (${tankData.oilname})`;
        },
      },
      y: {
        formatter: function (val) {
          return val !== null && val !== undefined ? `${val.toFixed(2)} KL` : '0.00 KL';
        },
      },
    },
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleString('en-GB', options);
  };

  return (
    <div className="w-full h-full bg-white relative overflow-x-auto">
      <div className="absolute top-3 right-2 m-4 text-xs font-bold flex items-center">
        Last Updated:&nbsp;
        <div>{formatDate(lastUpdatedDate)}</div>
      </div>
      <Chart options={options} series={seriesData} type="bar" height="100%" />
    </div>
  );
};

export default TankChart;
