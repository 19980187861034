import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import SkeletonLoader from '../SkeletonLoader';
import StockList from './StockList';
import StockEditModal from './StockEditModal';
import StockAddModal from './StockAddModal';
import AddTankModal from './AddTank';
import MaterialModal from './MaterialModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FaDownload } from 'react-icons/fa';
import MaterialEditModal from './MaterialEditModal';
import Download from './Download';

const StockTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingStock, setEditingStock] = useState(false);
  const [addingStock, setAddingStock] = useState(false);
  const [tankModalOpen, setTankModalOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [materialToEdit, setMaterialToEdit] = useState(false);
  const [isDownloadOpen,setIsDownloadopen] = useState(false);

  const [formData, setFormData] = useState({
    tankname: '',
    oilname: '',
    currentquantity: '',
    materialtype: '',
    radius: '',
    capacity: '',
  });
  const [tankOptions, setTankOptions] = useState([]);

  const fetchStockData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/getstockData`);
      if (response.data && Array.isArray(response.data.data)) {
        setStockData(response.data.data);
        console.log('stock table',response.data.data);
        
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTankOptions = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
      if (response.data && Array.isArray(response.data.data)) {
        setTankOptions(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching tank options:', error);
    }
  };

  useEffect(() => {
    fetchStockData();
    fetchTankOptions();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStockData = stockData.filter((item) =>
    Object.values(item).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleEditClick = (item) => {
    setFormData({
      tankname: item.tankname,
      oilname: item.oilname,
      currentquantity: 0,
      materialtype: item.materialtype,
      radius: item.tankradius || '',
      capacity: item.capacity || '',
    });
    setEditingStock(true);
  };

  const handleAddClick = () => {
    setFormData({
      tankname: '',
      oilname: '',
      currentquantity: 0,
      materialtype: '',
      radius: '',
      capacity: '',
    });
    setAddingStock(true);
  };

  const handleOpenTankModal = () => {
    setTankModalOpen(true);
  };

  const handleCloseTankModal = () => {
    setTankModalOpen(false);
  };
const handleOpenMaterialModal = () => {
  setMaterialOpen(true);
}
const handleCloseMaterialModal = () => {
  setMaterialOpen(false);
}
const handleEditMaterial = () => {
  setMaterialToEdit(true); 
};

const handleCloseEditMaterial = () => {
  setMaterialToEdit(false);
}
const opendown = () => setIsDownloadopen(true);
const closedown = () => setIsDownloadopen(false);
  return (
    <div className="stock-table w-full p-4 bg-gray-100 min-h-screen">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded-lg bg-white">
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <div className="flex items-center space-x-4">
                <h3 className="font-bold text-lg text-gray-900 uppercase mr-4">Stock</h3>
                <input
                  type="text"
                  placeholder="Search..."
                  className="search-input px-2 py-1 border border-gray-300 text-gray-900 rounded-lg focus:ring focus:ring-blue-300 focus:border-blue-500"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <button
                  onClick={handleAddClick}
                  className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-green-600 transition"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add New Stock
                </button>
                <button
                  onClick={handleOpenTankModal}
                  className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-blue-600 transition flex items-center"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Tank
                </button>
                <button
                  onClick={handleOpenMaterialModal}
                  className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-cyan-600 transition flex items-center"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Material
                </button>
                <button
                  onClick={handleEditMaterial}
                  className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition flex items-center"
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Edit Material
                </button>
                <button onClick={opendown} className=" text-xs px-3 py-1.5 bg-[#DFF0D8] text-[#102310]  rounded-lg hover:bg-rose-600 transition flex items-center">
          <FaDownload size={14} />
          <span className="ml-2">Download</span> 
        </button>
                
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[80vh] overflow-auto">
          {loading ? (
            <SkeletonLoader height={40} count={10} />
          ) : (
            <>
              <StockList
                filteredStockData={filteredStockData}
                handleEditClick={handleEditClick}
                fetchStockData={fetchStockData}
              />
              {editingStock && (
                <StockEditModal
                  formData={formData}
                  setFormData={setFormData}
                  setEditingStock={setEditingStock}
                  stockData={stockData}
                  fetchStockData={fetchStockData}
                />
              )}
              {addingStock && (
                <StockAddModal
                  formData={formData}
                  setFormData={setFormData}
                  tankOptions={tankOptions}
                  setAddingStock={setAddingStock}
                  stockData={stockData}
                  fetchStockData={fetchStockData}
                />
              )}
              {tankModalOpen && (
                <AddTankModal
                  isOpen={tankModalOpen}
                  onClose={handleCloseTankModal}
                  fetchTanks={fetchTankOptions}
                />
              )}
              {materialOpen && (
                <MaterialModal
                isOpen={materialOpen}
                onClose={handleCloseMaterialModal}
              />
              )}
              {materialToEdit && (
                <MaterialEditModal
                  isEOpen={materialToEdit}
                  onEClose={handleCloseEditMaterial}
                  fetchStockData={fetchStockData} // If you need to refetch stock data after editing
                />
              )}
                 {isDownloadOpen && <Download
                  closedown={closedown} 
                  stockData={stockData}
                  />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StockTable;
