import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LogoImage from '../assets/ksr.jpg';

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.user?.user_role;

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  // State to control the visibility of the Purchase Department submenu
  const [isPurchaseMenuOpen, setPurchaseMenuOpen] = useState(false);

  const togglePurchaseMenu = () => {
    setPurchaseMenuOpen((prev) => !prev);
  };

  return (
    <div className="h-screen flex overflow-hidden">
      <div className="bg-gradient-to-b from-gray-800 to-gray-900 text-white w-64 flex flex-col shadow-lg">
        <div className="p-6 flex flex-col justify-between flex-grow">
          <div>
            <div className="flex items-center justify-center mb-4">
              <img src={LogoImage} alt="Company Logo" className="h-20 w-auto" />
            </div>
            <h1 className="text-lg font-bold text-center font-montserrat uppercase">KSR Techno And Co</h1>
            <div className="flex items-center justify-center mt-2">
              <p className="text-xs text-gray-300 text-center">
                No, 57, 1st FLOOR, GANDHIJI ROAD, NRT NAGAR - THENI, TAMILNADU - 625531
              </p>
              <div className="ml-2 cursor-pointer" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} className="text-gray-400 hover:text-rose-600 transition duration-300" />
              </div>
            </div>
          </div>

          <ul className="flex-grow overflow-y-auto max-h-[calc(100vh-200px)]">
            <SidebarLink to="dashboard" icon="fas fa-tachometer-alt" label="Dashboard" />
            <SidebarLink to="in_out" icon="fas fa-exchange-alt" label="Inward and Outward" />
            <SidebarLink to="stock" icon="fas fa-cubes" label="Stock" />
            <SidebarLink to="logsheet" icon="fas fa-clipboard-list" label="Log Sheet" />
            {userRole === 'Admin' && (
              <SidebarLink to="expenses" icon="fas fa-money-bill-wave" label="Expenses" />
            )}
            <SidebarLink to="pi" icon="fas fa-file-alt" label="Purchase Intent" />
            <li className="px-4 py-2 text-sm">
              <div
                className="flex items-center cursor-pointer hover:bg-gray-800 hover:text-rose-600 transition duration-300"
                onClick={togglePurchaseMenu}
              >
                <span className="w-8 h-8 bg-gray-800 text-gray-400 flex items-center justify-center rounded-full mr-2">
                  <i className="fas fa-file-invoice"></i>
                </span>
                <span className="flex-grow">Purchase Department</span>
                <FontAwesomeIcon icon={isPurchaseMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'} className="ml-auto" />
              </div>
              {isPurchaseMenuOpen && (
                <ul className=" text-xs ml-4 mt-2 ">
                  <SidebarSubLink to="quotation" label="PI Details" />
                  <SidebarSubLink to="po_form" label="PO Form" />
                  <SidebarSubLink to="po_details" label="PO Details" />
                </ul>
              )}
            </li>
            <SidebarLink to="adminreview" icon="fas fa-user-check" label="Plant Head" />
          
          </ul>

          {/* Footer */}
          <div className="p-4">
            <p className="text-sm text-gray-400 text-center">&copy; KSR Techno And Co</p>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 p-2 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

const SidebarLink = ({ to, icon, label }) => (
  <li className="px-4 py-2 hover:bg-gray-800 hover:text-rose-600 transition duration-300">
    <Link to={to} className="flex items-center text-sm">
      <span className="w-8 h-8 bg-gray-800 text-gray-400 flex items-center justify-center rounded-full mr-2 hover:text-rose-600 transition duration-300">
        <i className={icon}></i>
      </span>
      {label}
    </Link>
  </li>
);

// SidebarSubLink component for submenu links
const SidebarSubLink = ({ to, label }) => (
  <li className="py-1 hover:bg-gray-800 hover:text-rose-600 transition duration-300">
    <Link to={to} className="text-sm ml-4">
      {label}
    </Link>
  </li>
);

export default Sidebar;
