import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialRowState = {
  rawMaterial: '',
  quantity: '',
  tankerName: '',
  tankRadius: '',
  tankCapacity: '',
  currentQuantity: '',
  expectedArrivalDate: ''
};

const PurchaseIntent = ({ plantHeadName }) => {
  const [rows, setRows] = useState(Array.from({ length: 7 }, () => ({ ...initialRowState })));
  const [options, setOptions] = useState([]);
  const [tankOptions, setTankOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [error, setError] = useState(null);

useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); 
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const fetchStorageTanks = async () => {
      try {
         const response = await axios.get(`${config.apiUrl}/stock/storagetanks`);
        const tankOptions = response.data.map(tank => ({
          value: tank.tankname, 
          label: tank.tankname, 
          tankradius: tank.tankradius,
          tankcapacity: tank.tankcapacity
        }));
        setTankOptions(tankOptions);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    };

    fetchStorageTanks();
  }, []);
  useEffect(() => {
    const fetchOilNames = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/stock/getOil`);
        if (response.status !== 200) {
          throw new Error('Failed to fetch oil names');
        }
        const oilOptions = response.data.map(item => ({
          value: item.oilname,
          label: item.oilname,
        }));
        
        setOptions(oilOptions);
        console.log(oilOptions,'rawmaterials');
        
      } catch (err) {
        console.error(err);
        setError(err.message);
      } 
    };

    fetchOilNames();
  }, []);

  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    if (field === 'tankerName') {
      const selectedTank = tankOptions.find(option => option.value === value);
      if (selectedTank) {
        newRows[index].tankRadius = selectedTank.tankradius;
        newRows[index].tankCapacity = selectedTank.tankcapacity;
      }
      newRows[index][field] = value; 
    } else {
      newRows[index][field] = value;
    }
    setRows(newRows);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const rowsToSend = rows.filter(row => row.rawMaterial && row.quantity && row.tankerName);

    if (rowsToSend.length === 0) {
      toast.error('Please fill at least one row');
      return;
    }

    setIsLoading(true);

    const payload = {
      Planthead: 'Test Head',
      items: rowsToSend.map(row => ({
        RawMaterial: row.rawMaterial,
        Qty: row.quantity,
        TankName: row.tankerName,
        ExpectedArrivalDate: row.expectedArrivalDate,
        admin_selected: 'N/A', 
      }))
    };

    try {
      const response = await axios.post(`${config.apiUrl}/purchase/requests`, payload);
      console.log('Data submitted successfully:', response.data);
      setRows(Array.from({ length: 7 }, () => ({ ...initialRowState })));
      toast.success('Data submitted successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Failed to complete purchase. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  


  const formattedDateTime = currentDateTime.toLocaleString('en-IN', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata'
  });

  return (
    <div className="container mx-auto p-4 md:p-6 bg-gray-900  min-h-screen">
      <h1 className="text-center text-2xl font-bold mb-2 font-montserrat text-gray-100">Purchase Intent</h1>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex items-center">
          <p className="font-bold text-gray-100 ">Plant Head Name:</p>
          <p className="text-md ml-2 text-gray-100">Test User</p>
        </div>

        <div className="flex items-center justify-end">
          <div className="pr-4 flex">
            <p className="font-bold text-gray-100">Date: </p>
            <p className="ml-4 text-gray-100 ">{formattedDateTime}</p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="">
          <table className="w-full min-w-full divide-y p-2 bg-gray-800 border-collapse table-fixed">
            <thead className="bg-teal-600 text-gray-100 text-center">
              <tr>
                <th className="px-1 py-1 w-1/12 text-center text-xs font-medium  uppercase">S.No</th>
                <th className="px-1 py-1 w-2/12 text-center text-xs font-medium  uppercase">Raw Materials</th>
                <th className="px-1 py-1 w-2/12 text-center text-xs font-medium  uppercase">Tanker Name</th>
                <th className="px-1 py-1 w-1/12 text-center text-xs font-medium  uppercase">Tank Radius</th>
                <th className="px-1 py-1 w-1/12 text-center text-xs font-medium  uppercase">Tank Capacity</th>
                <th className="px-1 py-1 w-1/12 text-center text-xs font-medium  uppercase">Quantity</th>
               
                <th className="px-1 py-1 w-2/12 text-center text-xs font-medium uppercase">Expected Arrival Date</th>
              </tr>
            </thead>
            <tbody className=" divide-y text-sm">
              {rows.map((row, index) => (
                <tr key={index} className='border-b border-gray-700'>
                  <td className="px-1 py-1 text-center text-gray-300">{index + 1}</td>
                  <td className="px-1 py-1 ">
                   <Select
  options={options}
  value={row.rawMaterial ? { value: row.rawMaterial, label: row.rawMaterial } : null}
  onChange={selectedOption => handleChange(index, 'rawMaterial', selectedOption.value)}
  menuPlacement="auto"
/>
                  </td>
                  <td className="px-1 py-1">
                    <Select
                      options={tankOptions}
                      value={row.tankerName ? { value: row.tankerName, label: row.tankerName } : null}
                      onChange={selectedOption => handleChange(index, 'tankerName', selectedOption.value)}
                       menuPlacement="auto"
                      
                    />
                  </td>
                  <td className="px-1 py-1">
                    <input
                      type="number"
                      value={row.tankRadius}
                      readOnly
                      className="w-full px-1 py-1 border border-gray-300 rounded bg-gray-100 text-center"
                    />
                  </td>
                  <td className="px-1 py-1 ">
                    <input
                      type="number"
                      value={row.tankCapacity}
                      readOnly
                      className="w-full px-1 py-1 border border-gray-300 rounded bg-gray-100 text-center"
                    />
                  </td>
                  <td className="px-1 py-1">
                    <input
                      type="number"
                      value={row.quantity}
                      onChange={e => handleChange(index, 'quantity', e.target.value)}
                      className="w-full px-1 py-1 border border-gray-300 rounded text-center"
                    />
                  </td>
                
                  <td className="px-1 py-1">
                    <DatePicker
                      selected={row.expectedArrivalDate}
                      onChange={date => handleChange(index, 'expectedArrivalDate', date)}
                      className="w-full px-1 py-1 border border-gray-300 rounded"
                      dateFormat="yyyy-MM-dd"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className={`px-4 py-2 font-bold text-white text-xs bg-teal-600 hover:bg-blue-900 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PurchaseIntent;
