import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
    // If the user is not logged in, redirect them to the login page
    return <Navigate to="/" replace />;
  }

  // If the user is logged in, render the child components (protected content)
  return children;
};

export default ProtectedRoute;
