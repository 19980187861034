import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Download = ({ closedown, stockData }) => {
  const [format, setFormat] = useState('');

  const handleDownload = () => {
    if (format === 'pdf') {
      downloadPDF();
    } else if (format === 'excel') {
      downloadExcel();
    } else {
      toast.error('Please select a format to download.');
    }
    closedown();
  };

  // PDF Download function
  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape', // Better fit for wide tables
      unit: 'pt', // pt for precision
      format: 'A4',
    });

    doc.text('Stock List', 40, 40);

    doc.autoTable({
      startY: 60, // Starting position after the title
      headStyles: { fillColor: [0, 123, 255], textColor: 255, fontSize: 10 }, 
      bodyStyles: { fontSize: 10, cellPadding: 6, valign: 'middle' }, 
      margin: { top: 50, right: 40, bottom: 50, left: 40 }, 
      theme: 'striped', 
      head: [['S.No', 'Material', 'Material Type', 'Tanker Name', 'Capacity (KL)', 'Radius', 'Stock (KL)', 'Last Update']],
      body: stockData.map((item, index) => [
        index + 1,
        item.oilname,
        item.materialtype,
        item.tankname,
        item.capacity || 0,
        item.tankradius || 0,
        item.currentquantity,
        item.lastupdateddate.split(' ')[0],
      ]),
      styles: { overflow: 'linebreak' },
    });

    doc.save('StockList.pdf');
    toast.success('PDF downloaded successfully');
  };

  // Excel Download function
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Stock List');

    // Add header row with styles
    worksheet.addRow(['S.No', 'Material', 'Material Type', 'Tanker Name', 'Capacity (KL)', 'Radius', 'Stock (KL)', 'Last Update']);
    worksheet.getRow(1).font = { bold: true, size: 12 };
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

    // Add data rows with formatting
    stockData.forEach((item, index) => {
      const row = worksheet.addRow([
        index + 1,
        item.oilname,
        item.materialtype,
        item.tankname,
        item.capacity || 0,
        item.tankradius || 0,
        item.currentquantity,
        item.lastupdateddate.split(' ')[0],
      ]);
      row.alignment = { vertical: 'middle', horizontal: 'left' }; // Align data properly
    });

    // Set column widths for neatness
    worksheet.columns = [
      { width: 10 },  
      { width: 20 },  
      { width: 20 },  
      { width: 25 }, 
      { width: 15 },  
      { width: 15 },  
      { width: 15 },  
      { width: 20 },  
    ];

    // Save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'StockList.xlsx';
    link.click();

    toast.success('Excel downloaded successfully');
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-2xl shadow-lg max-w-sm w-full relative">
        <h2 className="text-xl font-bold mb-4">Download Stock Data</h2>
        <button onClick={closedown} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          className="w-full px-3 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-blue-50"
        >
          <option value="" disabled>Select Format</option>
          <option value="pdf">PDF</option>
          <option value="excel">Excel</option>
        </select>
       
        <div className="flex justify-end ">
          <button
            onClick={handleDownload}
            className={`py-1.5 px-3 rounded-lg text-white text-sm
                ${!format ? 'bg-gray-300 cursor-not-allowed' : 'bg-rose-500 hover:bg-rose-600 text-white'}`}
              disabled={!format}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default Download;
