import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

const ColorModal = ({ isOpen, onClose, onAddColor }) => {
    const [color, setColor] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (color.trim()) {
            try {
                const response = await axios.post(`${config.apiUrl}/in_out/colors`, { name: color.trim() });
                onAddColor(response.data);
                setColor('');
            } catch (error) {
                console.error('Error adding color:', error);
            }
            onClose();
        }
    };

    if (!isOpen) return null;

  const handleInputChange = (e) => {
        // Get the input value and convert to uppercase
        const inputValue = e.target.value.toUpperCase();
        
        // Filter out non-alphabetic characters
        const filteredValue = inputValue.replace(/[^A-Z]/g, '');
        
        setColor(filteredValue);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="p-6 rounded-md w-full max-w-xs shadow-lg bg-white">
                <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Add Color</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-gray-700 mb-1">
                            <span className="text-red-500">*</span> Enter Color
                        </label>
                        <input
    type="text"
    name="color"
    value={color}
    onChange={handleInputChange}
    className="border border-gray-300 rounded-md px-3 py-2 w-full"
    required
/>

                    </div>
                    <div className="flex justify-end mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="mr-2 text-sm px-3 py-1.5 bg-gray-300 text-gray-800 rounded-md shadow hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="text-sm px-3 py-1.5 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                        >
                            Add Color
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ColorModal;
