import React, { useState ,useEffect} from 'react';
import axios from 'axios';
import config from '../config'; // Ensure the path to your config file is correct
import dayjs from 'dayjs';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ObservationPage = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');

  const initialRow = {
    time: '',
    boilerTempIn: '',
    boilerTempOut: '',
    reactorTemp: '',
    waterTempIn: '',
    waterTempOut: '',
    remarks: ''
  };

  const initialMaterialRow = (type) => ({
    type,
    material: '',
    tankName: '',
    quantity: '',
    color: '',
    density: '',
    ph: ''
  });

  const [rows, setRows] = useState(Array(3).fill(initialRow));
  const [batchNumber, setBatchNumber] = useState('');
  const [inputRows, setInputRows] = useState([initialMaterialRow('input')]);
  const [outputRows, setOutputRows] = useState([initialMaterialRow('output')]);

  useEffect(() => {
    // Fetch the batch number when the component mounts
   

    fetchBatchNumber();
  }, []);

  const addRow = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleChange = (index, field, value, type) => {
    const targetRows = type === 'input' ? inputRows : type === 'output' ? outputRows : rows;
    const setTargetRows = type === 'input' ? setInputRows : type === 'output' ? setOutputRows : setRows;

    setTargetRows(targetRows.map((row, i) => i === index ? { ...row, [field]: value } : row));
  };
  const addInputRow = () => {
    setInputRows([...inputRows, initialMaterialRow('input')]);
  };

  const addOutputRow = () => {
    setOutputRows([...outputRows, initialMaterialRow('output')]);
  };

  const removeInputRow = (index) => {
    const newRows = inputRows.filter((_, i) => i !== index);
    setInputRows(newRows);
  };

  const removeOutputRow = (index) => {
    const newRows = outputRows.filter((_, i) => i !== index);
    setOutputRows(newRows);
  };

  const handleMaterialChange = (index, field, value, type) => {
    const setRowsFunc = type === 'input' ? setInputRows : setOutputRows;
    const rows = type === 'input' ? inputRows : outputRows;

    const newRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRowsFunc(newRows);
  };

  const calculateTotalQuantity = () => outputRows.reduce((total, row) => total + (parseFloat(row.quantity) || 0), 0);

  const handleSubmit = async () => {
    const isValid = (rows, type) => rows.every(row => 
      Object.values(row).every(value => value.trim() !== '')
    );

    if (!isValid(rows) || !isValid(inputRows, 'input') || !isValid(outputRows, 'output')) {
      toast.error('Please fill in all fields.');
      return;
    }
     // Format the current date for submission
  const formattedDateForSubmission = dayjs().format('YYYY-MM-DD');

  // Map rows with formatted date and time
  const observationRowsWithDate = rows.map(row => ({
    ...row,
    date: formattedDateForSubmission,
    time: row.time // Ensure time is in HH:mm format
  }));

  const data = {
    inputRows: inputRows.map(row => ({ ...row })),
    outputRows: outputRows.map(row => ({ ...row })),
    observationRows: observationRowsWithDate,
    date: formattedDateForSubmission,
    batchNumber
  };

    try {
      const response = await axios.post(`${config.apiUrl}/observation/submit`, { rows: data });
      toast.success('Data submitted successfully');
         // Reset form data and state
    resetForm();
     // Fetch new batch number
     await fetchBatchNumber();
    } catch (error) {
      toast.error(`Error submitting data: ${error.response?.data?.message || 'Please try again.'}`);
    }
  };
  // Function to reset form data and state
const resetForm = () => {
  setInputRows([]); // Assuming you are using useState
  setOutputRows([]);
  setRows([]);
  setBatchNumber('');
  // Add any additional resets here if necessary
};
  
const fetchBatchNumber = async () => {
  try {
     const response = await axios.get(`${config.apiUrl}/observation/generate-batch-number`);
    setBatchNumber(response.data.batchNumber);
  } catch (error) {
    console.error('Error fetching batch number:', error);
  }
};
  return (
    <div className="min-h-screen bg-gray-50 p-6 sm:p-8">
      <div className="flex justify-between items-center mb-8 p-6 bg-white shadow-lg rounded-lg border border-gray-200">
  <h1 className="text-2xl font-extrabold text-gray-900">Observation</h1>
  <div className="text-lg font-medium text-gray-700 mr-5">
  <div className="flex items-center text-sm mb-1">
    <span className="mr-2 text-gray-500 text-md font-bold w-32">Date:</span>
    <span className="text-gray-900 flex-1 text-right">{currentDate || 'N/A'}</span>
  </div>
  <div className="flex items-center text-sm">
    <span className="mr-2 text-gray-500 font-bold w-32">Batch No:</span>
    <span className="text-gray-900 flex-1 text-right">{batchNumber || 'N/A'}</span>
  </div>
</div>




</div>


      <div className="mb-8 overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-emerald-100 text-gray-700">
            <tr>
              <th rowSpan="2" className="border border-gray-300 p-3">S.No</th>
              <th rowSpan="2" className="border border-gray-300 p-3">Time</th>
              <th colSpan="2" className="border border-gray-300 p-3">Boiler Temp C</th>
              <th rowSpan="2" className="border border-gray-300 p-3">Reactor Temp C</th>
              <th colSpan="2" className="border border-gray-300 p-3">Water Temp C</th>
              <th rowSpan="2" className="border border-gray-300 p-3">Remarks</th>
              <th rowSpan="2" className="border border-gray-300 p-3">Actions</th>
            </tr>
            <tr>
              <th className="border border-gray-300 p-3">In</th>
              <th className="border border-gray-300 p-3">Out</th>
              <th className="border border-gray-300 p-3">In</th>
              <th className="border border-gray-300 p-3">Out</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row, index) => (
              <tr key={index} className="text-center">
                <td className="border border-gray-300 p-2">{index + 1}</td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="time"
                    value={row.time}
                    onChange={(e) => handleChange(index, 'time', e.target.value)}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.boilerTempIn}
                    onChange={(e) => handleChange(index, 'boilerTempIn', e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.boilerTempOut}
                    onChange={(e) => handleChange(index, 'boilerTempOut', e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.reactorTemp}
                    onChange={(e) => handleChange(index, 'reactorTemp', e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.waterTempIn}
                    onChange={(e) => handleChange(index, 'waterTempIn', e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.waterTempOut}
                    onChange={(e) => handleChange(index, 'waterTempOut', e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.remarks}
                    onChange={(e) => handleChange(index, 'remarks', e.target.value)}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <button
                    onClick={() => removeRow(index)}
                    className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-4">
          <button
            onClick={addRow}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-800 flex items-center"
          >
            <FaPlus className="" />
          </button>
        </div>
      </div>

      <div className="mt-8 overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-emerald-100 text-gray-700">
            <tr>
              <th className="border border-gray-300 p-3">Type</th>
              <th className="border border-gray-300 p-3">Material</th>
              <th className="border border-gray-300 p-3">Tank Name</th>
              <th className="border border-gray-300 p-3">Quantity</th>
              <th className="border border-gray-300 p-3">Color</th>
              <th className="border border-gray-300 p-3">Density</th>
              <th className="border border-gray-300 p-3">pH</th>
              <th className="border border-gray-300 p-3">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {inputRows.map((row, index) => (
              <tr key={index} className="text-center ">
                <td className="border border-gray-300 p-2">{row.type}</td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.material}
                    onChange={(e) => handleMaterialChange(index, 'material', e.target.value, 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.tankName}
                    onChange={(e) => handleMaterialChange(index, 'tankName', e.target.value, 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.quantity}
                    onChange={(e) => handleMaterialChange(index, 'quantity', e.target.value.replace(/[^0-9]/g, ''), 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.color}
                    onChange={(e) => handleMaterialChange(index, 'color', e.target.value, 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.density}
                    onChange={(e) => handleMaterialChange(index, 'density', e.target.value, 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.ph}
                    onChange={(e) => handleMaterialChange(index, 'ph', e.target.value, 'input')}
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <button
                    onClick={() => removeInputRow(index)}
                    className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
            {outputRows.map((row, index) => (
              <tr key={index} className="text-center ">
                <td className="border border-gray-300 p-2">{row.type}</td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.material}
                    onChange={(e) => handleMaterialChange(index, 'material', e.target.value, 'output')}
                    className="w-full border border-gray-300  rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.tankName}
                    onChange={(e) => handleMaterialChange(index, 'tankName', e.target.value, 'output')}
                    className="w-full border border-gray-300  rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="number"
                    value={row.quantity}
                    onChange={(e) => handleMaterialChange(index, 'quantity', e.target.value.replace(/[^0-9]/g, ''), 'output')}
                    className="w-full border  border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.color}
                    onChange={(e) => handleMaterialChange(index, 'color', e.target.value, 'output')}
                    className="w-full border  border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.density}
                    onChange={(e) => handleMaterialChange(index, 'density', e.target.value, 'output')}
                    className="w-full border  border-gray-300 rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    type="text"
                    value={row.ph}
                    onChange={(e) => handleMaterialChange(index, 'ph', e.target.value, 'output')}
                    className="w-full border border-gray-300  rounded-lg p-2"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <button
                    onClick={() => removeOutputRow(index)}
                    className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="bg-gray-100 text-gray-700">
            <tr>
              <td colSpan="3" className="border border-gray-300 p-3 text-right font-semibold">Total Quantity:</td>
              <td className="border border-gray-300 p-3 text-center font-semibold">
                {calculateTotalQuantity()}
              </td>
              <td colSpan="4" className="border border-gray-300 p-3"></td>
            </tr>
          </tfoot>
        </table>
        <div className="flex justify-between mt-4">
          <button
            onClick={addInputRow}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center"
          >
            <FaPlus className="" /> 
          </button>
          <button
            onClick={addOutputRow}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-800 flex items-center"
          >
            <FaPlus className="" /> 
          </button>
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-800"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ObservationPage;
