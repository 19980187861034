import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = ({ height, count = 1 }) => (
  <div className="w-full h-full flex flex-col space-y-4">
    {Array(count)
      .fill(0)
      .map((_, index) => (
        <Skeleton
          key={index}
          height={height}
          width="100%"
          borderRadius={8} // Rounded corners
          baseColor="#e0e0e0" // Base color
          highlightColor="#f5f5f5" // Highlight color
        />
      ))}
  </div>
);

export default SkeletonLoader;
