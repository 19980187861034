import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const getCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // pad with 0 if necessary
  return `${year}-${month}`;
};

const MonthlyProductionChart = () => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    labels: [],
    colors: ['#FF4560', '#008FFB', '#00E396', '#FEB019', '#FF66C3'],
    plotOptions: {
      pie: {
        expandOnClick: true,
      },
    },
    legend: {
      show: false, // Hide the legend
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2); // Ensure two decimal places
        }
      }
    }
  });
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [toastId, setToastId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear the previous toast if it exists
      if (toastId) {
        toast.dismiss(toastId);
      }

      try {
        const response = await axios.get(`${config.apiUrl}/logsheet/monthly-production`, {
          params: { month: selectedMonth },
        });
        const data = response.data;
        console.log('Monthly',data);
        

        if (data.length === 0) {
          const id = toast.error('No data available for the selected month.', {
            autoClose: 3000,
          });
          setToastId(id);
        } else {
          // Process the data for pie chart
          const labels = data.map(item => item.Product);
          console.log('labels',labels);
          
          const series = data.map(item =>  parseFloat(item.TotalQty));
          console.log('series',series);
          

          setChartOptions(prevOptions => ({
            ...prevOptions,
            labels: labels,
            
          }));
          setChartData(series);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        const id = toast.error('Failed to fetch data. Please try again later.', {
          autoClose: 3000,
        });
        setToastId(id);
      }
    };

    fetchData();
  }, [selectedMonth]);

  return (
    <div className="p-4 rounded-lg w-full mt-10">
      <h2 className="text-gray-700 text-xl font-bold text-center mb-4">
        Products (Monthly Production)
      </h2>
      <div className="mb-4 flex justify-center items-center">
        <label htmlFor="monthFilter" className="text-gray-700 text-sm font-medium mr-2">
          Select Month:
        </label>
        <select
          id="monthFilter"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="p-1 border border-gray-300 rounded text-gray-700 text-sm"
        >
          <option value={getCurrentMonth()}>Current Month</option>
          {MONTHS.map((month, index) => {
            const value = `${new Date().getFullYear()}-${String(index + 1).padStart(2, '0')}`;
            return <option key={value} value={value}>{month}</option>;
          })}
        </select>
      </div>
      <div className="flex justify-center items-center w-full h-80">
        <ApexCharts
          type="pie"
          width="100%"
          height="100%"
          options={chartOptions}
          series={chartData}
        />
      </div>
    </div>
  );
};

export default MonthlyProductionChart;
