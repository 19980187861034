import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from 'react-icons/fa'; 
import ColorModal from './ColorModal';
import { HiInformationCircle } from "react-icons/hi";
import { HiOutlineSave } from 'react-icons/hi';
import InoutHistory from './InoutHistory';
import SaveModal from './SavedModal';

const InWardForm = () => {
    const [isOutWard, setIsOutWard] = useState(false);
    const [tankOptions, setTankOptions] = useState([]);
    const [formData, setFormData] = useState({
        purchaseOrderNo: '',
        invoiceNo: '',
        ewayBillNo: '',
        date: '',
        vehicleNo: '',
        driverName: '',
        inandout: '',
        location: '',
        tankNo: '',
        fuelName: '',
        grossWeight: '',
        tareWeight: '',
        netWeight: '',
        uom: '',
        color: '',
        density: '',
        remarks: '',

    });
    const [customColors, setCustomColors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [istableOpen, setIstableOpen] = useState(false);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [isManualNetWeight, setIsManualNetWeight] = useState(false); 
    const [isSaving, setIsSaving] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedDataType, setSelectedDataType] = useState('inward');
   // Dynamic entries for material and tank
   const [materialEntries, setMaterialEntries] = useState([]);
    const handleSaveData = (updatedData) => {
        setFormData(updatedData);
        closeModal(); // Close the modal
      };

    useEffect(() => {
        const fetchColors = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/in_out/colors`);
                setCustomColors(response.data.map(color => color.name));
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };

        fetchColors();
    }, []);
    useEffect(() => {
        const fetchOilNames = async () => {
          try {
            const response = await axios.get(`${config.apiUrl}/stock/getoilnames`);
            // Assuming the response is an array of objects with oilname properties
            const options = response.data.map(item => ({
              value: item.oilname,
              label: item.oilname,
            }));
            setMaterialOptions(options);
          } catch (error) {
            console.error('Failed to fetch oil names:', error);
          }
        };
    
        fetchOilNames();
      }, []);
    
    

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleAddColor = (newColor) => {
        setCustomColors(prevColors => [...prevColors, newColor.name]);
    };


    

    const validateVehicleNo = (vehicleNo) => {
        // Regular expression for validation
        const vehicleNoPattern = /^[A-Z]{2}\s\d{2}\s[A-Z]{1,2}\s\d{4}$/;
        return vehicleNoPattern.test(vehicleNo);
    };

    const fetchTankOptions = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
            if (response.data && Array.isArray(response.data.data)) {
                const formattedOptions = response.data.data.map(tank => ({
                    tankname: tank.tankname,
                    tankradius: tank.tankradius // Ensure radius is included
                }));
                setTankOptions(formattedOptions);
                console.log(formattedOptions);
                
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching tank options:', error);
        }
    };
    
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;

        setIsOutWard(isChecked);

    
        setFormData(prevData => {
              return {
                ...prevData,
                isOutWard: isChecked,
                netWeight: '',
                grossWeight:'',
                tareWeight:'',
                density:'',
                barrelCount: '', 
                litresPerBarrel: '',

            };
        });
    };

  const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => {
            let newValue = parseFloat(value) || 0;
        if (isManualNetWeight) {
            return {
                ...prevData,
                [name]: value,
                grossWeight: 0,   
                tareWeight: 0  ,
            };
        }
            let newGrossWeight = parseFloat(prevData.grossWeight) || 0;
            let newTareWeight = parseFloat(prevData.tareWeight) || 0;
            let newDensity = parseFloat(prevData.density) || 0;
            let newBarrelCount = parseInt(prevData.barrelCount, 10) || 1;
            let newLitresPerBarrel = parseFloat(prevData.litresPerBarrel) || 0;
            let newNetWeight = 0; 
            if (name === 'tankNo' && isSaving ) {
                return {
                    ...prevData,
                    [name]: value,
                    grossWeight: '',
                    tareWeight: '',
                    netWeight: ''
                };
            } else if (name === 'tankNo' && !isSaving) {
            return {
                ...prevData,
                [name]: value
            };
        }
            switch (name) {
                case 'grossWeight':
                    newGrossWeight = newValue;
                    break;
                case 'tareWeight':
                    newTareWeight = newValue;
                    break;
                case 'density':
                    newDensity = newValue;
                    break;
                case 'barrelCount':
                    newBarrelCount = newValue;
                    break;
                case 'litresPerBarrel':
                    newLitresPerBarrel = newValue;
                    break;
                case 'tankNo':
                    const selectedTank = tankOptions.find(tank => tank.tankname === value);
                    const radius = selectedTank ? selectedTank.tankradius : 0;
                    // Calculate new net weight based on radius and user input if needed
                    if (radius && !isNaN(radius)) {
                        newNetWeight = (parseFloat(prevData.netWeight) || 0) * radius * radius * Math.PI / 4;
                    }
                    break;
                default:
                    break;
            }
    
            // Validate that both weights are non-negative
            if (newGrossWeight < 0 || newTareWeight < 0) {
                return {
                    ...prevData,
                    [name]: value,
                    netWeight: '' // Reset net weight if any input is invalid
                };
            }
    
            // Validate that Tare Weight is not greater than Gross Weight
            if (newTareWeight > newGrossWeight) {
                return {
                    ...prevData,
                    [name]: value,
                    netWeight: '',
                    tareWeight: '',
                    grossWeight: ''
                };
            }
    
            if (!isManualNetWeight && newGrossWeight >= 0 && newTareWeight >= 0) {
                newNetWeight = newGrossWeight - newTareWeight; // Calculate netWeight if not manual
            }
    
            // Apply UOM-based logic for converting to KL
            switch (prevData.uom) {
                case 'MTS':
                    newNetWeight = prevData.isOutWard
                        ? (newNetWeight * newDensity)
                        : (newNetWeight / newDensity)
                    break;
                case 'Barrel':
                    const klPerBarrel = newLitresPerBarrel / 1000;
                    newNetWeight = klPerBarrel * newBarrelCount;
                    break;
                case 'Litre':
                    newNetWeight = newNetWeight / 1000;
                    break;
                case 'Kgs':
                    newNetWeight = (newNetWeight / (newDensity * 1000))
                    break;
                    case 'cm':
                        const tank = tankOptions.find(t => t.tankname === prevData.tankNo);

                        if (tank) {
                            newNetWeight = newNetWeight * tank.tankradius * tank.tankradius * 3.14 / 4;
                                                   
                       
                        }
                        break;
                default:
                    break;
            }
            // Ensure newNetWeight is a valid number before using .toFixed()
            newNetWeight = isNaN(newNetWeight) ? 0 : parseFloat(newNetWeight).toFixed(2)
            return {
                ...prevData,
                [name]: value,
                netWeight: isManualNetWeight ? prevData.netWeight : newNetWeight // Only calculate if not manual
            };
        });
    };
    
    const handleManualNetWeightChange = (e) => {
        const isChecked = e.target.checked;
        setIsManualNetWeight(isChecked);
        
        if (isChecked) {
            setFormData(prevData => ({
                ...prevData,
                grossWeight: '',
                tareWeight: '',
                netWeight: '' 
            }));
        } else {

            setFormData(prevData => ({
                ...prevData,
                netWeight: '', 
                grossWeight: '',
                tareWeight: ''
            }));
        }
    };
    
    

    const handleSelectChange = (selectedOption) => {
        setFormData(prevData => ({
          ...prevData,
          fuelName: selectedOption ? selectedOption.value : '',
        }));
 };
  
      
      const handleUOMChange = (e) => {
        const newUOM = e.target.value;
    
        setFormData(prevData => {
            let newNetWeight = '';
    
 
            if (newUOM !== prevData.uom) {
                return {
                    ...prevData,
                    uom: newUOM,
                    grossWeight: '',
                    tareWeight: '',
                    density: '',
                    netWeight: '',
                    barrelCount: '',
                    litresPerBarrel: '',  
                };
            }
    
            return prevData;
        });
    };
    
    

    const handleSave = async (e) => {
        e.preventDefault(); 

     const requiredFields = [
        'purchaseOrderNo',
        'invoiceNo',
        'ewayBillNo',
        'date',
        'vehicleNo',
        'driverName',
        'inandout',
        'location',
        'fuelName',
        'uom',
        'color',
        'netWeight',
        'remarks'
    ];


    for (let field of requiredFields) {
        if (!formData[field]) {
            toast.error(`${field} is required.`);
            return;
        }
    }

if (!isManualNetWeight) {
    if (!formData.grossWeight) {
        toast.error('Gross Weight is required .');
        return;
    }
    if (!formData.tareWeight) {
        toast.error('Tare Weight is required ');
        return;
    }
}

if (formData.uom === 'Barrel') {
    if (!formData.barrelCount || formData.barrelCount <= 0) {
        toast.error('Barrel count is required');
        return;
    }
    if (!formData.litresPerBarrel || formData.litresPerBarrel <= 0) {
        toast.error('Litres per barrel is required');
        return;
    }
}
if (formData.uom === 'MTS') {
    if (!formData.density || formData.density <= 0) {
        toast.error('density  is required');
        return;
    }
}
if (formData.uom === 'Kgs') {
    if (!formData.density || formData.density <= 0) {
        toast.error('density  is required');
        return;
    }
}
    const savingFormData = {
        purchaseOrderNo: formData.purchaseOrderNo,
        invoiceNo: formData.invoiceNo,
        ewayBillNo: formData.ewayBillNo,
        date: formData.date,
        vehicleNo: formData.vehicleNo,
        driverName: formData.driverName,
        inandout:formData.inandout,
        location: formData.location,
        fuelName: formData.fuelName,
        uom: formData.uom,
        color: formData.color,
        remarks: formData.remarks,
        barrelCount: formData.barrelCount || 0, 
        litresPerBarrel: formData.litresPerBarrel || 0,
        grossWeight: isManualNetWeight ? 0 : (formData.grossWeight || 0),
        tareWeight: isManualNetWeight ? 0 : (formData.tareWeight || 0),
        netWeight: formData.netWeight || 0,
        density: formData.density || 0 
    };
        if (isOutWard && !savingFormData.inandout) {
            toast.error('Details cannot be empty.');
            return;
        }
    
        if (!validateVehicleNo(savingFormData.vehicleNo)) {
            toast.error('Vehicle No must be in the correct format');
            return;
        }
    
        try {
            const endpoint = isOutWard ? 'outward' : 'inward';
            console.log(`Saving ${endpoint} data with:`, savingFormData);
           const dataResponse = await axios.post(`${config.apiUrl}/saveio/${endpoint}`, savingFormData);
          if (dataResponse.status === 200 || dataResponse.status === 201) {
                toast.success('Data saved successfully');
            } else {
                toast.error(`Failed to save ${endpoint} details. Please try again.`);
            }
        } catch (dataError) {
            console.error('Error saving data:', dataError);
            if (dataError.response) {
                const { status, data } = dataError.response;
    
                if (status === 404) {
                    toast.error(data.message);
                } else if (status === 400) {
                    toast.error(data.message || `Failed to save details. Please try again.`);
                } else if (status >= 500) {
                    toast.error(`Server error: ${data.message || 'Please try again later.'}`);
                } else {
                    toast.error(`Error saving ${isOutWard ? 'outward' : 'inward'} details!`);
                }
            } else {
                toast.error(`Error saving ${isOutWard ? 'outward' : 'inward'} details!`);
            }
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
    const requiredFields = [
        'purchaseOrderNo',
        'invoiceNo',
        'ewayBillNo',
        'date',
        'vehicleNo',
        'driverName',
        'location',
        'tankNo',
        'fuelName',
        'uom',
        'color',
        'remarks'
    ];

    for (let field of requiredFields) {
        if (!formData[field]) {
            toast.error(`${field} is required.`);
            return;
        }
    }
        const updatedFormData = {
            ...formData,
            barrelCount: formData.barrelCount || 0, 
            litresPerBarrel: formData.litresPerBarrel || 0,
            grossWeight: isManualNetWeight ? 0 : (formData.grossWeight || 0),
            tareWeight: isManualNetWeight ? 0 : (formData.tareWeight || 0),
            density:formData.density || 0 
        };
    
        if (isOutWard && !updatedFormData.inandout) {
            toast.error('Details cannot be empty.');
            return;
        }
    
        if (!validateVehicleNo(updatedFormData.vehicleNo)) {
            toast.error('Vehicle No must be in the correct format');
            return;
        }
    
        try {
            const endpoint = isOutWard ? 'outward' : 'inward';
            console.log(`Submitting ${endpoint} data with:`, updatedFormData);
          const dataResponse = await axios.post(`${config.apiUrl}/in_out/${endpoint}`, updatedFormData);
            if (dataResponse.status === 200) {
                toast.success('Data submitted successfully');
                const stockUpdates = [{
                    tankNo: updatedFormData.tankNo,
                    netWeight: updatedFormData.netWeight,
                    fuelName: updatedFormData.fuelName 
                }];
    
                try {
                    console.log('Updating stock with:', {
                        updates: stockUpdates,
                        isOutWard: isOutWard
                    });
    
                    const stockResponse = await axios.put(`${config.apiUrl}/stock/in_out`, {
                        updates: stockUpdates,
                        isOutWard: isOutWard
                    });
    
                    if (stockResponse.status === 200) {
                        toast.success('Stock updated successfully');
                        resetFormFields();
                        setIsManualNetWeight('false') 
                    } else {
                        toast.error(`Failed to update stock: ${stockResponse.data.message}`);
                    }
                } catch (stockError) {
                    console.error('Error updating stock:', stockError);
                    toast.error('Failed to update stock. Please try again.');
                }
            } else {
                toast.error(`Failed to submit ${endpoint} details. Please try again.`);
            }
        } catch (dataError) {
            console.error('Error submitting data:', dataError);
            if (dataError.response) {
                const { status, data } = dataError.response;
           if (status === 404) {
                    toast.error(data.message);
                } else if (status === 400) {
                    toast.error(data.message || `Failed to submit details. Please try again.`);
                } else {
                    toast.error(`Error submitting ${isOutWard ? 'outward' : 'inward'} details!`);
                }
            } else {
                toast.error(`Error submitting ${isOutWard ? 'outward' : 'inward'} details!`);
            }
           
        }
    };

    const resetFormFields = () => {
        setFormData({
            purchaseOrderNo: '',
            invoiceNo: '',
            ewayBillNo: '',
            date: '',
            vehicleNo: '',
            driverName: '',
            inandout: '',
            location: '',
            tankNo: '',
            fuelName: '',
            grossWeight: '',
            tareWeight: '',
            netWeight: '',
            uom: '',
            color: '',
            density: '',
            remarks: ''
        });
        setIsOutWard(false); 
        setIsSaving(true);
    };

    useEffect(() => {
        fetchTankOptions();
    }, []);
  
    const openTable = () => {
        setIstableOpen(true);
      };
      
      const closeTable = () => {
        setIstableOpen(false);
      };
      const openModal = () => {
        setShowModal(true)
      }
      const closeModal = () => {
        setShowModal(false)
      }
      const handlesaveChange = () => {
        setIsSaving((prev) => !prev);
      };

    return (
        <>
        <div className="max-w-4xl mx-auto p-6 bg-[#DDCFD1]  shadow-md rounded-lg">
        <button onClick={openModal} className="text-blue-900 float-left hover:text-blue-300 flex items-center">
        <HiOutlineSave size={20} />

        </button>
        <button onClick={openTable} className="text-blue-900 float-right hover:text-blue-300 flex items-center">
          <HiInformationCircle size={20} />

        </button>
            <h2 className="text-xl font-bold mb-4 text-center text-[#438FD1]">{isOutWard ? 'Outward Form' : 'Inward Form'}</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block text-sm font-medium ">Invoice Number<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="invoiceNo" 
                            value={formData.invoiceNo}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Purchase Order No<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="purchaseOrderNo" 
                            value={formData.purchaseOrderNo}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">e-way Bill No<span className="text-red-500">*</span></label>
                        <input 
                            type="number" 
                            name="ewayBillNo" 
                            value={formData.ewayBillNo}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">{isOutWard ? 'Date of Delivery' : 'Date of Arrival'} <span className="text-red-500">*</span></label>
                        <input 
                            type="date" 
                            name="date" 
                            value={formData.date}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Vehicle No <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="vehicleNo"
                            value={formData.vehicleNo}
                            onChange={handleChange}
                            title="e.g. TN 12 BD 1234 or TN 12 C 1234"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Driver Name <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="driverName" 
                            value={formData.driverName}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                            required 
                            title="Driver Name must contain only uppercase letters and spaces."
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">{isOutWard ? 'OutWard to Company' : 'Inward to Company'}<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="inandout"
                            value={formData.inandout}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                            pattern="[a-zA-Z\s]+" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">From Location <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="location" 
                            value={formData.location}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                            pattern="[a-zA-Z\s]+" 
                            required 
                        />
                    </div>
                    <div>
                    <div className="flex items-center space-x-2">
        <label className="text-sm font-medium">Tank No <span className="text-red-500">*</span></label>
        <input
            className=""
            type="checkbox"
            checked={!isSaving}  // Checkbox reflects if saving is enabled or not
            onChange={handlesaveChange}  // Toggle isSaving state
        />
    </div>
                        <select 
                            name="tankNo" 
                            value={formData.tankNo}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md text-sm shadow-sm bg-white text-gray-900" 
                            required
                            disabled={isSaving}
                        >
                            <option value="">Select Tank No</option>
                            {tankOptions.map((tank, index) => (
                                <option key={index} value={tank.tankname}>{tank.tankname}</option>
                            ))}
                        </select>
                    </div>
                    <div className="relative">
      <label className="block text-sm font-medium">
        Material Name <span className="text-red-500">*</span>
      </label>
      <Select
                value={materialOptions.find(option => option.value === formData.fuelName) || null}
                onChange={handleSelectChange}
                options={materialOptions}
                styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      minHeight: '1rem',
                      boxShadow: 'none',
                      borderRadius: '0.375rem',
                      fontSize: '0.75rem',
                      padding: '0 0.5rem', // Adjust padding as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '270px',
                      zIndex: 9999,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.75rem',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '0.75rem',
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: '0.75rem', 
                      padding: '0.25rem 0.5rem', 
                      lineHeight: '1rem',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                placeholder="Select a material"
                required
            />
        
        </div>
                    <div>
    <label className="block text-sm font-medium">
        UOM <span className="text-red-500">*</span>
    </label>
    <select
        name="uom"
        value={formData.uom}
        onChange={handleUOMChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        required
    >
       <option value="" disabled>Select UOM</option>
        <option value="Kgs">Kgs</option>
        <option value="KL">KL</option>
        <option value="Barrel">Barrel</option>
        <option value="MTS">MTS</option>
        <option value="Litre">Litre</option>
        <option value="cm">cm</option> 
       
    </select>
</div>
{/* Barrel Count & Litres per Barrel */}
{formData.uom === 'Barrel' && (
                        <>
                            <div>
                                <label className="block text-sm font-medium">Number of Barrels</label>
                                <input 
                                    type="number" 
                                    name="barrelCount" 
                                    value={formData.barrelCount}
                                    onChange={handleChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                                    min="1"
                                    required 
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium">Litres per Barrel</label>
                                <input 
                                    type="number" 
                                    name="litresPerBarrel" 
                                    value={formData.litresPerBarrel}
                                    onChange={handleChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm" 
                                    min="200"
                                    max="210"
                                    required 
                                />
                            </div>
                        </>
                    )}

<div>
            <label className="block text-sm font-medium">
                Color <span className="text-red-500">*</span>
                <FaPlus
                    className="inline-block ml-2 mb-1 cursor-pointer text-blue-600"
                    onClick={handleOpenModal}
                />
            </label>
            <select
                name="color"
                value={formData.color}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                required
            >
                <option value="" disabled>Select Color</option>
                {customColors.map((color, index) => (
                    <option key={index} value={color}>{color}</option>
                ))}
            </select>
                    </div>
                    {formData.uom !== 'Barrel' && (
                        <>
                    <div>
                        <label className="block text-sm font-medium">Gross Weight <span className="text-red-500">*</span></label>
                        <input
                            type="number"
                            name="grossWeight"
                            value={formData.grossWeight}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            required
                            step="0.01" 
                            min="0" 
                            disabled={isManualNetWeight}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Tare Weight  <span className="text-red-500">*</span></label>
                        <input
                            type="number"
                            name="tareWeight"
                            value={formData.tareWeight}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            required
                            step="0.01" 
                            min="0" 
                            disabled={isManualNetWeight}
                        />
                    </div>
                    </>
                    )}
                                   <div>
    <div className="flex items-center space-x-2">
        <label className="text-sm font-medium">Net Weight (KL) <span className="text-red-500">*</span></label>
        <input
            className=""
            type="checkbox"
            checked={isManualNetWeight}
            onChange={handleManualNetWeightChange}  // Updated checkbox handler
        />
    </div>
    <input
        type="number"
        name="netWeight"
        value={formData.netWeight}
        onChange={handleChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
        step="0.01" // Allows entering decimal values
        min="0" // Only positive values
        disabled={!isManualNetWeight}
        required
    />
</div>
                   
                    {formData.uom !== 'cm' && formData.uom !== 'Barrel' && formData.uom !== 'Litre' &&(   
                    <div>
                        <label className="block text-sm font-medium">Density <span className="text-red-500">*</span></label>
                        <input
                            type="text"
                            name="density"
                            value={formData.density}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            required
                        />
                    </div>
                    )}
                    <div>
                        <label className="block text-sm font-medium">Remarks</label>
                        <input
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-4 mt-4">
                    <input
                        type="checkbox"
                        checked={isOutWard}
                        onChange={handleCheckboxChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label className="text-sm font-medium">Check for Outward Form</label>
                </div>
                <div className="flex justify-center mt-6">
  {isSaving ? (
    <button
      type="button"
      onClick={handleSave} // Call your save function
      className="w-32 px-3 py-1.5 text-xs text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
    >
      Save
    </button>
  ) : (
    <button
      type="submit"
      className="w-32 px-3 py-1.5 text-xs text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
    >
      Submit
    </button>
  )}
  <button
    type="button"
    onClick={resetFormFields}
    className="ml-4 w-32 px-3 py-1.5 text-xs text-white bg-red-600 hover:bg-red-700 rounded-md shadow-md "
  >
    Cancel
  </button>
</div>

            </form>
            <ColorModal 
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onAddColor={handleAddColor}
            />

{istableOpen && <InoutHistory closeTable={closeTable} />}  
{showModal && <SaveModal closeModal={closeModal} setFormData={setFormData} setIsSaving={setIsSaving} handleSaveData={handleSaveData} selectedDataType={selectedDataType} 
          setSelectedDataType={setSelectedDataType} setIsOutWard={setIsOutWard} />}
        </div>
        </>
    );
};

export default InWardForm;
