import React from 'react';

const StockTable = ({ stockSumData }) => {
    return (
        <div className="overflow-y-auto max-h-full w-full">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gradient-to-r from-blue-400 to-purple-500 text-white text-center text-xs whitespace-nowrap sticky top-0 z-10">
                    <tr>
                        <th className="px-4 py-2">Material</th>
                        <th className="px-4 py-2">Total Quantity (KL)</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-xs text-center text-gray-600">
                    {stockSumData.length ? (
                        stockSumData.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                <td className="px-4 py-2">{item.oilname}</td>
                                <td className="px-4 py-2">{parseFloat(item.total_quantity).toFixed(2)} KL</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" className="px-2 py-2 text-center text-gray-500">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default StockTable;
