import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MaterialModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    oilname: '',
    materialType: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    let validationErrors = {};
    if (!formData.oilname) validationErrors.oilname = 'Oil name is required';
    if (!formData.materialType) validationErrors.materialType = 'Material type is required';

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    try {
      const response = await axios.post(`${config.apiUrl}/stock/savematerial`, formData);
      if (response.data.message) {
        toast.success(response.data.message);
        onClose(); // Close the modal upon successful submission
      } else {
        toast.error('Failed to add material. Please try again.');
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      toast.error('There was an error submitting the form.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-gray-800 bg-opacity-75 z-50">
      <div className="p-6 rounded-md w-full max-w-lg bg-white shadow-lg transform transition-all">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-2  border-gray-300 pb-2">Add New Material</h2>
          <button onClick={onClose} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block text-gray-700 mb-1">
              <span className="text-red-500">*</span> Oil Name
            </label>
            <input
              type="text"
              name="oilname"
              value={formData.oilname}
              onChange={handleChange}
              className={`form-input w-full border ${errors.oilname ? 'border-red-500' : 'border-gray-300'} rounded-md text-sm p-2 bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
              required
            />
            {errors.oilname && <p className="text-red-500 text-sm mt-1">{errors.oilname}</p>}
          </div>

          <div>
            <label className="block text-gray-700 mb-1">
              <span className="text-red-500">*</span> Material Type
            </label>
            <select
              name="materialType"
              value={formData.materialType}
              onChange={handleChange}
              className={`form-input w-full border ${errors.materialType ? 'border-red-500' : 'border-gray-300'} rounded-md text-sm p-2 bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
              required
            >
              <option value="">Select Material Type</option>
              <option value="rawmaterial">Raw Material</option>
              <option value="productaftermixing">Product After Mixing</option>
              <option value="product">Product</option>
              <option value="solvent">Solvent</option>
              <option value="I/P">I/P</option>
            </select>
            {errors.materialType && <p className="text-red-500 text-sm mt-1">{errors.materialType}</p>}
          </div>

          <button
            type="submit"
            className="mt-4 px-3 py-1.5 text-sm float-right bg-rose-500 text-white rounded-md hover:bg-rose-600 transition"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default MaterialModal;
