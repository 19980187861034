import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { FaPlus, FaTimes } from 'react-icons/fa';
import LogsheetDownloadModal from './LogsheetDownload';
import { FaDownload } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enIN } from 'date-fns/locale';
import config from '../config';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalComponent from './Modal';
import { format } from 'date-fns';
import { HiInformationCircle } from "react-icons/hi";
import ShowTable from './ShowTable';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SummaryTable from './BatchSummary';
import LoadingSpinner from './LoadingSpinner';

const CustomOption = (props) => {
  const { data, innerProps } = props;
  
  return (
    <div
      {...innerProps}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '6px',
        cursor: data.isDisabled ? 'not-allowed' : 'pointer',
      }}
    >
      <div style={{ fontSize: '0.80rem' }}>{data.label}</div>
      <div style={{ fontSize: '0.80rem' }}>{data.currentquantity || 'N/A'}</div>
    </div>
  );
};


const CustomDatePicker = ({ selectedDate, handleDateChange, isRowFilled, batchNo, minDate , factoryname, handleFactoryChange, isFactoryNameSetByModal}) => {
  return (
    <div className="p-2 relative"> {/* Ensure this container is relative */}
     <div className="flex flex-col">
        {/* Factory Name Selector */}
       
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl font-bold text-[#DF7F7F]">Logsheet</h1>
        <div className=" flex items-center">
          <label htmlFor="factory-name" className="mr-2 font-semibold">Factory Name:</label>
          <select
            id="factory-name"
            value={factoryname}
            onChange={handleFactoryChange}
            className="border border-gray-300 rounded px-2 py-1 w-48"
            disabled={isFactoryNameSetByModal}
          >
            <option value="KSR">KSR</option>
            <option value="AVR">Avatar</option>
          </select>
        </div>
        <div className="flex items-center">
          <div className="mr-4 flex items-center">
            <span className="font-semibold text-[#438FD1]">Batch No:</span>
            <span className="ml-2 text-gray-700 truncate w-48">{batchNo}</span>
          </div>
          <label htmlFor="date-picker" className="mr-2 font-semibold text-[#438FD1]">Date:</label>
          <div className="relative overflow-visible" > {/* Ensure this container is relative */}
            <DatePicker
              id="date-picker"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              locale={enIN}
              className="border border-gray-300 rounded px-2 py-1 w-40"
              readOnly
            
              
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};




const Logsheet = () => {
  const initialRow = {
    time: '',
    material: '',
    qty: '',
    from: '',
    ebOpening: '',
    ebClosing: '',
    ldoOpening: '',
    ldoClosing: '',
    reactor: '',
    reactorTempOpening: '',
    reactorTempClosing: '',
    reactorPressure: '',
    receiverPressure: '',
    circulatingPumpPressure: '',
    boilerOpening: '',
    boilerClosing: '',
    waterOpening: '',
    waterClosing: '',
    Others: '',
    product: '',
    pqty: '',
    ptank: '',
    noOfOperators: '',
    operatorName: '',
    remarks: ''
  };
  const [loading, setLoading] = useState(false);
  const [batchNo, setBatchNo] = useState('');
  const [factoryname, setFactoryname] = useState('KSR'); // Default factory name
  const [isFactoryNameSetByModal, setIsFactoryNameSetByModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ProductOptions, setProductOptions] = useState([]);
  const [rows, setRows] = useState(Array(12).fill(initialRow));
  const [materialOptions, setMaterialOptions] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isRowFilled, setIsRowFilled] = useState(false);
  const currentDate = new Date();
  const minDate = isRowFilled ? currentDate : null;
  const [istrue, setIstrue] = useState(false); // Add istrue state
  const [istable, setIstable] = useState(false); // Add istrue state
  const [materialShowOptions, setMaterialShowOptions] = useState([]);


  const [currentQuantities, setCurrentQuantities] = useState({});
  const [tankLookup, setTankLookup] = useState({});
  const [tankCapacities, setTankCapacities] = useState({});
  const [materialTypes, setMaterialTypes] = useState({});


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tanks, setTanks] = useState([]);
  const [selectedTank, setSelectedTank] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

 
  const handleFactoryChange = (event) => {
    const newFactoryname = event.target.value;
    setFactoryname(newFactoryname);
  };
  

  const reactorOptions = [
    { value: 'N/A', label: 'N/A' },
    { value: 'R1', label: 'R1' },
    { value: 'R2', label: 'R2' },
    { value: 'R3', label: 'R3' },
    { value: 'R4', label: 'R4' }
  ];
// Initial empty summary row structure
const initialSummaryRow = {
  solvent: { name: '', qty: '' },
  condensateQty: '',
  finishProduct: { name: '', qty: '' },
  residueQty: '',
  editable: true
};
const [summary, setSummary] = useState({
  summaryData: [initialSummaryRow], // Start with one empty summary row
  ebUsage: 0, // Total EB usage
  ldoUsage: 0, // Total LDO usage

});
  

  useEffect(() => {
    console.log('Rows data:', rows); // Debugging log
  
    let totalEbUsage = 0;
    let totalLdoUsage = 0;
  
  // Calculate EB and LDO usage from the `rows`
  rows.forEach(row => {
      const ebOpening = isNaN(parseFloat(row.ebOpening)) ? 0 : parseFloat(row.ebOpening);
      const ebClosing = isNaN(parseFloat(row.ebClosing)) ? 0 : parseFloat(row.ebClosing);
      const ldoOpening = isNaN(parseFloat(row.ldoOpening)) ? 0 : parseFloat(row.ldoOpening);
      const ldoClosing = isNaN(parseFloat(row.ldoClosing)) ? 0 : parseFloat(row.ldoClosing);
   

      totalEbUsage += (ebClosing - ebOpening);
      totalLdoUsage += (ldoOpening - ldoClosing);
    });
  
    console.log('Total EB Usage:', totalEbUsage); // Correct calculation
    console.log('Total LDO Usage:', totalLdoUsage);
  
    setSummary(prevSummary => ({
      ...prevSummary,
      ebUsage: totalEbUsage,
      ldoUsage: totalLdoUsage,
    }));
  }, [rows]);
  
  useEffect(() => {
    // Log the entire summary object
    console.log('Updated Summary:', summary);
    
    // Log specific values if needed
    console.log('Updated EB Usage:', summary.ebUsage);
    console.log('Updated LDO Usage:', summary.ldoUsage);
    console.log('Rows:', summary.summaryData);
  }, [summary]); // Log whenever `summary` changes
  
  
 // Add a new waste material row
const addWasteMaterial = () => {
  setSummary(prevSummary => ({
    ...prevSummary,
    summaryData: [
      ...prevSummary.summaryData,
      {
        solvent: { name: '', qty: '' },
        condensateQty: '',
        finishProduct: { name: '', qty: '' },
        residueQty: '',
        editable: true // Newly added rows should be editable
      }
    ]
  }));
};
  

  // Remove a waste material row
  const removeWasteMaterial = (index) => {
    const updatedRows = summary.summaryData.filter((_, i) => i !== index);
    setSummary(prevSummary => ({
      ...prevSummary,
      summaryData: updatedRows
    }));
  };

 // Handle row value changes
 const handleRowChange = (index, field, value) => {
  const updatedRows = [...summary.summaryData];
  updatedRows[index][field] = value;
  setSummary(prevSummary => ({
    ...prevSummary,
    summaryData: updatedRows
  }));
};
 
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setRows(rows.map(row => {
      // Combine the selected date with the current time of each row
      const combinedDateTime = formatDateTime(date, row.time);
      return { ...row, date, time: combinedDateTime }; // Store the combined date and time
    }));
  };
  

  const handleTimeChange = (index, value) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], time: value };

      // Format the full datetime with the selected date and the updated time
      const formattedDateTime = format(new Date(`${format(selectedDate, 'yyyy-MM-dd')} ${value}`), 'yyyy-MM-dd HH:mm:ss');


      return newRows;
    });
  };
  
  
    // Effect to check if any row is filled
    useEffect(() => {
      const hasFilledRows = rows.some(row => Object.values(row).some(value => value !== ''));
      setIsRowFilled(hasFilledRows);
    }, [rows]);

  // Function to fetch or generate batchNo
  const fetchBatchNo = async (factoryname) => {
    try {
      const response = await axios.get(`${config.apiUrl}/saverows/generate-batch-number/${factoryname}`);
      const batchNumber = response.data.batchNumber;
      console.log(batchNumber);
      
    
      if (batchNumber) {
        setBatchNo(batchNumber);
      } else{
        setBatchNo('')
      }
    } catch (error) {
      console.error('Error fetching batch number:', error);
      setBatchNo(''); // Optionally handle errors by setting an empty batch number
    }
  };
  


  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/oil-storage`);
      const data = response.data;
       // Separate options based on materialType
       const rawMaterialOptions = data
       .filter(item => item.materialtype === 'rawmaterial')
       .map(item => ({
         value: `${item.oilname}_${item.tankname}`, // Combine oilname and tankname for value
         label: item.oilname ,// Show only oilname in the label
         isDisabled: item.currentquantity <= 0, // Disable option if quantity is 0
         currentquantity: item.currentquantity
       }));

     const ProductOptions = data
      .filter(item => item.materialtype === 'product' || item.materialtype === 'solvent' || item.materialtype === 'I/P') // Include both 'product' and 'solvent'
       .map(item => ({
         value: `${item.oilname}_${item.tankname}`, // Combine oilname and tankname for value
         label: item.oilname ,
         currentquantity: item.currentquantity
       }));

     // Set options based on type
     setMaterialOptions(rawMaterialOptions);
     setProductOptions(ProductOptions);

   
      

      // Convert the data into dictionaries with unique keys
      const quantities = data.reduce((acc, item) => {
        const key = `${item.oilname}_${item.tankname}`;
        acc[key] = item.currentquantity;
        return acc;
      }, {});
      setCurrentQuantities(quantities);

      // Create a lookup dictionary for combined oilname and tankname to tankname
      const tankDict = data.reduce((acc, item) => {
        const key = `${item.oilname}_${item.tankname}`;
        acc[key] = item.tankname;
        return acc;
      }, {});
      setTankLookup(tankDict);

      // Set tank capacities
      const capacities = data.reduce((acc, item) => {
        const key = `${item.oilname}_${item.tankname}`;
        acc[key] = item.tankcapacity; // Assuming 'tankcapacity' is the field for capacities
        return acc;
      }, {});
      setTankCapacities(capacities);

      const materialTypes = data.reduce((acc, item) => {
        const key = `${item.oilname}_${item.tankname}`;
        acc[key] = item.materialtype; // Store materialType
        return acc;
      }, {});
      setMaterialTypes(materialTypes);
      

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };





  useEffect(() => {
    if (factoryname && !isFactoryNameSetByModal) {
      fetchBatchNo(factoryname);
    }
    fetchData();
  }, [factoryname, isFactoryNameSetByModal]); 
  



  
  
  
 
  const addRow = () => {
    setRows(prevRows => [...prevRows, {...initialRow}]);
  };

  const removeRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    setRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value
      };
      
      // Clear validation error
      setInvalidFields(prev => prev.filter(f => !(f.row === index && f.field === field)));
      
      return updatedRows;
    });
  };
  

  const handleQtyChange = (index, field, value) => {
    // Check if value is empty or not a number
    const sanitizedValue = value === '' ? 0 : parseFloat(value);
  
    // Ensure valid values are non-negative
    const validValue = isNaN(sanitizedValue) ? 0 : Math.max(0, sanitizedValue);
  
    // Update the specific field in the row
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: validValue };
  
    // Update state with new rows
    setRows(updatedRows);
     // Clear validation error for the specific row and field
  setInvalidFields(prev => prev.filter(f => !(f.row === index && f.field === field)));
  };
  

  const handleMaterialChange = (index, selectedOption) => {
    const updatedRows = [...rows];
    const combinedValue = selectedOption ? selectedOption.value : 'N/A';
    
    if (combinedValue === "N/A") {
      updatedRows[index] = {
        ...updatedRows[index],
        material: "N/A",
        from: "N/A",
        qty:0
      }
     // Clear validation errors related to material and qty
     setInvalidFields(prev => prev.filter(f => !(f.row === index && (f.field === 'material' || f.field === 'qty'))));
    } else {
      const [oilname, tankname] = combinedValue.split('_');
      updatedRows[index] = {
        ...updatedRows[index],
        material: oilname ,
        from: tankname
      };
    }
  
    setRows(updatedRows);
    setInvalidFields(prev => prev.filter(f => !(f.row === index && (f.field === 'material'))));
  };
  

  const handleProductChange = (index, selectedOption) => {
    const updatedRows = [...rows];
  
    // Extract the tank name from selected option
    const Product = selectedOption ? selectedOption.value : 'N/A';
  
    if (Product === "N/A") {
      // If no option is selected, set product, tank, and quantity to 'N/A' or 0
    updatedRows[index] = {
      ...updatedRows[index],
      product: "N/A",
      ptank: "N/A",
      pqty: 0
    };
      setInvalidFields(prev => prev.filter(f => !(f.row === index && (f.field === 'product' || f.field === 'pqty' || f.field ==='ptank'))));
    } else {
       updatedRows[index] = {
        ...updatedRows[index],
        product: Product ,
      };
    }
    setRows(updatedRows);
     // Clear validation errors related to product and quantity
  setInvalidFields(prev => prev.filter(f => !(f.row === index && (f.field === 'product'))));
   
  };

 const formatDateTime = (date, time) => {
    const combinedDateTime = new Date(`${format(date, 'yyyy-MM-dd')} ${time}`);
    return format(combinedDateTime, 'yyyy-MM-dd HH:mm:ss');
  };
  
  const handleSave = async () => {
    const hasrowid = rows.some(row => !row.id );
    if(!hasrowid){
      toast.error('please enter a new row')
      return; // Exit function early
    }
    if(!batchNo){
      toast.error('batchNo is required')
      return; // Exit function early
    }
    // Set form as submitted
    setIsSubmitted(true);
    setLoading(true);
  
  
    
    const { isValid, updatedRows } =await validateRowsBeforeSubmission(rows);
    if (!isValid) {
      setRows(updatedRows);
  
      const allInvalidFields = updatedRows.flatMap((row, rowIndex) => {
        const fields = ['time','material','qty','from', 'ebOpening', 'ebClosing', 'ldoOpening', 'ldoClosing', 'reactor','reactorTempOpening', 'reactorTempClosing', 'reactorPressure', 'receiverPressure','circulatingPumpPressure','boilerOpening', 'boilerClosing', 'waterOpening', 'waterClosing','Others','product','ptank','pqty','noOfOperators','remarks'];
        return fields
          .filter(f => !row[f] && (f !== 'qty' || row['material'] !== 'N/A') && (f !== 'pqty' || row['product'] !== 'N/A'))
          .map(f => ({ row: rowIndex, field: f }));
      });
  
      setInvalidFields(allInvalidFields);
      setLoading(false);
      return;
    }
  
    const hasMaterial = rows.some(row => row.material && row.material !== 'N/A');
    if (!hasMaterial) {
      toast.error('At least one row must have a selected material');
      setLoading(false);
      return;
    }
  
    const allInvalidFields = rows.flatMap((row, rowIndex) => {
      const fields = ['time', 'material', 'qty', 'from', 'ebOpening', 'ebClosing', 'ldoOpening', 'ldoClosing', 'reactor', 'reactorTempOpening', 'reactorTempClosing', 'reactorPressure', 'receiverPressure', 'circulatingPumpPressure', 'boilerOpening', 'boilerClosing', 'waterOpening', 'waterClosing', 'Others', 'product', 'ptank', 'pqty', 'noOfOperators', 'remarks'];
      return fields
        .filter(f => (row[f] === undefined || row[f] === null || row[f] === '') && 
          (f !== 'qty' || row['material'] !== 'N/A') && 
          (f !== 'pqty' || row['product'] !== 'N/A'))
        .map(f => ({ row: rowIndex, field: f }));
    });
    
  
    if (allInvalidFields.length > 0) {
      setInvalidFields(allInvalidFields);
      setLoading(false);
      return;
    }
   // Ensure at least one row contains a non-'N/A' reactor value
  // Ensure at least one row contains a non-'N/A' reactor value
  const hasValidReactor = rows.some(row => row.reactor && row.reactor !== 'N/A');
  if (!hasValidReactor) {
    toast.error('Select a Reactor');
    setLoading(false); // Stop loading indicator
    return;
  }
 // Ensure at least one row contains a non-'N/A' reactor value
 const hasValidOperatorName = rows.some(row => row.operatorName && row.operatorName !== 'N/A');
 if (!hasValidOperatorName) {
   toast.error('Enter a Operator Name');
   setLoading(false); // Stop loading indicator
   return;
 }
    // Check if summary data is filled
  if (!summary.ebUsage || !summary.ldoUsage) {
    toast.error('Please fill in the summary details before saving');
    setLoading(false);
    return;
  }
    // Reset the form submission state before actually submitting
    setIsSubmitted(false);
  
    // Format rows and log the formatted data
    const formattedRows = rows.map(row => ({
      ...row,
      factoryname: rows[0].factoryname || factoryname, 
      batchNo:rows[0].batchNo || batchNo,
      Others: row.Others || 'N/A',
      noOfOperators: row.noOfOperators || 'N/A',
      remarks: row.remarks || 'N/A',
      operatorName: row.operatorName || 'N/A',
      pqty: parseFloat(row.pqty).toFixed(2) // Ensure 2 decimal places
      
    }));
  
    console.log('Formatted Rows:', formattedRows);
  
    try {
      const payload = { entries: formattedRows };
      console.log('Request Payload:', payload);
  
      const response = await axios.post(`${config.apiUrl}/saverows/save`, payload);
      if (response.status === 200) {
      // Stock quantity update logic for new rows
      const newRows = rows.filter(row => !row.id);
      if (newRows.length > 0) {
        await updateStockQuantities(newRows);
      }
      setSavedRows([...savedRows, ...newRows]);
      toast.success('Data saved successfully!');
     await fetchBatchNo(rows[0].factoryname || factoryname);
     await fetchData();
      resetForm();
// Check if summary contains any data and call the submitsummary endpoint if needed
const hasSummaryData = summary.ebUsage || summary.ldoUsage ;
if (hasSummaryData) {
  const summaryPayload = {
    ebUsage: summary.ebUsage,
    ldoUsage: summary.ldoUsage,
    summaryData: summary.summaryData, 
    batchNo: rows[0].batchNo || batchNo,
  };
  console.log('Summary Payload:', summaryPayload);
  const summaryResponse = await axios.post(`${config.apiUrl}/summary/submit`, summaryPayload);
  if (summaryResponse.status === 200) {
    toast.success('Summary data saved successfully!');
     // Reset the summary state after successful submission
        setSummary({
          ebUsage: 0,
          ldoUsage: 0,
          summaryData: [initialSummaryRow], // Reset to the initial row structure
        });
  }
}
} else {
  toast.error('Failed to save data!');
}
    }catch (error) {
      console.error('Error saving data:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach(err => toast.error(err));
      } else {
        toast.error('Failed to save data!');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
       // Filter out rows that are new and not yet saved
  const newRows = rows.filter(row => !row.id);

  // Check if there are new rows that haven't been saved
  if (newRows.length > 0) {
    toast.error('Please save the new rows and then submit.');
    return; // Exit function early
  }
  
    if(!batchNo){
      toast.error('batchNo is required')
      setLoading(false);
      return; // Exit function early
    }
  
  // Set form as submitted
  setIsSubmitted(true);
  setLoading(true);
  

  const hasProduct = rows.some(row => row.product && row.product !== 'N/A');

  if (!hasProduct) {
    toast.error('At least one row must have a selected product.');
    setLoading(false);
    return;
  }
  
    // Ensure all mandatory fields are filled
    const allInvalidFields = rows.flatMap((row, rowIndex) => {
      const fields = ['time','material','qty','from','ebOpening','ebClosing','ldoOpening','ldoClosing','reactor','reactorTempOpening', 'reactorTempClosing', 'reactorPressure', 'receiverPressure','circulatingPumpPressure', 'boilerOpening', 'boilerClosing', 'waterOpening', 'waterClosing','Others','product','ptank','pqty','noOfOperators','remarks'];
      return fields
      .filter(f => !row[f] && (f !== 'qty' || row['material'] !== 'N/A') && (f !== 'pqty' || row['product'] !== 'N/A'))
      .map(f => ({ row: rowIndex, field: f }));
  });
  
    if (allInvalidFields.length > 0) {
      setInvalidFields(allInvalidFields);
      setLoading(false);
      return;
    }
  // Ensure at least one row contains a non-'N/A' reactor value
  const hasValidReactor = rows.some(row => row.reactor && row.reactor !== 'N/A');
  if (!hasValidReactor) {
    toast.error('Select a Reactor');
    setLoading(false); // Stop loading indicator
    return;
  }
 // Ensure at least one row contains a non-'N/A' reactor value
 const hasValidOperatorName = rows.some(row => row.operatorName && row.operatorName !== 'N/A');
 if (!hasValidOperatorName) {
   toast.error('Enter a Operator Name');
   setLoading(false); // Stop loading indicator
   return;
 }
    // Reset the form submission state before actually submitting
    setIsSubmitted(false);
    // Format rows for submission
    const formattedRows = rows.map(row => ({
      ...row,
      factoryname: rows[0].factoryname || factoryname, 
      batchNo:rows[0].batchNo || batchNo,
      Others: row.Others || 'N/A',
      noOfOperators: row.noOfOperators || 'N/A',
      remarks: row.remarks || 'N/A'
    }));
  
    try {
      const response = await axios.post(`${config.apiUrl}/logsheet/submit`, { entries: formattedRows });
  
      if (response.data.message === 'Duplicate batch number(s) detected') {
        // Handle duplicate batch number error
        const duplicates = response.data.duplicates.join(', ');
        toast.error(`Duplicate batch number(s) detected: ${duplicates}`);
        setLoading(false);
      } else {
        toast.success('Data submitted successfully!');
        setSummary({
          ebUsage: 0,
          ldoUsage: 0,
          summaryData: [initialSummaryRow], // Reset to the initial row structure
        });
        await fetchData();
        setIsFactoryNameSetByModal(false);
        fetchBatchNo(rows[0].factoryname);
        resetForm();

      }
    } catch (error) {
      console.error('Error:', error);
      // Check if the error response has a message indicating stock quantity issue
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
        setLoading(false);
      } else {
        toast.error('Error submitting logsheet!');
      }
    }
    finally {
      // Always stop the loading indicator
      setLoading(false);
    }
  };

  
  const formatNumber = (num) => num.toFixed(2);
 // Validate rows before submission
 const validateRowsBeforeSubmission = (rowsToValidate) => {
  const totals = {};
  let errorMessages = [];
  let isValid = true;
// Filter rows without an id
const rowsWithoutId = rowsToValidate.filter(row => !row.id);
  // Calculate total quantities for each combination of material and from, or product and ptank
  rowsWithoutId.forEach((row) => {
    const { material, from, product, ptank, qty, pqty } = row;
    const numericQty = Number(qty) || 0;
    const numericPqty = Number(pqty) || 0;

    
    let combinedValue;
    let currentQty;

    if (material && from) {
      // For raw materials
      combinedValue = `${material}_${from}`;
      currentQty = Number(currentQuantities[combinedValue]) || Infinity;

      if (!totals[combinedValue]) {
        totals[combinedValue] = { totalQty: 0, currentQty, rows: [], type: 'material' };
      }

      totals[combinedValue].totalQty += numericQty;
      totals[combinedValue].rows.push(row);
    }  if (product && ptank) {
      // For products
      combinedValue = `${product}_${ptank}`;
      currentQty = Number(currentQuantities[combinedValue]) || 0;
      const tankCapacity = Number(tankCapacities[combinedValue]) || Infinity;

      if (!totals[combinedValue]) {
        totals[combinedValue] = { totalQty: 0, currentQty, tankCapacity, rows: [], type: 'product' };
      }

      totals[combinedValue].totalQty += numericPqty;
      totals[combinedValue].rows.push(row);
    }
  });

  let updatedRows = [...rowsToValidate];

  // Validate quantities and update rows if needed
  Object.keys(totals).forEach((combinedValue) => {
    const { totalQty, currentQty, tankCapacity, rows, type } = totals[combinedValue];

    
    if (type === 'material') {
      // Validation for raw materials
      if (totalQty > currentQty) {
        const formattedTotalQty = formatNumber(totalQty);
        const formattedCurrentQty = formatNumber(currentQty);
        const message = `${rows[0].material} Entered Quantity: ${formattedTotalQty}, Available Quantity: ${formattedCurrentQty}.`;
        if (!errorMessages.includes(message)) {
          errorMessages.push(message);
        }

        updatedRows = updatedRows.map(row => {
          if (row.material && row.from && `${row.material}_${row.from}` === combinedValue) {
            const rowTotalQty = rows.reduce((sum, r) => r === row ? sum + Number(r.qty) : sum, 0);
            if (rowTotalQty > currentQty) {
              return { ...row }; // Reset to empty if exceeds
            }
          }
          return row;
        });
        isValid = false;
      }
    }
    
  });

  // Display all collected error messages
  if (errorMessages.length > 0) {
    errorMessages.forEach(message => toast.error(message));
  }
 
  return { isValid, updatedRows, errorMessages };
};





  const updateStockQuantities = async (entries) => {
    try {
      const updates = entries.map(entry => ({
        tankname: entry.from, 
        quantity: entry.qty,
        material: entry.material ,
        product:entry.product,
        ptank:entry.ptank,
        pqty:entry.pqty
      }));
  
      await axios.put(`${config.apiUrl}/stock/updateStockQuantity`, updates);
      toast.success('Stock quantities updated successfully!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error updating stock quantities!');
      }
      console.error('Error updating stock quantities:', error);
    }
  };

  const resetForm = () => {
    setRows(Array(12).fill(initialRow)); 
    setSelectedDate(new Date()); 
    setBatchNo(''); 
  
  };

  const options = [
    { value: '', label: 'Select' },
    { value: 'Batch Start', label: 'Batch Start' },
    { value: 'Batch Stop', label: 'Batch Stop' },
    { value: 'Under Process', label: 'Under Process' },
    { value: 'Under Transfer', label: 'Under Transfer' },
    { value: 'Under Cooling', label: 'Under Cooling' },
    { value: 'IP Start', label: 'IP Start' },
    { value: 'IP Stop', label: 'IP Stop' },
    { value: 'Centrifuge Start', label: 'Centrifuge Start' },
    { value: 'Centrifuge Stop', label: 'Centrifuge Stop' },
    { value: 'Condensate Start', label: 'Condensate Start' },
    { value: 'Condensate Stop', label: 'Condensate Stop' },
  
  ];
 
  const handleDateTimeChange = (index, value) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index],  time: value };
    return newRows;
    });
  };
 
 const updateRow = (newRows) => {
  setRows(prevRows => {
    console.log('Previous Rows:', prevRows);
    console.log('New Rows:', newRows);
    const factories = newRows.map(row => row.factoryname);
    if (factories.length > 0) {
      setFactoryname(factories[0]); 
      setIsFactoryNameSetByModal(true); 
    }

    const updatedRows = [...prevRows];


    newRows.forEach((row, index) => {
      console.log(`Row ${index} with BatchNo:`, row.batchNo);

      if (index < updatedRows.length) {

        updatedRows[index] = {
          ...updatedRows[index],
          ...row,
          batchNo: row.batchNo,
          factoryname:row.factoryname
          
        };
        console.log(`Updated Row no needed  extra rows${index + 1}:`, updatedRows[index]);
      } else {
        updatedRows.push({
          ...row, 
          batchNo: row.batchNo,
          factoryname:row.factoryname
        });
        console.log(`Added New Row ${index + 1}:`, updatedRows[index]);
      }
    });
    const finalRows = updatedRows.slice(0, newRows.length);

    console.log('Final Rows before return:', finalRows);
    return finalRows;
  });
   
};

const openTable = () => {
  setIstable(true);
};

const closeTable = () => {
  setIstable(false);
};
const handleBatchNoSelect = (selectedBatchNo) => {
  setBatchNo(selectedBatchNo);
};

  const fetchMaterialData = async () => {
        try {
          const response = await axios.get(`${config.apiUrl}/stock/getOilData`);
          if (response.data) {
      const materials = response.data.map(item => ({
        value: item.oilname,
        label: item.oilname
      }));
              // Add "N/A" option as the first item
            const optionsWithNA = [{ value: 'N/A', label: 'N/A' }, ...materials];

            setMaterialShowOptions(optionsWithNA);
          } else {
            console.error('Unexpected response format for materials and types:', response.data);
          }
        } catch (error) {
          console.error('Error fetching material data:', error);
        }
      };

      useEffect(() => {
        fetchMaterialData();
        fetchTankData();
      }, []);

      const fetchTankData = async () => {
        try {
       const response = await axios.get(`${config.apiUrl}/stock/getstoragetankData`);
       if (response.data) {
                setTanks(response.data.data);
            }else {
              console.error('Unexpected response format for tanks:', response.data);
            }
          } catch (error) {
            console.error('Error fetching material data:', error);
          }
        }
        
        const handleSelectChange = (index, selectedOption) => {
          const updatedRows = [...rows];
          
          updatedRows[index] = {
            ...updatedRows[index],
            ptank: selectedOption?.value || '', 
          };
          
          setRows(updatedRows);
          setSelectedTank(selectedOption.value); 
        };
        
        
  return (
    <>
    <div className="p-1 relative">
       <CustomDatePicker
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        isRowFilled={isRowFilled}
        batchNo={batchNo}
        minDate={minDate}
        factoryname={factoryname}
        handleFactoryChange={handleFactoryChange}
        isFactoryNameSetByModal={isFactoryNameSetByModal} 
      />
<ModalComponent 
        updateRow={updateRow} 
        setIstrue={setIstrue}
        setSummary={setSummary}
        istrue={istrue}
        setRows={setRows} 
       setFactoryname={setFactoryname} 
       initialRow={initialRow} 
       isFactoryNameSetByModal={isFactoryNameSetByModal} 
       setIsFactoryNameSetByModal={setIsFactoryNameSetByModal}  
       setBatchNo={handleBatchNoSelect}

 />
       <div className="absolute top-14 right-0 m-2">
     <div className="flex space-x-4 ">
        <button onClick={openModal} className="text-[#438FD1] hover:text-blue-900 flex items-center">
          <FaDownload size={20} />
          <span className="ml-2">Download</span> 
        </button>

        <button onClick={openTable} className="text-[#438FD1] hover:text-blue-900 flex items-center">
          <HiInformationCircle size={20} />
          <span className="ml-2">Info</span> 
        </button>
      </div>
        </div>
      <div className="w-full h-[76vh] overflow-auto">
        <div className="relative min-w-max">
       
          <form onSubmit={handleSubmit}>
            <table className="min-w-full bg-white border border-gray-300 ">
            <thead className="bg-gray-800 text-xs text-white sticky top-0 z-10">
  <tr>
    <th rowSpan="2" className="border border-gray-400 p-1 w-16 sticky left-0 bg-gray-800 z-20 pr-6 ">Time</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-32 sticky left-32 bg-gray-800 z-20">Materials</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">Feed Qty (KL)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">From Tank</th>
    <th colSpan="2" className="border border-gray-400 p-1 w-28">EB Reading (Kwh)</th>
    <th colSpan="2" className="border border-gray-400 p-1 w-28">LDO (KL)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-28 sticky left-56 bg-gray-800 z-20">Reactor</th>
    <th colSpan="2" className="border border-gray-400 p-1 w-28">Reactor Temp (°C)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-28">Reactor Pressure(mm/Hg)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-28">Receiver Pressure (mm/Hg)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-28">Circulating Pump Pressure (kg/cm<sup>2</sup>)</th>
    <th colSpan="2" className="border border-gray-400 p-1 w-28">Boiler Temp (°C)</th>
    <th colSpan="2" className="border border-gray-400 p-1 w-28">Water Temp (°C)</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-28 sticky lef-72 z-20">Others</th>
   
    <th colSpan="3" className="border border-gray-400 p-1 w-28">Storage Tank </th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">No. of Operators</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">Operator Name</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">Remarks</th>
    <th rowSpan="2" className="border border-gray-400 p-1 w-24">Actions</th>
  </tr>
  <tr>
    <th className="border border-gray-400 p-1">Open</th>
    <th className="border border-gray-400 p-1">Close</th>
    <th className="border border-gray-400 p-1">Open</th>
    <th className="border border-gray-400 p-1">Close</th>
    <th className="border border-gray-400 p-1">Open</th>
    <th className="border border-gray-400 p-1">Close</th>
    <th className="border border-gray-400 p-1">Open</th>
    <th className="border border-gray-400 p-1">Close</th>
    <th className="border border-gray-400 p-1">Open</th>
    <th className="border border-gray-400 p-1">Close</th>
    <th className="border border-gray-400 p-1">Product</th>
    <th className="border border-gray-400 p-1">Qty (KL)</th>
    <th className="border border-gray-400 p-1">Tank</th>
  </tr>
</thead>

              <tbody className='text-xs'>
                {rows.map((row, index) => (
                  <tr key={index}>
                   <td className="border border-gray-400 p-1 w-32 sticky left-0 bg-white">
  <input
    type="datetime-local"
    value={row.time}
    onChange={(e) => handleDateTimeChange(index, e.target.value)}
    className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'time') ? 'border-red-500' : 'border-gray-300'}`}
    // readOnly={row.editable}
  />
</td>

<td className="border border-gray-400 p-1 w-32 sticky left-32 bg-white">
  <Select
    options={[
      { value: "N/A", label: "N/A" }, 
      ...materialOptions
    ]}
    value={
      materialOptions.find(option => option.value === `${row.material}_${tankLookup[`${row.material}_${Object.values(tankLookup).find(tank => tank === row.from)}`]}`) ||
      (row.material === "N/A" ? { value: "N/A", label: "N/A" } : null)
    }
    onChange={(option) => handleMaterialChange(index, option)}
    className='p-1 rounded'
    components={{ Option: (props) => <CustomOption {...props} currentQuantities={currentQuantities} /> }}
    placeholder="Select" 
    isDisabled={row.editable}
    styles={{
      control: (provided) => ({
        ...provided,
        width: '100%',
        minHeight: '2rem',
        borderColor: isSubmitted && invalidFields.some(f => f.row === index && f.field === 'material') ? '#f87171' : '#d1d5db',
        boxShadow: 'none',
        borderRadius: '0.375rem',
        fontSize: '0.75rem',
      }),
      menu: (provided) => ({
        ...provided,
        width: '150px',
        zIndex: 9999,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '0.75rem', 
      }),
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
  />
</td>



<td className="border border-gray-400 p-1 w-24">
  <input
    type="number"
    value={row.qty}
    onChange={(e) => {
      const value = e.target.value;
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        handleChange(index, 'qty', value);
      }
    }}
    className={`w-full border-b border-gray-300 p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'qty') ? 'border-red-500' : 'border-gray-300'}`}
    min="0" 
    step="0.01" 
    readOnly={row.editable}
  />
</td>


                    <td className="border border-gray-400 p-1 w-24">
                      <input
                        type="text"
                        value={row.from}
                        onChange={(e) => handleChange(index, 'from', e.target.value)}
                        className={`border-b border-gray-300 p-1 rounded-none w-full text-sm${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'from') ? 'border-red-500' : 'border-gray-300'}`}
                        readOnly
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.ebOpening}
                        readOnly={row.editable}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex allows numbers with decimal points
                          if (/^\d*\.?\d{0,2}$/.test(value)) {
                            handleChange(index, 'ebOpening', value);
                          }
                        }}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'ebOpening') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.ebClosing}
                        readOnly={row.editable}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex allows numbers with decimal points
                          if (/^\d*\.?\d{0,2}$/.test(value)) {
                            handleChange(index, 'ebClosing', value);
                          }
                        }}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'ebClosing') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.ldoOpening}
                        readOnly={row.editable}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d{0,2}$/.test(value)) {
                            handleChange(index, 'ldoOpening', value)
                        } 
                      }}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'ldoOpening') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.ldoClosing}
                        readOnly={row.editable}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d{0,2}$/.test(value)) {
                            handleChange(index, 'ldoClosing',value)
                     }
                    }}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'ldoClosing') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28 sticky left-56 bg-white">
                      <Select
                        options={reactorOptions}
                        value={reactorOptions.find(option => option.value === row.reactor)|| null}
                        onChange={(option) => handleChange(index, 'reactor', option.value)}
                        className={`p-1 rounded  ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'reactor') ? 'border-red-500' : 'border-gray-300'}`}
                        isDisabled={row.editable}
                        styles={{
                          control: (provided) => ({
                              ...provided,
                              width: '100%',
                              minHeight: '2rem',
                              borderColor: isSubmitted && invalidFields.some(f => f.row === index && f.field === 'reactor') ? '#f87171' : '#d1d5db',
                              boxShadow: 'none',
                              borderRadius: '0.375rem',
                              fontSize: '0.75rem',
                          }),
                          menu: (provided) => ({
                              ...provided,
                              width: '100px',
                              zIndex: 9999,
                          }),
                          menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                          }),
                          placeholder: (provided) => ({
                              ...provided,
                              fontSize: '0.75rem',
                          }),
                          singleValue: (provided) => ({
                              ...provided,
                              fontSize: '0.75rem',
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: '0.75rem', // Adjust this value to your desired font size
                          }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                  />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.reactorTempOpening}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'reactorTempOpening', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'reactorTempOpening') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.reactorTempClosing}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'reactorTempClosing', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'reactorTempClosing') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.reactorPressure}
                        onChange={(e) => handleChange(index, 'reactorPressure', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'reactorPressure') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.receiverPressure}
                        onChange={(e) => handleChange(index, 'receiverPressure', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'receiverPressure') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.circulatingPumpPressure}
                        onChange={(e) => handleChange(index, 'circulatingPumpPressure', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'circulatingPumpPressure') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.boilerOpening}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'boilerOpening', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'boilerOpening') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.boilerClosing}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'boilerClosing', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'boilerClosing') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.waterOpening}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'waterOpening', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'waterOpening') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28">
                      <input
                        type="text"
                        value={row.waterClosing}
                        // readOnly={row.editable}
                        onChange={(e) => handleChange(index, 'waterClosing', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'waterClosing') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-28 sticky lef-72 ">
  <Select
    value={options.find(option => option.value === row.Others)}
    onChange={(selectedOption) => handleChange(index, 'Others', selectedOption ? selectedOption.value : '')}
    options={options}
    className="basic-single"
    classNamePrefix="select"
    isDisabled={row.editable}
    styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          minHeight: '2rem',
          borderColor: isSubmitted && invalidFields.some(f => f.row === index && f.field === 'Others') ? '#f87171' : '#d1d5db',
          boxShadow: 'none',
          borderRadius: '0.375rem',
          fontSize: '0.75rem',
        }),
        menu: (provided) => ({
          ...provided,
          width: '150px',
          zIndex: 9999,
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: '0.75rem',
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: '0.75rem',
        }),
        option: (provided) => ({
          ...provided,
          fontSize: '0.75rem', // Adjust this value to your desired font size
        }),
      }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
 
  />
</td>
<td className="border border-gray-400 p-1 w-32 sticky left-16 bg-white">
  <Select
 options={materialShowOptions}
 value={materialShowOptions.find(option => option.value === row.product)|| null}
 onChange={(option) => handleProductChange(index, option)}
    className="p-1 rounded"
    placeholder="Select"
    isDisabled={row.editable}
    styles={{
      control: (provided) => ({
        ...provided,
        width: '100%',
        minHeight: '2rem',
        borderColor: isSubmitted && invalidFields.some(f => f.row === index && f.field === 'product') ? '#f87171' : '#d1d5db',
        boxShadow: 'none',
        borderRadius: '0.375rem',
        fontSize: '0.75rem',
      }),
      menu: (provided) => ({
        ...provided,
        width: '150px',
        zIndex: 9999,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
      }),
    }}
    menuPortalTarget={document.body}
    menuPosition="fixed"
  />
</td>


<td className="border border-gray-400 p-1 w-28">
  <input
    type="number"
    value={row.pqty}
    readOnly={row.editable}
    onChange={(e) => {
      const value = e.target.value;
      // Regex allows numbers with decimal points
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        handleQtyChange(index, 'pqty', value);
      }
    }}
    className={`w-full border-b p-1 rounded-none ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'pqty') ? '#f87171' : '#d1d5db'}`}
    min="0" // Prevent negative numbers
    step="0.01" // Allow decimal values with two decimal places
  />
</td>


<td className="border border-gray-400 p-1 w-28">
    <Select
        options={[
            { value: 'N/A', label: 'N/A' },
            ...tanks.map(tank => ({ value: tank.tankname, label: tank.tankname }))
        ]}
        value={row.ptank ? { value: row.ptank, label: row.ptank } : null} 
        isDisabled={row.editable} 
        onChange={(option) => handleSelectChange(index, option)}
        className="p-1 rounded"
        styles={{
          control: (provided) => ({
            ...provided,
            width: '100%',
            minHeight: '2rem',
            borderColor: isSubmitted && invalidFields.some(f => f.row === index && f.field === 'ptank') ? '#f87171' : '#d1d5db',
            boxShadow: 'none',
            borderRadius: '0.375rem',
            fontSize: '0.75rem',
          }),
          menu: (provided) => ({
            ...provided,
            width: '150px',
            zIndex: 9999,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: '0.75rem',
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: '0.75rem',
          }),
          option: (provided) => ({
            ...provided,
            fontSize: '0.75rem',
          }),
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
    />
</td>

                    <td className="border border-gray-400 p-1 w-24">
                      <input
                        type="number"
                        // readOnly={row.editable}
                        value={row.noOfOperators}
                        onChange={(e) => handleChange(index, 'noOfOperators', e.target.value.replace(/[^0-9]/g, ''))}
                        className={`border-b border-gray-300 p-1 rounded-none w-full ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'noOfOperators') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-24">
                      <input
                        type="text"
                        // readOnly={row.editable}
                        value={row.operatorName}
                        onChange={(e) => handleChange(index, 'operatorName', e.target.value.replace(/[^a-zA-Z\s]/g, ''))}
                        className={`border-b border-gray-300 p-1 rounded-none w-full ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'operatorName') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-24">
                      <input
                        type="text"
                        value={row.remarks}
                        onChange={(e) => handleChange(index, 'remarks', e.target.value)}
                        className={`border-b border-gray-300 p-1 rounded-none w-full ${isSubmitted && invalidFields.some(f => f.row === index && f.field === 'remarks') ? 'border-red-500' : 'border-gray-300'}`}
                      />
                    </td>
                    <td className="border border-gray-400 p-1 w-24 text-center">
                      {rows.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeRow(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTimes />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
         
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
              <button
                type="button"
                onClick={addRow}
                className="flex items-center bg-[#438FD1] text-white px-3 py-1.5 text-xs rounded hover:bg-blue-600"
              >
                <FaPlus className="mr-2" />
                Add Row
              </button>
              <button
        type="submit"
        onClick={handleSubmit}
        className={`ml-4 px-3 py-1.5 text-xs rounded ${isFactoryNameSetByModal ? 'bg-[#9A4C5E] text-white ' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
        disabled={!isFactoryNameSetByModal}
        data-tooltip-id="submit-tooltip"
        data-tooltip-content={!isFactoryNameSetByModal ? 'You must select at least one saved batch to submit.' : ''}
      >
        Submit
      </button>
              <button  className="ml-4 bg-[#607D73] text-white px-3 py-1.5 text-xs rounded hover:bg-green-600"onClick={handleSave}>Save</button>
            </div>
            {isModalOpen && <LogsheetDownloadModal closeModal={closeModal} />}
            {istable && <ShowTable closeTable={closeTable} />}
            <ReactTooltip id="submit-tooltip" effect="solid" place="top" />
            {loading && <LoadingSpinner />}
            <SummaryTable
      rows={summary.summaryData}
      ebUsage={summary.ebUsage}
      ldoUsage={summary.ldoUsage}
      onRowChange={handleRowChange}
      onAddWasteMaterial={addWasteMaterial}
      onRemoveWasteMaterial={removeWasteMaterial}
     
    />
    </div>
  </>
  );
};

export default Logsheet;
