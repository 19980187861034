import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

const SummaryTable = ({ rows, ebUsage, ldoUsage, onRowChange, onAddWasteMaterial, onRemoveWasteMaterial }) => {
  return (
    <div className="w-full mt-4 overflow-x-auto">
      <table className="min-w-full table-fixed bg-white border border-gray-300">
        <thead className="bg-gray-800 text-xs text-white">
          <tr>
            <th className="border border-gray-400 p-2">Eb Usage</th>
            <th className="border border-gray-400 p-2">LDO Usage</th>
            <th className="border border-gray-400 p-2">Name & Quantity</th>
            <th className="border border-gray-400 p-2">Condensate Quantity</th>
            <th className="border border-gray-400 p-2">Finish Product & Quantity</th>
            <th className="border border-gray-400 p-2">Residue Quantity</th>
            <th className="border border-gray-400 p-2">Actions</th>
          </tr>
        </thead>
        <tbody className="text-xs text-gray-700">
          {rows.map((row, index) => (
            <tr key={index}>
              {index === 0 && (
                <>
                  <td rowSpan={rows.length} className="border border-gray-400 p-2 text-center">
                    {(ebUsage ?? 0).toFixed(2)}
                  </td>
                  <td rowSpan={rows.length} className="border border-gray-400 p-2 text-center">
                    {(ldoUsage ?? 0).toFixed(2)}
                  </td>
                </>
              )}
              <td className="border border-gray-400 p-2">
                <input
                  type="text"
                  value={row.solvent?.name || ''} // Safe access with default value
                  onChange={(e) => onRowChange(index, 'solvent', { ...row.solvent, name:  e.target.value.replace(/[^a-zA-Z\s]/g, '')})}
                  className="w-full px-2 py-1 border border-gray-300"
                  placeholder="Name"
                  readOnly={!row.editable}

                />
               <input
  type="text"
  value={row.solvent?.qty || 0} // Safe access with default value
  onChange={(e) => {
    const value = e.target.value;
    // Regex allows numbers with up to two decimal points
    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
      onRowChange(index, 'solvent', { ...row.solvent, qty: value });
    }
  }}
  className="w-full mt-1 px-2 py-1 border border-gray-300"
  placeholder="Qty"
  readOnly={!row.editable}
/>

              </td>
              <td className="border border-gray-400 p-2">
              <input
  type="text"
  value={row.condensateQty || 0 } // Safe access with default value
  onChange={(e) => {
    const value = e.target.value;
    // Regex allows numbers with up to two decimal points
    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
      onRowChange(index, 'condensateQty', value);
    }
  }}
  className="w-full px-2 py-1 border border-gray-300"
  placeholder="Condensate Qty"
  readOnly={!row.editable}
/>

              </td>
              <td className="border border-gray-400 p-2">
                <input
                  type="text"
                  value={row.finishProduct?.name || ''} // Safe access with default value
                  onChange={(e) => onRowChange(index, 'finishProduct', { ...row.finishProduct, name: e.target.value.replace(/[^a-zA-Z\s]/g, '')})}
                  className="w-full px-2 py-1 border border-gray-300"
                  placeholder="Finish Product"
                  readOnly={!row.editable}
                />
                <input
                  type="text"
                  value={row.finishProduct?.qty || 0 }// Safe access with default value
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex allows numbers with up to two decimal points
                    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
                      onRowChange(index, 'finishProduct', { ...row.finishProduct, qty: value });
                    }
                  }}
                  className="w-full mt-1 px-2 py-1 border border-gray-300"
                  placeholder="Finish Product Qty"
                  readOnly={!row.editable}
                />
              </td>
              <td className="border border-gray-400 p-2">
                <input
                  type="text"
                  value={row.residueQty || 0 } // Safe access with default value
                  onChange={(e) => {
                    const value = e.target.value;
                    // Regex allows numbers with up to two decimal points
                    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
                      onRowChange(index, 'residueQty', value );
                    }
                  }}
                  className="w-full px-2 py-1 border border-gray-300"
                  placeholder="Residue Qty"
                  readOnly={!row.editable}
                />
              </td>
              <td className="border border-gray-400 p-2 text-center">
                {rows.length > 1 && (
                  <button
                    className="px-2 py-1 text-red-500 rounded"
                    onClick={() => onRemoveWasteMaterial(index)}
                  >
                    <FaTimes />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center mt-4">
        <button
          className="px-4 py-1.5 text-xs bg-[#607D73] text-white rounded hover:bg-green-600 flex items-center"
          onClick={onAddWasteMaterial}
        >
          <FaPlus className="mr-1" /> Add
        </button>
      </div>
    </div>
  );
};

export default SummaryTable;
