import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCheck, FaTimes } from 'react-icons/fa';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminApprovalTable = () => {
  const [requests, setRequests] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    fetchApprovalRequests();
  }, []);

  const fetchApprovalRequests = () => {
    axios
      .get(`${config.apiUrl}/purchase/approval-requests`)
      .then((response) => {
        setRequests(response.data);
        console.log('res',response.data);
        setApprovalData(
          response.data.map((request) => ({
            RequestID: request.ID,
            AdminApproval: false,
            AdminCancel: false,
            Supplier: '',
            Status: '',
          }))
        );
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  const handleSupplierChange = (index, value) => {
    const newApprovalData = [...approvalData];
    newApprovalData[index].Supplier = value;
    setApprovalData(newApprovalData);
  };

  const handleApprove = (index) => {
    const newApprovalData = [...approvalData];
    newApprovalData[index].AdminApproval = true;
    newApprovalData[index].AdminCancel = false;
    newApprovalData[index].Status = 'Approved';
    setApprovalData(newApprovalData);
  };

  const handleDeny = (index) => {
    const newApprovalData = [...approvalData];
    newApprovalData[index].AdminApproval = false;
    newApprovalData[index].AdminCancel = true;
    newApprovalData[index].Status = 'Denied';
    setApprovalData(newApprovalData);
  };

  const handleSubmit = () => {
    setSubmit(true);
    const approvalWithoutSupplier = approvalData.some(
      (data) => data.Status === 'Approved' && data.Supplier === ''
    );

    if (approvalWithoutSupplier) {
      toast.error('Select suppliers');
      setSubmit(false);
      return;
    }

    const filteredApprovalData = approvalData.filter(
      (data) =>
        (data.Status === 'Approved' && data.Supplier !== '') ||
        data.Status === 'Denied'
    );

    if (filteredApprovalData.length === 0) {
      toast.error('Kindly approve or deny at least one request');
      setSubmit(false);
      return;
    }

    axios
      .patch(`${config.apiUrl}/purchase/admin-approval`, filteredApprovalData)
      .then((response) => {
        toast.success('Submitted successfully');
        fetchApprovalRequests(); // Refresh data after submission
        setSubmit(false);
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        setSubmit(false);
      });
  };

  return (
    <div className="container mx-auto mt-8">
      {requests.length === 0 ? (
        <div className="flex items-center justify-center h-[80vh]">
          <div className="text-center bg-yellow-200 p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold text-gray-700">No Data Available</h2>
            <p className="text-sm text-gray-600">There are currently no approval requests.</p>
          </div>
        </div>
      ) : (
        <div className="w-full h-[80vh] overflow-auto">
          <table className="min-w-full bg-transparent border border-gray-300">
            <thead className="bg-gray-800 text-gray-400 text-left text-xs font-bold uppercase">
              <tr>
                <th className="px-2 py-2">S.No</th>
                <th className="px-4 py-2">Material</th>
                <th className="px-4 py-2">Requested Qty</th>
                <th className="px-4 py-2">Tank</th>
                <th className="px-4 py-2">Current Material</th>
                <th className="px-4 py-2">Current Qty</th>
                <th className="px-4 py-2">Last Update</th>
                <th className="px-4 py-2">Plant Head</th>
                <th className="px-4 py-2">Supplier</th>
                <th className="px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
  {requests.map((request, index) => (
    <tr key={index} className="text-gray-500 text-xs font-semibold border-b">
      <td className="px-2 py-2">{index + 1}</td>
      <td className="px-4 py-2 bg-green-200 text-center">{request.RawMaterial}</td>
      <td className="px-4 py-2 bg-green-200 text-center">{request.Qty}</td>
      <td className="px-4 py-2 bg-blue-200 text-center">{request.TankName}</td>
      <td className="px-4 py-2 bg-orange-200 text-center">{request.oilname}</td>
      <td className="px-4 py-2 bg-orange-200 text-center">{request.currentquantity}</td>
      
      <td className="px-4 py-2 bg-orange-200">
        {request.lastupdateddate ? request.lastupdateddate.split(' ')[0] : 'N/A'}
      </td>
      <td className="px-4 py-2">{request.Planthead}</td>
      <td className="px-2 py-2">
        <select
          value={approvalData[index]?.Supplier || ''}
          onChange={(e) => handleSupplierChange(index, e.target.value)}
          className="w-full py-2 px-2"
        >
          <option value="">Select Supplier</option>
          <option value="Supplier 1">Supplier 1</option>
          <option value="Supplier 2">Supplier 2</option>
          <option value="Supplier 3">Supplier 3</option>
          <option value="Supplier 4">Supplier 4</option>
          <option value="Supplier 5">Supplier 5</option>
        </select>
      </td>
      <td>
        {approvalData[index]?.Status ? (
          <span
            className={`px-2 py-1 font-semibold leading-tight rounded-full ${
              approvalData[index].Status === 'Approved'
                ? 'bg-green-200 text-green-700'
                : 'bg-red-200 text-red-700'
            }`}
          >
            {approvalData[index].Status}
          </span>
        ) : (
          <div className="flex space-x-3">
            <button
              onClick={() => handleApprove(index)}
              className="px-2 py-1 text-green-600 hover:text-green-800 focus:outline-none"
            >
              <FaCheck />
            </button>
            <button
              onClick={() => handleDeny(index)}
              className="px-2 py-1 text-red-600 hover:text-red-800 focus:outline-none"
            >
              <FaTimes />
            </button>
          </div>
        )}
      </td>
    </tr>
  ))}
</tbody>

          </table>
          <div className="mt-4 flex justify-end">
            <button
              onClick={handleSubmit}
              disabled={submit}
              className="px-3 py-1 bg-gray-800 text-white rounded hover:bg-gray-900 focus:outline-none font-semibold"
            >
              {submit ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminApprovalTable;
