import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';


const StockSummaryDonut = ({stockSumData}) => {

    const chartSeries = stockSumData.map(item => parseFloat(item.total_quantity));
    const chartLabels = stockSumData.map(item => item.oilname);

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: chartLabels,
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                },
                offsetY: 0, // Ensure all segments fit properly
            },
        },
        dataLabels: {
            enabled: true, // Enable data labels
            formatter: (value, { seriesIndex }) => {
                // Use the seriesIndex to get the corresponding total_quantity
                const totalQuantity = chartSeries[seriesIndex];
                return parseFloat(totalQuantity).toFixed(2) + ' KL'; // Format the total quantity
            },
            style: {
                fontSize: '12px', // Adjust font size if necessary
            },
        },
        tooltip: {
            y: {
                formatter: (value) => parseFloat(value).toFixed(2) + ' KL',
            },
        },
        legend: {
            show: false, // Hide the legend
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%',
                },
                legend: {
                    position: 'bottom',
                },
            },
        }],
    };

    return (
        <div>
            <h2 className="text-lg font-medium mb-4">Total Stock Summary</h2>
            <Chart 
                options={chartOptions} 
                series={chartSeries} 
                type="donut" 
                width="400"
            />
        </div>
        
    );
};

export default StockSummaryDonut;
