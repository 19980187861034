import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import Chart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { format, startOfDay, endOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const CustomDatePicker = ({ selectedDate, onDateChange, onClear }) => (
  <div className="relative">
    <DatePicker
      selected={selectedDate}
      onChange={onDateChange}
      className="p-1 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 w-full text-xs"
      dateFormat="yyyy-MM-dd"
      placeholderText="Select a date"
      calendarClassName="bg-white border border-gray-300 rounded-lg shadow-md"
      dayClassName={(date) => (date.getDay() === 6 || date.getDay() === 0 ? 'text-red-500' : undefined)}
    />
    {selectedDate && (
      <button
        type="button"
        onClick={onClear}
        className="absolute inset-y-0 right-0 flex items-center pr-1"
      >
        <XCircleIcon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
      </button>
    )}
  </div>
);

const Prouductionsummary = () => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [entries, setEntries] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [error, setError] = useState('');

  const handleClearFrom = () => {
    setFrom(null);
    checkAndFetchData(null, to);
  };

  const handleClearTo = () => {
    setTo(null);
    checkAndFetchData(from, null);
  };

  const checkAndFetchData = (newFrom, newTo) => {
    const timezone = 'Asia/Kolkata'; 
    const localFrom = newFrom ? toZonedTime(newFrom, timezone) : null;
    const localTo = newTo ? toZonedTime(newTo, timezone) : null;

    if (localFrom && localTo && localFrom > localTo) {
      setError('The "From" date cannot be later than the "To" date.');
      return;
    }

    const customFrom = localFrom ? format(startOfDay(localFrom), 'yyyy-MM-dd') : null;
    const customTo = localTo ? format(endOfDay(localTo), 'yyyy-MM-dd') : null;

    fetchBatchEntries(customFrom, customTo);
  };

  const fetchBatchEntries = async (customFrom = null, customTo = null) => {
    try {
      const response = await axios.get(`${config.apiUrl}/logsheet/getbatchentries`, {
        params: { from: customFrom, to: customTo },
      });
      const data = response.data;
      console.log('getbatchentries',data);
      
// Formatter for tooltips and labels
const formatter = (value) => value.toFixed(2);

      const { series, categories, tooltipData } = transformData(data);
      setChartSeries(series);
      const dynamicColors = generateDynamicColors(series.length);
      setChartOptions({
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories,
          title: {
            text: 'Batch No',
            style: {
              fontSize: '9px',
            },
          },
          labels: {
            rotate: -40,
            style: {
              fontSize: '9px',
            },
          },
        },
        yaxis: {
          title: {
            text: 'Quantity',
          },
          labels: {
            formatter: formatter,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '25%',
            distributed: true,
            dataLabels: {
              position: 'top', // Shows data labels at the top of the bar
            },
          },
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        colors: dynamicColors,
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: true,
          },
          y: {
            formatter: formatter,
          },
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const batchNo = w.config.xaxis.categories[dataPointIndex];
            const productSeriesName = w.config.series[seriesIndex].name;
            const productQty = series[seriesIndex][dataPointIndex].toFixed(2);
        
            const detailedInfo = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        
            let tooltipContent = `
              <div style="padding: 10px; font-size: 12px;">
                <strong>Batch No: ${batchNo}</strong><br>
                ${productSeriesName}: ${productQty} Lts<br>
            `;
        
            Object.entries(detailedInfo).forEach(([product, qty]) => {
              if (qty !== 'N/A' && qty !== '' && qty !== 0) {
                tooltipContent += `<div>${product}: ${qty} Lts</div>`;
              }
            });
        
            tooltipContent += '</div>';
        
            return tooltipContent;
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => value.toFixed(2), // Ensures two decimal places
          style: {
            fontSize: '9px',
            colors: ['#fff'],
          },
        },
      });

      setError('');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while fetching data.');
    }
  };

  const transformData = (entries) => {
    const filteredEntries = entries.filter(entry => parseFloat(entry.Pqty) > 0);
  
    const productNames = Array.from(new Set(filteredEntries.flatMap(entry => entry.Product)));
    const categories = Array.from(new Set(filteredEntries.map(entry => entry.batchNo)));
  
    const seriesData = productNames.map(product => ({
      name: product,
      data: categories.map(batchNo => {
        const entry = filteredEntries.find(e => e.batchNo === batchNo && e.Product === product);
        return entry ? parseFloat(entry.Pqty) : 0;
      }),
    }));
  
    const tooltipData = filteredEntries.reduce((acc, entry) => {
      if (!acc[entry.batchNo]) acc[entry.batchNo] = {};
      acc[entry.batchNo][entry.Product] = parseFloat(entry.Pqty).toFixed(2); // Ensure formatting for tooltip
      return acc;
    }, {});
  
    return { series: seriesData, categories, tooltipData };
  };
  

  const generateDynamicColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const hue = (i * 137.508) % 360;
      colors.push(`hsl(${hue}, 70%, 55%)`);
    }
    return colors;
  };

  const handleFetch = () => {
    if (from && to) {
      const timezone = 'Asia/Kolkata';
      const localFrom = toZonedTime(from, timezone);
      const localTo = toZonedTime(to, timezone);
  
      const customFrom = format(startOfDay(localFrom), 'yyyy-MM-dd');
      const customTo = format(endOfDay(localTo), 'yyyy-MM-dd');
  
      fetchBatchEntries(customFrom, customTo);
      setError('');
    } else {
      setError('Please select both From and To dates.');
    }
  };

  useEffect(() => {
    fetchBatchEntries(); // Fetch default data on initial mount
  }, []);

  return (
    <div className="p-2 rounded-lg w-full">
      <h2 className="text-gray-600 text-lg font-bold text-center mt-2">Products (Batch wise)</h2>
      <div className="flex items-center justify-between mt-2 space-x-2">
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-1">
            <label htmlFor="from-date" className="text-xs">From:</label>
            <CustomDatePicker selectedDate={from} onDateChange={date => setFrom(date)} onClear={handleClearFrom} />
          </div>
          <div className="flex items-center space-x-1">
            <label htmlFor="to-date" className="text-xs">To:</label>
            <CustomDatePicker selectedDate={to} onDateChange={date => setTo(date)} onClear={handleClearTo} />
          </div>
          <button
            onClick={handleFetch}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-2 py-1 rounded-lg shadow-md text-xs"
          >
            Submit
          </button>
        </div>
      </div>
      {error && (
        <div className="mt-2 p-2 bg-red-100 text-red-700 rounded-md text-xs">
          {error}
        </div>
      )}
      <div className="mt-4">
        <Chart options={chartOptions} series={chartSeries} type="bar" height={300} className="w-full" />
      </div>
    </div>
  );
};

export default Prouductionsummary;
