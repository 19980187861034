import React from 'react';
import ApexCharts from 'react-apexcharts';

const StackedColumnChart = ({ data }) => {
  console.log('StackedColumnChart', data);
  
  const parseQuantity = (quantity) => {
    const parsed = parseFloat(quantity);
    return Number.isFinite(parsed) ? parsed : 0;
  };
  

  const aggregatedData = data.reduce((acc, item) => {
    if (!acc[item.batchNo]) {
      acc[item.batchNo] = {
        batchNo: item.batchNo,
        materialQty: 0,
        productQty: 0,
        materials: [],
        products: [],
      };
    }
  
    if (item.Qty && item.Material && item.Material !== 'N/A') {
      const materialQty = parseQuantity(item.Qty);
      acc[item.batchNo].materialQty += materialQty;
      acc[item.batchNo].materials.push(`${item.Material}: ${materialQty} Lts`);
    }
  
    if (item.Pqty && item.Product && item.Product !== 'N/A') {
      const productQty = parseQuantity(item.Pqty);
      acc[item.batchNo].productQty += productQty;
      acc[item.batchNo].products.push(`${item.Product}: ${productQty} Lts`);
    }
  
    return acc;
  }, {});
  
  

  const categories = Object.keys(aggregatedData);
  const rawMaterialsData = categories.map(batchNo => aggregatedData[batchNo].materialQty);
  const productsData = categories.map(batchNo => aggregatedData[batchNo].productQty);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: 'normal',
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: '#000',
          fontSize: '9px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Quantity (Lts)',
      },
      labels: {
        formatter: (value) => `${value.toFixed(2)}`,
        style: {
          colors: '#000',
          fontSize: '12px',
        },
      },
    },
    title: {
      text: 'Production (Batch wise)',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex }) => {
        const batch = aggregatedData[categories[dataPointIndex]];
        const seriesData = [rawMaterialsData, productsData][seriesIndex];
        const seriesTotal = seriesData[dataPointIndex];
    
        const formattedSeriesTotal = Number.isFinite(seriesTotal) ? seriesTotal.toFixed(2) : '0.00';
    
        let details = '';
        if (seriesIndex === 0) {
          details = batch.materials.length ? batch.materials.map(item => {
            const [material, qty] = item.split(': ');
            return `${material}: ${parseFloat(qty).toFixed(2)} Lts`;
          }).join('<br>') : 'No data available';
        } else if (seriesIndex === 1) {
          details = batch.products.length ? batch.products.map(item => {
            const [product, qty] = item.split(': ');
            return `${product}: ${parseFloat(qty).toFixed(2)} Lts`;
          }).join('<br>') : 'No data available';
        }
    
        return `
          <div style="
            max-height: 150px;
            overflow-y: auto;
            padding: 6px;
            background: #fff;
            border: 1px solid #d1d5db;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            font-size: 11px;
            color: #333;
            box-sizing: border-box;
          ">
            <strong class="block mb-1">Batch Number: ${batch.batchNo}</strong>
            <strong class="block mb-1">${seriesIndex === 0 ? 'Raw Materials' : 'Products'}:</strong>
            <div class="mb-2">${details}</div>
            <strong>Total ${seriesIndex === 0 ? 'Raw Material' : 'Product'}: ${formattedSeriesTotal} Lts</strong>
          </div>
        `;
      },
    },
    
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: -4,
      labels: {
        colors: '#000',
        fontSize: '8px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(2)}`,
      style: {
        colors: ['#fff'],
        fontSize: '10px',
        fontWeight: 'bold',
      },
    },
    colors: ['#FF4560', '#008FFB'],
  };

  const chartSeries = [
    {
      name: ' Raw Material',
      data: rawMaterialsData,
    },
    {
      name: 'Product',
      data: productsData,
    },
  ];

  return (
    <div className="w-full h-[300px]">
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height="100%"
      />
    </div>
  );
};

export default StackedColumnChart;
