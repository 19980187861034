import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from './ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StockEditModal = ({ formData, setFormData, setEditingStock, fetchStockData, stockData }) => {
  const [oilOptions, setOilOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [currentQuantity, setCurrentQuantity] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);


  const materialTypeOptions = [...new Set(stockData.map(stock => stock.materialtype))].filter(Boolean);
  useEffect(() => {
    const fetchOilNames = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/stock/getoilnames`);
        setOilOptions(response.data.map(item => item.oilname));
      } catch (error) {
        console.error('Failed to fetch oil names:', error);
      }
    };

    fetchOilNames();
  }, []);

  useEffect(() => {
    const existingTank = stockData.find(t => t.tankname === formData.tankname);
    if (existingTank) {
      setCurrentQuantity(existingTank.currentquantity);
    }
  }, [formData.tankname, stockData]);

  const validateForm = () => {
    const newErrors = {};
    const existingTank = stockData.find(t => t.tankname === formData.tankname);

    if (!existingTank) {
      newErrors.tankname = `No tank found with the name ${formData.tankname}.`;
    } else {
      if (!formData.oilname) {
        newErrors.oilname = 'Oil name is required.';
      } else if (formData.oilname !== existingTank.oilname && existingTank.currentquantity > 0) {
        newErrors.oilname = `Cannot change material the tank already has ${existingTank.currentquantity} KL of ${existingTank.oilname}.`;
      }

      const currentQuantity = parseFloat(formData.currentquantity);
      if (isNaN(currentQuantity) || currentQuantity < 0) {
        newErrors.currentquantity = 'Current quantity must be a non-negative number.';
      } else {
        const totalQuantity = currentQuantity; // Only consider the new quantity

        if (checkQuantityExceedsCapacity(existingTank, totalQuantity)) {
          newErrors.currentquantity = `Total quantity (${totalQuantity.toFixed(2)} KL) exceeds the tank capacity of ${existingTank.capacity} KL.`;
        }
      }
    }

    if (!formData.materialtype) {
      newErrors.materialtype = 'Material type is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkQuantityExceedsCapacity = (tank, quantity) => {
    return quantity > tank.capacity;
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleUpdateStock = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const { radius, capacity, ...dataToUpdate } = formData;
    const updatedFormData = {
      ...dataToUpdate,
      currentquantity: parseFloat(formData.currentquantity),
    };
      // Check if the quantity is being set to 0
      if (formData.currentquantity === 0) {
        setIsConfirmOpen(true);
        return;
      }

    try {
      const response = await axios.put(`${config.apiUrl}/stock/editstock`, updatedFormData);
      if (response.data.success) {
        toast.success('Stock updated successfully!');
        fetchStockData();
        setEditingStock(false);
      } else {
        toast.error('Failed to update stock. Please try again.');
      }
    } catch (error) {
      console.error('Error updating stock:', error.response || error);
      toast.error('An error occurred while updating stock. Please try again.');
    }
  };
  const handleConfirm = async () => {
    setIsConfirmOpen(false);

    const { radius, capacity, ...dataToUpdate } = formData;
    const updatedFormData = {
      ...dataToUpdate,
      currentquantity: parseFloat(formData.currentquantity),
    };

    try {
      const response = await axios.put(`${config.apiUrl}/stock/editstock`, updatedFormData);
      if (response.data.success) {
        toast.success('Stock updated successfully!');
        fetchStockData();
        setEditingStock(false);
      } else {
        toast.error('Failed to update stock. Please try again.');
      }
    } catch (error) {
      console.error('Error updating stock:', error.response || error);
      toast.error('An error occurred while updating stock. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsConfirmOpen(false);
    setFormData(prevData => ({
      ...prevData,
      currentquantity: 0,
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'currentquantity' && value === '0') {
      setIsConfirmOpen(true);
    } else {
      validateForm();
    }
  };

  const handleOilSelect = (event) => {
    setFormData(prevData => ({
      ...prevData,
      oilname: event.target.value,
    }));
    validateForm();
  };

  const handleMaterialTypeSelect = (event) => {
    setFormData(prevData => ({
      ...prevData,
      materialtype: event.target.value,
    }));
    validateForm();
  };

  return (
    <div>
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="p-6 rounded-md w-full max-w-lg shadow-lg bg-white relative">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Update Stock</h2>
        <button  onClick={() => setEditingStock(false)} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleUpdateStock}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Tank Name
              </label>
              <input
                type="text"
                name="tankname"
                value={formData.tankname}
                onChange={handleFormChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-200 text-gray-800"
                disabled
              />
              {errors.tankname && <p className="text-red-500 text-sm mt-1">{errors.tankname}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Oil Name
              </label>
              <select
                name="oilname"
                value={formData.oilname}
                onChange={handleOilSelect}
                className={`w-full  text-sm px-3 py-2 border ${errors.oilname ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
                required
              >
                <option value="">Select Oil</option>
                {oilOptions.length === 0 ? (
                  <option value="" disabled>No oils available</option>
                ) : (
                  oilOptions.map((oil, index) => (
                    <option key={index} value={oil}>
                      {oil}
                    </option>
                  ))
                )}
              </select>
              {errors.oilname && <p className="text-red-500 text-sm mt-1">{errors.oilname}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Current Quantity (KL)
              </label>
              <div className="relative">
                <input
                  type="number"
                  name="currentquantity"
                  value={formData.currentquantity }
                  onChange={handleFormChange}
                  className={`w-full px-3 py-2 border ${errors.currentquantity ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
                  step="0.01"
                  required
                />
                <span className="absolute inset-y-0 right-0 flex items-center px-1 text-sm mr-8 text-gray-500">
                  {currentQuantity} KL
                </span>
              </div>
              {errors.currentquantity && <p className="text-red-500 text-sm mt-1">{errors.currentquantity}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Material Type
              </label>
              <select
                name="materialtype"
                value={formData.materialtype}
                onChange={handleMaterialTypeSelect}
                className={`w-full px-3 py-2 border ${errors.materialtype ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
                required
              >
                <option value="">Select Material Type</option>
                {materialTypeOptions.length === 0 ? (
                  <option value="" disabled>No material types available</option>
                ) : (
                  materialTypeOptions.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))
                )}
              </select>
              {errors.materialtype && <p className="text-red-500 text-sm mt-1">{errors.materialtype}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">Radius</label>
              <input
                type="text"
                name="radius"
                value={formData.radius}
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-200 text-gray-800"
                disabled
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-1">Capacity</label>
              <input
                type="text"
                name="capacity"
                value={formData.capacity}
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-200 text-gray-800"
                disabled
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
          
            <button
              type="submit"
              className="text-sm px-3 py-1.5 bg-rose-500 hover:bg-rose-600 text-white rounded-md  transition"
            >
              Update Stock
            </button>
            
          </div>
        </form>
      </div>
    </div>
    <ConfirmationModal
        isOpen={isConfirmOpen}
        message="Are you sure you want to set the quantity to 0?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default StockEditModal;
