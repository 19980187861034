import React, { useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const PUBLIC_VAPID_KEY = 'BBivXBmnc0iP7yF6RWVHvucLBUxUSVRqYDY998IJEYX7vYjRwKW1zTPNuaRZLiPoIMEtjZp9mYwO3OgZdPPD7SM'; // Use your public key here

const PushNotification = () => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
          sendSubscription();
        } else {
          console.error('Service workers are not supported in this browser.');
        }
      }, []);
      

      const sendSubscription = async () => {
        try {
            // Request notification permission
            const permission = await Notification.requestPermission();
    
            if (permission !== 'granted') {
                console.error('Notification permission not granted');
                return;
            }
    
            // Register the service worker
            const register = await navigator.serviceWorker.register('/sw.js', {
                scope: '/'
            });
    
            // Wait until the service worker is ready
            await navigator.serviceWorker.ready;
    
            console.log('Service Worker is active and ready.');
    
            // Now subscribe to push notifications
            const subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
            });
    
            // Send the subscription object to the backend
            await axios.post(`${config.apiUrl}/push/subscribe`, subscription);
            console.log('Subscription successful:', subscription);
        } catch (error) {
            console.error('Error during service worker registration or push subscription:', error);
        }
    };
    
    



  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  };

 return null;
};

export default PushNotification;
