import React, { useState, useRef } from 'react';
import axios from 'axios';
import config from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PurchaseTemplate from './PurchaseTemplate';
import { FaTimes } from 'react-icons/fa';

const PurchaseOrderForm = ({PiNo,showPoForm,setShowPoForm,resetSelectedQuotations,fetchPDQuotations}) => {
  const [formData, setFormData] = useState({
    billTo: { name: '', address: '', gst: '', email: '' },
    shipTo: { name: '', address: '', gst: '', email: '' },
    orderItems: [{ description: '', hsnCode: '', qty: '', uom: '', itemRate: '', taxPercent: '' }]
  });

  const [submittedData, setSubmittedData] = useState(null); 
  const [showTemplate, setShowTemplate] = useState(false);
  const templateRef = useRef(null);
  const companies = [
    { name: "KSR TECHNO AND CO", address: "123 KSR Street, City, State, 123456", gst: "GST123456", email: "contact@ksrtechno.com" },
    { name: "Avatar Petrochemicals Private Ltd", address: "456 Avatar Road, City, State, 654321", gst: "GST654321", email: "info@avatarpetrochemicals.com" }
  ];

  const handleCompanyChange = (e) => {
    const selectedCompany = companies.find(company => company.name === e.target.value);
    if (selectedCompany) {
      setFormData(prevState => ({
        ...prevState,
        shipTo: { ...selectedCompany }
      }));
    }
  };

  const handleBillToChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      billTo: { ...prevState.billTo, [name]: value }
    }));
  };

  const handleOrderItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedOrderItems = [...formData.orderItems];
    updatedOrderItems[index][name] = value;
    setFormData(prevState => ({ ...prevState, orderItems: updatedOrderItems }));
  };

  const addOrderItemRow = () => {
    setFormData(prevState => ({
      ...prevState,
      orderItems: [...prevState.orderItems, { description: '', hsnCode: '', qty: '', uom: '', itemRate: '', taxPercent: '' }]
    }));
  };

  const removeOrderItemRow = (index) => {
    const updatedOrderItems = [...formData.orderItems];
    updatedOrderItems.splice(index, 1);
    setFormData(prevState => ({ ...prevState, orderItems: updatedOrderItems }));
  };

  const resetForm = () => {
    setFormData({
      billTo: { name: '', address: '', gst: '', email: '' },
      shipTo: { name: '', address: '', gst: '', email: '' },
      orderItems: [{ description: '', hsnCode: '', qty: '', uom: '', itemRate: '', taxPercent: '' }]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { billTo, shipTo, orderItems } = formData;

    // Validate Bill To and Ship To fields
    if (!billTo.name || !billTo.address || !billTo.gst || !billTo.email) {
      toast.error("Please fill all Bill To fields");
      return;
    }

    if (!shipTo.name || !shipTo.address || !shipTo.gst || !shipTo.email) {
      toast.error("Please fill all Ship To fields");
      return;
    }

    const hasEmptyFields = orderItems.some(item => !item.description || !item.hsnCode || !item.qty || !item.uom || !item.itemRate || !item.taxPercent);
    if (hasEmptyFields) {
      toast.error("Please fill all fields in order items");
      return;
    }

    try {
      const payload = {
        ...formData,
        PiNo:PiNo,
        totalQty: totalQty,
        totalAmount: totalAmount,
        totalTax: totalTax,
        orderTotal: orderTotal
      };
      
      const response = await axios.post(`${config.apiUrl}/po/purchase-orders`, payload);
      toast.success('Purchase Order submitted successfully');
    // Simulate form submission
    setSubmittedData(payload); // Set the form data for the template
    setShowTemplate(true); // Show the template after submission
    fetchPDQuotations();
      resetForm();
      console.log('Purchase Order response:', response.data);
    } catch (error) {
      toast.error('Error submitting purchase order. Please try again.');
      console.error('Error:', error);
    }
  };

  // Calculations for totals
  const totalQty = formData.orderItems.reduce((acc, item) => acc + parseFloat(item.qty || 0), 0);
  const totalAmount = formData.orderItems.reduce((acc, item) => acc + (parseFloat(item.qty || 0) * parseFloat(item.itemRate || 0)), 0);
  const totalTax = formData.orderItems.reduce((acc, item) => acc + ((parseFloat(item.qty || 0) * parseFloat(item.itemRate || 0)) * (parseFloat(item.taxPercent || 0) / 100)), 0);
  const orderTotal = totalAmount + totalTax;

  const handleDownload = async () => {
    const element = templateRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('purchase-order.pdf');
  };

  return (
    <>
    {showPoForm? 
      <>
       <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex justify-center items-center">
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-4 mt-6 rounded-md bg-[#DDCFD1]">
      <div className="flex justify-between items-center mb-4 relative">
      <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
              onClick={() => {
                resetSelectedQuotations(); 
            }}
            >
              <FaTimes className="h-5 w-5 text-red-500" />
            </button>
    {/* <h2 className="text-xl font-semibold text-[#438FD1]">Purchase Order</h2> */}
    <div className="flex items-center">
        <span className="text-lg font-semibold text-[#438FD1] mr-2">PI.No:</span>
        <h3 className="text-lg font-semibold ">{PiNo}</h3>
    </div>
</div>

        {/* Bill To Section */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">Bill To</h3>
            <input
              type="text"
              name="name"
              value={formData.billTo.name}
              onChange={handleBillToChange}
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="Company Name"
            />
            <input
              type="text"
              name="address"
              value={formData.billTo.address}
              onChange={handleBillToChange}
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="Company Address"
            />
            <input
              type="text"
              name="gst"
              value={formData.billTo.gst}
              onChange={handleBillToChange}
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="GST"
            />
            <input
              type="email"
              name="email"
              value={formData.billTo.email}
              onChange={handleBillToChange}
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="Email"
            />
          </div>
          
          {/* Ship To Section */}
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">Ship To</h3>
            <select
              className="border border-gray-300 text-sm rounded-md p-1 w-full mb-1"
              onChange={handleCompanyChange}
              defaultValue=""
            >
              <option value="" disabled>Select Company</option>
              {companies.map((company, index) => (
                <option key={index} value={company.name}>
                  {company.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="address"
              value={formData.shipTo.address}
              readOnly
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="Company Address"
            />
            <input
              type="text"
              name="gst"
              value={formData.shipTo.gst}
              readOnly
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="GST"
            />
            <input
              type="email"
              name="email"
              value={formData.shipTo.email}
              readOnly
              className="border border-gray-300 rounded-md text-sm p-1 w-full mb-1"
              placeholder="Email"
            />
          </div>
        </div>

        {/* Order Items Section */}
        <table className="w-full text-sm mb-4 border-collapse">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="border p-1">Description</th>
              <th className="border p-1">HSN Code</th>
              <th className="border p-1">Qty</th>
              <th className="border p-1">UOM</th>
              <th className="border p-1">Item Rate</th>
              <th className="border p-1">Tax %</th>
              <th className="border p-1">Actions</th>
            </tr>
          </thead>
          <tbody>
            {formData.orderItems.map((item, index) => (
              <tr key={index}>
                <td className="border p-1">
                  <input
                    type="text"
                    name="description"
                    value={item.description}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1">
                  <input
                    type="text"
                    name="hsnCode"
                    value={item.hsnCode}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="qty"
                    value={item.qty}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1">
                  <input
                    type="text"
                    name="uom"
                    value={item.uom}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="itemRate"
                    value={item.itemRate}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="taxPercent"
                    value={item.taxPercent}
                    onChange={(e) => handleOrderItemChange(index, e)}
                    className="w-full p-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="border p-1 text-center">
                  {formData.orderItems.length > 1 && (
                    <button
                      type="button"
                      className="bg-red-500 text-white px-2 py-1 rounded-md"
                      onClick={() => removeOrderItemRow(index)}
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between mb-4">
          <button
            type="button"
            className="bg-blue-500 text-white px-2 py-1 rounded-md"
            onClick={addOrderItemRow}
          >
            Add Item
          </button>
        </div>

        {/* Summary Section */}
        <div className="flex justify-between items-center mb-4">
          <div>Total Quantity: {totalQty}</div>
          <div>Total Amount: ₹{totalAmount.toFixed(2)}</div>
          <div>Total Tax: ₹{totalTax.toFixed(2)}</div>
          <div>Order Total: ₹{orderTotal.toFixed(2)}</div>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={resetForm}
            className="bg-yellow-500 text-white px-3 py-2 rounded-md"
          >
            Reset
          </button>
          <button
            type="submit"
            className="bg-green-500 text-white px-3 py-2 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
      </div>
      </> : 
      <div>No selected checkbox</div>
          }
      <PurchaseTemplate
        showTemplate={showTemplate}
        setShowTemplate={setShowTemplate}
        setShowPoForm={setShowPoForm}
        Data={submittedData}
      />
    </>
  );
};

export default PurchaseOrderForm;
