import React from 'react';
import { Circles } from 'react-loader-spinner';

const LoadingSpinner = () => (
  <>
  <style>
    {`
    .spinner-container {
  position: relative;
  /* Set specific width and height as needed */
  width: 100%; /* Adjust as necessary */
  height: 100%; /* Adjust as necessary */
}

.spinner-container .absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}`}
  </style>
  <div className="spinner-container relative flex justify-center items-center">
    <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
      <Circles color="#00BFFF" height={50} width={50} />
    </div>
  </div>
  </>
);

export default LoadingSpinner;
