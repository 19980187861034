import React from 'react';
import Select from 'react-select';

const ExpenseForm = ({ formData, handleInputChange, handleInvoiceSelect, invoiceOptions }) => {
    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <Select
                    name="invoiceNo"
                    value={invoiceOptions.find(option => option.value === formData.invoiceNo) || null}
                    onChange={handleInvoiceSelect}
                    options={invoiceOptions}
                    className="text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                    type="text"
                    name="purchaseOrderNo"
                    value={formData.purchaseOrderNo}
                    onChange={handleInputChange}
                    placeholder="Purchase Order No"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="ewayBillNo"
                    value={formData.ewayBillNo}
                    onChange={handleInputChange}
                    placeholder="Eway Bill No"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="text"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    placeholder="Date"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="vehicleNo"
                    value={formData.vehicleNo}
                    onChange={handleInputChange}
                    placeholder="Vehicle No"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="driverName"
                    value={formData.driverName}
                    onChange={handleInputChange}
                    placeholder="Driver Name"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="text"
                    name="inandout"
                    value={formData.inandout}
                    onChange={handleInputChange}
                    placeholder="In/Out"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    placeholder="Location"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="tankNo"
                    value={formData.tankNo}
                    onChange={handleInputChange}
                    placeholder="Tank No"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="text"
                    name="fuelName"
                    value={formData.fuelName}
                    onChange={handleInputChange}
                    placeholder="Fuel Name"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="number"
                    name="grossWeight"
                    value={formData.grossWeight || 0}
                    onChange={handleInputChange}
                    placeholder="Gross Weight"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="number"
                    name="tareWeight"
                    value={formData.tareWeight || 0}
                    onChange={handleInputChange}
                    placeholder="Tare Weight"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="number"
                    name="netWeight"
                    value={formData.netWeight || 0}
                    onChange={handleInputChange}
                    placeholder="Net Weight"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="uom"
                    value={formData.uom}
                    onChange={handleInputChange}
                    placeholder="UOM"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="color"
                    value={formData.color}
                    onChange={handleInputChange}
                    placeholder="Color"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="text"
                    name="density"
                    value={formData.density}
                    onChange={handleInputChange}
                    placeholder="Density"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="text"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleInputChange}
                    placeholder="Remarks"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    readOnly
                />
                <input
                    type="number"
                    name="barrelCount"
                    value={formData.barrelCount || 0}
                    onChange={handleInputChange}
                    placeholder="Barrel Count"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <input
                    type="number"
                    name="litresPerBarrel"
                    value={formData.litresPerBarrel || 0}
                    onChange={handleInputChange}
                    placeholder="Litres per Barrel"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                    type="text"
                    name="gstNo"
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    placeholder="GST No"
                    className="text-sm p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
        </div>
    );
};

export default ExpenseForm;
