import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import TankChart from './stockBar';
import TankTable from './Tanktable';
// import PieChartComponent from './EbReadingChart';
import StackedColumnChart from './StackedColumnChart';
import Prouductionsummary from './Prouductionsummary.js';
import MonthlyProductionChart from './MonthlyProductionPieChart.js';
// import ProductPieChart from './ProductPieChart.js';
// import MaterialPieChart from './MaterialPieChart.js';
import {PropagateLoader} from 'react-spinners';
import StockSummaryDonut from './StockSummaryDonut.js';
import StockTable from './StockTable.js';

const Dashboard = () => {
  const [stockData, setStockData] = useState([]);
  const [stockSumData, setStockSumData] = useState([]);
  const [ebData, setEbData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [revenue, setRevenue] = useState('08')
  const[expenses,setExpenses]=useState('08')

  const fetchStockData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/getstockData`);
      if (response.data && Array.isArray(response.data.data)) {
        setStockData(response.data.data);

        setLastUpdatedDate(response.data.data[0]?.lastupdateddate || '');
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

    const fetchStockSummary = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/stock/summary`);
            setStockSumData(response.data);
        } catch (error) {
            console.error('Error fetching stock summary:', error);
        }
    };




  const fetchEbData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/ebreading/getEbData`);
      if (response.data && Array.isArray(response.data.data)) {
        setEbData(response.data.data);

      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching EB data:', error);
    }
  };

  const fetchBatchData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/logsheet/getbatchentries`);
      if (response.data && Array.isArray(response.data)) {
        setBatchData(response.data);

      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching batch data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchStockData(),
        fetchEbData(),
        fetchBatchData(),
        fetchStockSummary()
      ]);
      setLoading(false); // Set loading to false after data is fetched
    };
    
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <PropagateLoader color="#50cfc0"  size={15}/>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-[72vh] flex flex-col justify-center items-center">
      <div className="container mx-auto p-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-80 flex justify-center items-center">
            <TankChart stockData={stockData} lastUpdatedDate={lastUpdatedDate} />
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-80 flex justify-center items-center">
            <TankTable stockData={stockData} />
          </div>
          {/* <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-80 flex justify-center items-center">
            <ProductPieChart data={stockData} />
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-80 flex justify-center items-center">
            <MaterialPieChart data={stockData} />
          </div> */}
         
        
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
            <Prouductionsummary />
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
            <MonthlyProductionChart />
          </div>
          <div className="bg-white shadow-md rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
          <StockSummaryDonut stockSumData={stockSumData} />
          </div>
          {/* <div className="bg-white shadow-md rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
            <div className="w-full h-full">
              <PieChartComponent data={ebData} />
            </div>
          </div> */}
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
          <StockTable stockSumData={stockSumData}/>
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
          <StackedColumnChart data={batchData} />
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg h-64 md:h-72 lg:h-96 flex justify-center items-center">
      
          </div>
          <div className="bg-white shadow-md p-2 rounded-lg flex justify-center items-center" style={{ height: '400px' }}>
  <iframe
    src="https://ksrinsights.fiveytech.com/d-solo/ddxr49501hn28d/ksr-inward?orgId=1&refresh=10s&panelId=1"
    width="550"
    height="100%"
    frameborder="0"
  ></iframe>
</div>

<div className="bg-white shadow-md p-2 rounded-lg flex justify-center items-center" style={{ height: '400px' }}>
<iframe src="https://ksrinsights.fiveytech.com/d-solo/ddxr49501hn28d/ksr-inward?orgId=1&refresh=10s&panelId=2" 
 width="550"
 height="100%"
 frameborder="0"
  ></iframe>
</div>
 
<div className="bg-gradient-to-r from-blue-200 to-indigo-200 shadow-lg p-6 rounded-xl flex flex-col justify-start items-center" style={{ height: '350px' }}>
  {/* Heading */}
  <h2 className="text-xl font-semibold text-gray-800 mb-4">Monthly Revenue</h2>
  
  {/* Dropdown select */}
  <select
    className="mb-6 p-2 text-sm text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200 ease-in-out"
    onChange={(e) => setRevenue(e.target.value)}
    value={revenue}
  >
    <option value="01">January</option>
    <option value="02">February</option>
    <option value="03">March</option>
    <option value="04">April</option>
    <option value="05">May</option>
    <option value="06">June</option>
    <option value="07">July</option>
    <option value="08">August</option>
    <option value="09">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
  </select>

  {/* Chart iframe */}
  <iframe
    src={`https://ksrinsights.fiveytech.com/d-solo/ddxr49501hn28d/ksr-inward?orgId=1&refresh=10s&var-revenue=${revenue}&panelId=5`}
    width="450"
    height="200"
    frameBorder="0"
    className="rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105"
  ></iframe>
</div>

<div className="bg-gradient-to-r from-green-100 to-teal-200 shadow-lg p-6 rounded-xl flex flex-col justify-start items-center" style={{ height: '350px' }}>
  {/* Heading */}
  <h2 className="text-xl font-semibold text-gray-800 mb-4">Monthly Expenses</h2>
  
  {/* Dropdown select */}
  <select
    className="mb-6 p-2 text-sm text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition-all duration-200 ease-in-out"
    onChange={(e) => setExpenses(e.target.value)}
    value={expenses}
  >
    <option value="01">January</option>
    <option value="02">February</option>
    <option value="03">March</option>
    <option value="04">April</option>
    <option value="05">May</option>
    <option value="06">June</option>
    <option value="07">July</option>
    <option value="08">August</option>
    <option value="09">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
  </select>

  {/* Chart iframe */}
  <iframe
    src={`https://ksrinsights.fiveytech.com/d-solo/ddxr49501hn28d/ksr-inward?orgId=1&refresh=10s&var-expenses=${expenses}&panelId=4`}
    width="450"
    height="100%"
    frameBorder="0"
    className="rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105"
  ></iframe>
</div>
<div className="bg-white shadow-md p-2 rounded-lg flex justify-center items-center" style={{ height: '500px' }}>
<iframe src="https://ksrinsights.fiveytech.com/d-solo/ddxr49501hn28d/ksr-inward?orgId=1&refresh=10s&panelId=3"
  width="450" height="100%" frameborder="0"></iframe>
  </div>
  





        </div>
      </div>
    </div>
  );
};

export default Dashboard;
