import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const InoutHistory = ({ closeTable }) => {
  const [inwardData, setInwardData] = useState([]);
  const [outwardData, setOutwardData] = useState([]);
  const [showInward, setShowInward] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (showInward) {
      fetchInwardData(currentPage, itemsPerPage);
    } else {
      fetchOutwardData(currentPage, itemsPerPage);
    }
  }, [currentPage, showInward]);

  const fetchInwardData = async (page, limit) => {
    try {
      setLoading(true);
      const offset = (page - 1) * limit;
      const response = await axios.get(`${config.apiUrl}/in_out/inwarddetails`, {
        params: { limit, offset },
      });
      setInwardData(response.data);
    } catch (error) {
      setError('Failed to fetch inward data');
    } finally {
      setLoading(false);
    }
  };

  const fetchOutwardData = async (page, limit) => {
    try {
      setLoading(true);
      const offset = (page - 1) * limit;
      const response = await axios.get(`${config.apiUrl}/in_out/outwarddetails`, {
        params: { limit, offset },
      });
      setOutwardData(response.data);
    } catch (error) {
      setError('Failed to fetch outward data');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  const filteredData = (data) => {
    if (!searchTerm) return data;
    return data.filter(item => 
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const displayedData = showInward ? filteredData(inwardData) : filteredData(outwardData);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg max-w-full w-full h-full flex flex-col relative">
        <button onClick={closeTable} className="absolute top-4 right-4 text-red-500">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-semibold mb-2 text-blue-600 border-b-2 border-blue-200 pb-2">History</h2>

        <div className="flex items-center justify-between mb-2">
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={showInward}
                onChange={() => setShowInward(!showInward)}
                className="form-checkbox"
              />
              <span className="ml-2">Show Inward</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={!showInward}
                onChange={() => setShowInward(!showInward)}
                className="form-checkbox"
              />
              <span className="ml-2">Show Outward</span>
            </label>
          </div>
          <div className="flex items-center bg-gray-100 rounded-lg overflow-hidden shadow-md">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border-none bg-transparent p-3 w-64 text-gray-700 focus:outline-none"
            />
            <button
              onClick={handleSearch}
              className="bg-blue-600 text-white px-4 py-3 rounded-r-lg hover:bg-blue-700 transition-colors duration-300 focus:outline-none"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            {searchTerm && (
              <button
                onClick={handleClearSearch}
                className="ml-2 bg-red-500 text-white px-3 py-1.5 rounded-lg hover:bg-red-600 transition-colors duration-300 focus:outline-none"
              >
                Clear
              </button>
            )}
          </div>
        </div>

        <div className="flex-1">
          {loading ? (
            <div className="text-center text-gray-500">Loading...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : displayedData.length > 0 ? (
            <div className="max-h-[calc(100vh-250px)] overflow-y-auto">
              <table className="table-auto w-full bg-white border-collapse">
                <thead className="bg-blue-900 text-white sticky top-0 text-xs text-center">
                  <tr>
                    <th className="px-1 py-0.5 border">Invoice No</th>
                    <th className="px-1 py-0.5 border">Purchase Order No</th>
                    <th className="px-1 py-0.5 border">Eway Bill No</th>
                    <th className="px-1 py-0.5 border">Date</th>
                    <th className="px-1 py-0.5 border">Vehicle No</th>
                    <th className="px-1 py-0.5 border">Driver Name</th>
                    <th className="px-1 py-0.5 border">{showInward ? 'Inward From & Details' : 'Outward to & Details'}</th>
                    <th className="px-1 py-0.5 border">Location</th>
                    <th className="px-1 py-0.5 border">Tank No</th>
                    <th className="px-1 py-0.5 border">Fuel Name</th>
                    <th className="px-1 py-0.5 border">Gross Weight</th>
                    <th className="px-1 py-0.5 border">Tare Weight</th>
                    <th className="px-1 py-0.5 border">Net Weight</th>
                    <th className="px-1 py-0.5 border">UOM</th>
                    <th className="px-1 py-0.5 border">Color</th>
                    <th className="px-1 py-0.5 border">Density</th>
                    <th className="px-1 py-0.5 border">Remarks</th>
                    <th className="px-1 py-0.5 border">Barrel Count</th>
                    <th className="px-1 py-0.5 border">Litres Per Barrel</th>
                    <th className="px-1 py-0.5 border">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData.map((item, index) => (
                    <tr key={index} className="text-xs text-center">
                      <td className="px-1 py-0.5 border">{item.invoiceNo}</td>
                      <td className="px-1 py-0.5 border">{item.purchaseOrderNo}</td>
                      <td className="px-1 py-0.5 border">{item.ewayBillNo}</td>
                      <td className="px-1 py-0.5 border">{new Date(item.date).toLocaleDateString()}</td>
                      <td className="px-1 py-0.5 border">{item.vehicle_no}</td>
                      <td className="px-1 py-0.5 border">{item.driver_name}</td>
                      <td className="px-1 py-0.5 border">{showInward ? item.inward_from_and_details : item.outward_to_and_details}</td>
                      <td className="px-1 py-0.5 border">{item.location}</td>
                      <td className="px-1 py-0.5 border">{item.tank_no}</td>
                      <td className="px-1 py-0.5 border">{item.fuel_name}</td>
                      <td className="px-1 py-0.5 border">{item.gross_weight.toFixed(2)}</td>
                      <td className="px-1 py-0.5 border">{item.tare_weight.toFixed(2)}</td>
                      <td className="px-1 py-0.5 border">{item.net_weight.toFixed(2)}</td>
                      <td className="px-1 py-0.5 border">{item.uom}</td>
                      <td className="px-1 py-0.5 border">{item.color}</td>
                      <td className="px-1 py-0.5 border">{item.density.toFixed(2)}</td>
                      <td className="px-1 py-0.5 border">{item.remarks}</td>
                      <td className="px-1 py-0.5 border">{item.barrelCount}</td>
                      <td className="px-1 py-0.5 border">{item.litresPerBarrel.toFixed(2)}</td>
                      <td className="px-1 py-0.5 border">{new Date(item.created_at).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
             
            </div>
          ) : (
            <div className="flex items-center justify-center min-h-[calc(100vh-250px)]">
            <div className="text-center text-gray-500">No data available</div>
          </div>
          
          )}
        </div>
        <div className="flex justify-between mt-2">
                <button
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-blue-600 text-white rounded disabled:bg-gray-400"
                >
                  Previous
                </button>
                <span className="text-sm">{`Page ${currentPage}`}</span>
                <button
                  onClick={() => handlePageChange(1)}
                  className="px-4 py-2 bg-blue-600 text-white rounded disabled:bg-gray-400"
                  disabled={displayedData.length < itemsPerPage}
                >
                  Next
                </button>
              </div>
      </div>
    </div>
  );
};

export default InoutHistory;
