import PurchaseIntent from "./Purchase/PI/PIrequest";
import StockTable from "./Stock/CurrentStock";
import AdminApprovalTable from "./Admin/admin";
import StockEntry from './Production/StockEntry'
import Dashboard from "./Dashboard/Main";
import EbForm from "./Dashboard/EbForm"
import Prouductionsummary from "./Dashboard/Prouductionsummary.js.js";
import BatchObservation from "./Production/Batchobservation";
import Logsheet from "./Logsheet/Logsheet.js";
import ObservationPage from "./Observation/Observation.js";
import Form from "./KSPCB/Form.js";
import InWardForm from "./I&O/In&out.js";
import FinanceForm from "./Expenses/expenses.js";
import QuotationSubmission from "./Purchase/Qut/QuotationSubmission.js";
import AdminQuotationReview from "./Purchase/AD/AdminReview.js";
import QuotationsTable from "./Purchase/PD/PoRise.js";
import PurchaseOrderDeatils from "./Purchase/PD/Podetails.js"



const routes = [
  { path: 'stock', component: StockTable },
  { path: 'pi', component: PurchaseIntent },
  {path:'batch_process', component:StockEntry},
  {path:'admin_approval', component:AdminApprovalTable},
  {path:'dashboard',component:Dashboard},
  {path:'eb_reading', component:EbForm},
  {path:'Prod_summary',component:Prouductionsummary},
  {path:'batch_observation',component:BatchObservation},
  {path:'logsheet',component:Logsheet},
  {path:'observation',component:ObservationPage},
  {path:'kspcb',component:Form},
  {path:'in_out',component:InWardForm},
  {path:'expenses',component:FinanceForm},
  {path:'quotation',component:QuotationSubmission},
  {path:'adminreview',component:AdminQuotationReview},
  {path:'po_form', component:QuotationsTable},
  {path:'po_details', component:PurchaseOrderDeatils}
  
];

export default routes;
