import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar/SideBar';
import routes from './RouteConfig';
import Login from './Login/login';
import ToastHandler from '../src/Toast/ToastHandler';
import ProtectedRoute from './Login/PrivateRoute';
import PushNotification from './PushNotification/PushNotification';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem('authentication') === 'true'
  );

  // Function to handle successful login
  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem('authentication', 'true');
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("User stored in localStorage:", user);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("user");
    sessionStorage.removeItem("authentication");
  };
  
 
  return (
    <Router>
      <ToastHandler />
      <PushNotification /> 
      <div className="h-screen">
      <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? <Navigate to="/sidebar/dashboard" /> : <Login onLogin={handleLogin} />
            }
          />
          <Route
            path="/sidebar/*"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Sidebar onLogout={handleLogout} />
              </ProtectedRoute>
            }
          >
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
