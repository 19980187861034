// ToastHandler.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const ToastHandler = () => {
  const location = useLocation();

  useEffect(() => {
    toast.dismiss(); // Dismiss all active toasts
  }, [location]);

  return null; // This component does not render anything
};

export default ToastHandler;
