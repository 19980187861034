import React, { useState} from 'react';
import axios from 'axios';
import config from '../config';

const EbReadingForm = () => {
  const [date, setDate] = useState('');
  const [dayStartReading, setDayStartReading] = useState('');
  const [dayEndReading, setDayEndReading] = useState('');
  const [error, setError] = useState('');
 

 

  // Calculate total reading based on start and end readings
  const calculateTotalReading = () => {
    const start = parseFloat(dayStartReading);
    const end = parseFloat(dayEndReading);
    return !isNaN(start) && !isNaN(end) ? (end - start).toFixed(2) : '0.00';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const totalReading = calculateTotalReading();
      const response = await axios.post(`${config.apiUrl}/ebreading/addEbReading`, {
        date,
        dayStartReading,
        dayEndReading,
        totalReading, // Send the calculated total reading to the backend
      });
      if (response.data.success) {
      
        setDate('');
        setDayStartReading('');
        setDayEndReading('');
      } else {
        setError('Error adding EB reading. Please try again.');
      }
    } catch (error) {
      setError('Error adding EB reading. Please try again.');
      console.error('Error adding EB reading:', error);
    }
  };

 

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">Add EB Reading</h1>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="dayStartReading">
              Day Start Reading
            </label>
            <input
              type="number"
              id="dayStartReading"
              value={dayStartReading}
              onChange={(e) => setDayStartReading(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
              min="0"
              step="any"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="dayEndReading">
              Day End Reading
            </label>
            <input
              type="number"
              id="dayEndReading"
              value={dayEndReading}
              onChange={(e) => setDayEndReading(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
              min="0"
              step="any"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="totalReading">
              Total Reading (Calculated)
            </label>
            <input
              type="text"
              id="totalReading"
              value={calculateTotalReading()}
              readOnly
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EbReadingForm;
