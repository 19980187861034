import React, { useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enIN } from 'date-fns/locale';


const BatchObservation = () => {
  const initialRow = {
    date: new Date(),
    batchId: '',
    batchFeed: '',
    water: '',
    solvent: '',
    quantity: '',
    material: '',
    remarks: ''
  };

  const [rows, setRows] = useState(Array(8).fill(initialRow));

  const addRow = () => {
    setRows([...rows, initialRow]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleChange = (index, field, value) => {
    const newRows = rows.map((row, i) => (i === index ? { ...row, [field]: value } : row));
    setRows(newRows);
  };

  return (
    <div className="p-2">
      <h1 className="text-2xl font-bold mb-4">Batch Observation</h1>
      <div className="w-full h-[80vh] overflow-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-200 sticky top-0 z-10">
            <tr>
              <th className="py-2 px-4 border-b">DATE</th>
              <th className="py-2 px-4 border-b">Batch ID</th>
              <th className="py-2 px-4 border-b">Batch Feed</th>
              <th className="py-2 px-4 border-b">Water</th>
              <th className="py-2 px-4 border-b">Solvent</th>
              <th className="py-2 px-4 border-b">Quantity</th>
              <th className="py-2 px-4 border-b">Material</th>
              <th className="py-2 px-4 border-b">Remarks</th>
              <th className="py-2 px-4 border-b"></th>
            </tr>
            <tr className="bg-gray-100 sticky top-10 z-10">
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b" colSpan="5">KLR</th>
              <th className="py-2 px-4 border-b"></th>
              <th className="py-2 px-4 border-b"></th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto max-h-96">
            {rows.map((row, index) => (
              <tr key={index} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-green-50'}`}>
                <td className="py-2 px-1 border-b">
                  <DatePicker
                    selected={row.date}
                    onChange={(date) => handleChange(index, 'date', date)}
                    className="w-full py-1 px-2 border rounded"
                    dateFormat="dd-MM-yyyy"
                    locale={enIN}
                  />
                </td>
                <td className="py-2 px-1 border-b">
                  <input
                    type="text"
                    value={row.batchId}
                    onChange={(e) => handleChange(index, 'batchId', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.batchFeed}
                    onChange={(e) => handleChange(index, 'batchFeed', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.water}
                    onChange={(e) => handleChange(index, 'water', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.solvent}
                    onChange={(e) => handleChange(index, 'solvent', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.quantity}
                    onChange={(e) => handleChange(index, 'quantity', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.material}
                    onChange={(e) => handleChange(index, 'material', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    value={row.remarks}
                    onChange={(e) => handleChange(index, 'remarks', e.target.value)}
                    className="w-full py-1 px-2 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    onClick={() => removeRow(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={addRow}
        className="mt-4 bg-blue-500 text-white py-2 px-2 rounded hover:bg-blue-700 flex items-center"
      >
        <FaPlus className="mr-2" />
        Add Row
      </button>
    </div>
  );
};

export default BatchObservation;
