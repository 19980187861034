import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ShowTable = ({ closeTable }) => {
  const [batchNo, setBatchNo] = useState('');
  const [logsheetData, setLogsheetData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/logsheet/all-data`);
        const data = response.data;

        const uniqueBatchNos = [...new Set(data.map(item => item.batchNo))];
        const options = uniqueBatchNos.map(batch => ({ value: batch, label: batch }));
        setBatchOptions(options);

        setAllData(data);
        console.log('showTable', data);
        
      } catch (error) {
        console.error('Error fetching logsheet data:', error);
        toast.error('Failed to load logsheet data.');
      }
    };

    fetchAllData();
  }, []);

  const handleSubmit = () => {
    if (!batchNo) {
      toast.error('Please select a batch number.');
      return;
    }

    setIsSubmitted(true);

    const filteredData = allData.filter(item => item.batchNo === batchNo);
    setLogsheetData(filteredData);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg max-w-full w-full h-full relative">
        <button onClick={closeTable} className="absolute top-4 right-4 text-red-500">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-semibold mb-6 text-blue-600 border-b-2 border-blue-200 pb-2">Logsheet History</h2>

        <div className="flex items-center mb-6 relative">
          <Select
            placeholder="Select Batch No"
            value={batchOptions.find(option => option.value === batchNo)}
            onChange={(selectedOption) => setBatchNo(selectedOption ? selectedOption.value : '')}
            options={batchOptions}
            className="w-1/2 text-sm"
            styles={{
              menu: (provided) => ({
                ...provided,
                maxHeight: '150px', 
                overflowY: 'auto',
              }),
             }}
         
           
          />

          <button
            onClick={handleSubmit}
            className="ml-4 bg-rose-500 text-white px-3 py-1.5 rounded-lg hover:bg-rose-600"
          >
            Submit
          </button>
        </div>

        {isSubmitted && logsheetData.length > 0 && (
         <div className="max-h-[calc(100vh-250px)] overflow-y-auto">
              <table className="table-auto w-full bg-white border-collapse">
              <thead className="table-header bg-blue-900 text-white text-xs">
                <tr>
                  <th rowSpan="2" className="px-2 py-1 border">Batch No</th>
                  <th rowSpan="2" className="px-2 py-1 border">Time</th>
                  <th rowSpan="2" className="px-2 py-1 border">Material</th>
                  <th rowSpan="2" className="px-2 py-1 border">Feed Qty (KL)</th>
                  <th rowSpan="2" className="px-2 py-1 border">From Tank</th>
                  <th className="px-2 py-1 border" colSpan="2">EB Reading (Kwh)</th>
                  <th className="px-2 py-1 border" colSpan="2">LDO (KL)</th>
                  <th rowSpan="2" className="px-2 py-1 border">Reactor</th>
                  <th className="px-2 py-1 border" colSpan="2">Reactor Temp (°C)</th>
                  <th className="px-2 py-1 border"  rowSpan="2" >Reactor Pressure</th>
                  <th className="px-2 py-1 border"  rowSpan="2" >Receiver Pressure</th>
                  <th className="px-2 py-1 border"  rowSpan="2" >Circulating Pump Pressure</th>
                  <th className="px-2 py-1 border" colSpan="2">Boiler Temp (°C)</th>
                  <th className="px-2 py-1 border" colSpan="2">Water Temp (°C)</th>
                  <th rowSpan="2" className="px-2 py-1 border">Others</th>
                  <th className="px-2 py-1 border" colSpan="3">Storage Tank</th>
                  <th rowSpan="2" className="px-2 py-1 border">No. of Operators</th>
                  <th rowSpan="2" className="px-2 py-1 border">Remarks</th>
                </tr>
                <tr>
                  <th className="px-2 py-1 border">Open</th>
                  <th className="px-2 py-1 border">Close</th>
                  <th className="px-2 py-1 border">Open</th>
                  <th className="px-2 py-1 border">Close</th>
                  <th className="px-2 py-1 border">Open</th>
                  <th className="px-2 py-1 border">Close</th>
                  <th className="px-2 py-1 border">Open</th>
                  <th className="px-2 py-1 border">Close</th>
                  <th className="px-2 py-1 border">Open</th>
                  <th className="px-2 py-1 border">Close</th>
                  <th className="px-2 py-1 border">Product</th>
                  <th className="px-2 py-1 border">Qty (KL)</th>
                  <th className="px-2 py-1 border">Tank</th>
                </tr>
              </thead>
              <tbody>
                {logsheetData.map((item, index) => (
                  <tr key={index} className='text-xs'>
                    <td className="px-2 py-1 border">{item.batchNo}</td>
                    <td className="px-2 py-1 border">{new Date(item.Time).toLocaleString()}</td>
                    <td className="px-2 py-1 border">{item.Material}</td>
                    <td className="px-2 py-1 border">{item.Qty.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.From}</td>
                    <td className="px-2 py-1 border">{item.EbOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.EbClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.LdoOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.LdoClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.Reactor}</td>
                    <td className="px-2 py-1 border">{item.ReactorTempOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.ReactorTempClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.ThermopackOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.ThermopackClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.BoilerOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.BoilerClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.WaterOpening.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.WaterClosing.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.Others}</td>
                    <td className="px-2 py-1 border">{item.Product}</td>
                    <td className="px-2 py-1 border">{item.Ptank}</td>
                    <td className="px-2 py-1 border">{item.Pqty.toFixed(2)}</td>
                    <td className="px-2 py-1 border">{item.NoOfOperators}</td>
                    <td className="px-2 py-1 border">{item.Remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowTable;
