import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PurchaseOrderForm from './POForm';

const QuotationsTable = () => {
    const [quotations, setQuotations] = useState([]);
    const [selectedQuotations, setSelectedQuotations] = useState([]);
    const [showPoForm, setShowPoForm] = useState(false);

    const fetchPDQuotations = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/purchasedep/po-rised-quotation`)
            setQuotations(response.data);
        } catch (error) {
            console.error('Error fetching quotations:', error);
        }
    };

    useEffect(() => {
        fetchPDQuotations();
    }, []);

    const toggleQuotationSelection = (quotation) => {
        setSelectedQuotations((prevSelected) => {
            if (prevSelected.includes(quotation)) {
                return prevSelected.filter(item => item !== quotation);
            }
            const newSelected = [...prevSelected, quotation];
            setShowPoForm(true); // Show the template when selecting a quotation
            return newSelected;
        });
    };

    const handleGenerateTemplate = () => {
        if (selectedQuotations.length > 0) {
            setShowPoForm(true);
        } else {
            toast.error("Please select at least one quotation to generate the template.");
        }
    };

    const resetSelectedQuotations = () => {
        setSelectedQuotations([]);
        setShowPoForm(false); // Close the form when resetting selections
        fetchPDQuotations();
    };

    const baseUrl = `${config.apiUrl}/uploads`;

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h2 className="text-xl font-bold  text-center text-[#5F071C] mb-6">Purchase Order</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                    <thead className="bg-teal-600 text-gray-100 text-xs uppercase">
                        <tr className="text-left">
                            <th className="p-3 border-b-2">Select</th>
                            <th className="p-3 border-b-2">PI.No</th>
                            <th className="p-3 border-b-2">Raw Material</th>
                            <th className="p-3 border-b-2">Quantity</th>
                            <th className="p-3 border-b-2">Request Time</th>
                            <th className="p-3 border-b-2">Admin Selected</th>
                            <th className="p-3 border-b-2">File Paths</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotations.length === 0 ? (
                            <tr>
                                <td colSpan="7" className="text-center p-3 ">
                                     Purchase Order Not Found.
                                </td>
                            </tr>
                        ) : (
                            quotations.map((quotation, index) => {
                                const filePaths = JSON.parse(quotation.file_paths || "[]");
                                return (
                                    <tr key={index} className="text-sm hover:bg-gray-100">
                                        <td className="p-3 border-b">
                                            <input
                                                type="checkbox"
                                                onChange={() => toggleQuotationSelection(quotation)}
                                                checked={selectedQuotations.includes(quotation)}
                                                className="h-4 w-4 text-blue-600 rounded focus:ring focus:ring-blue-500"
                                            />
                                        </td>
                                        <td className="p-3 border-b">{quotation.PiNo}</td>
                                        <td className="p-3 border-b">{quotation.RawMaterial}</td>
                                        <td className="p-3 border-b">{quotation.Qty}</td>
                                        <td className="p-3 border-b">{new Date(quotation.RequestTime).toLocaleString()}</td>
                                        <td className="p-3 border-b">{quotation.admin_selected}</td>
                                        <td className="p-3 border-b">
                                            {filePaths.length > 0 ? (
                                                filePaths.map((filePath, idx) => (
                                                    <a 
                                                        key={idx}
                                                        href={`${baseUrl}/${filePath}`}
                                                        target="_blank" 
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500 underline hover:text-blue-700 mr-2"
                                                    >
                                                        {filePath}
                                                    </a>
                                                ))
                                            ) : (
                                                'No file'
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>

            {showPoForm && (
                <PurchaseOrderForm
                    showPoForm={showPoForm}
                    setShowPoForm={setShowPoForm}
                    PiNo={selectedQuotations.map(q => q.PiNo)}
                    resetSelectedQuotations={resetSelectedQuotations}
                    fetchPDQuotations={fetchPDQuotations}
                />
            )}
        </div>
    );
};

export default QuotationsTable;
