import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'
import LoadingSpinner from '../Loading/Loading';

const CustomOption = (props) => {
  return (
    <div {...props.innerProps} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
      <div>{props.data.label}</div>
      <div>{props.data.value}</div>
    </div>
  );
};
const StockEntry = () => {

  const [tankData,setTankData] = useState(null)
  const [materialData,setMaterialData]=useState(null)
  const [materialTankMap, setMaterialTankMap] = useState({});
  const [isSubmit,setIsSubmit] = useState(false);
  const [materialQuantities, setMaterialQuantities] = useState({});
  const [tankCapacities, setTankCapacities] = useState({});
 

  const reactorData = [
    { value: 'R1', label: 'R1' },
    { value: 'R2', label: 'R2' },
    { value: 'R3', label: 'R3' },
    { value: 'R4', label: 'R4' },
    { value: 'R5', label: 'R5' }
  ];
  const [entries, setEntries] = useState([
    {
      material: [{ material: null }],
      tanknames: [{ tankname: null, qty: '' }],
      reactor: null,
      p1Tanks: [{ p1Tankname: null, p1Material: null, p1Qty: '' }],
      otherMaterials: [{ material: null, qty: '' }]
    }
  ]);
  // const [queuedEntries, setQueuedEntries] = useState([]);
  const [queuedData, setQueuedData] = useState({
    batchNo: 0,
    queuedEntries: []
  });
 
const fetchdata = () =>{
  axios.get(`${config.apiUrl}/stock/oil-storage`)
  .then(response => {
    const data = response.data;
    console.log('res',response.data);

    // Create options for materials and tanks
    // const materials = Array.from(new Set(data.map(item => item.oilname))).map(oilname => ({ value: oilname, label: oilname }));
    const tanks = data.map(item => ({ value: item.tankname, label: item.tankname }));
    const materials = data.map(item => ({
      // Oil name
     label: item.oilname,
     value: item.currentquantity // Quantity
   }));

    // Create a map for material-tank relationships
    const materialToTankMap = data.reduce((acc, item) => {
      if (!acc[item.oilname]) {
        acc[item.oilname] = [];
      }
      acc[item.oilname].push({ value: item.tankname, label: item.tankname });
      return acc;
    }, {});
    const initialQuantities = data.reduce((acc, item) => {
      acc[item.oilname] = item.currentquantity;
      return acc;
    }, {});

    const tankCapacities = data.reduce((acc, item) => {
      acc[item.tankname] = item.tankcapacity;
      return acc;
    }, {});
    setMaterialQuantities(initialQuantities); 

    setMaterialData(materials);
    setTankData(tanks);
    setMaterialTankMap(materialToTankMap);
    setTankCapacities(tankCapacities);
    console.log('Material Data:', materialData);
console.log('Tank Data:', tankData);
console.log('Material Tank Map:', materialTankMap);
console.log('tankcccc',tankCapacities);
  })
    .catch(error => {
      console.error('Error fetching stock data:', error);
    });

}


   
  useEffect(() => {
    // Fetch data from API
        // Fetch the next batch number from API
        
    axios.get(`${config.apiUrl}/batchprocess/next-batch-number`)
    .then(response => {
      setQueuedData({
        batchNo: response.data.nextBatchNo
      });
      console.log('respeor',response.data.nextBatchNo);
     
    })
    .catch(error => {
      console.error('Error fetching batch number:', error);
    });
    fetchdata()


  }, []);


  const handleEntryChange = (entryIndex, field, value) => {
    const newEntries = [...entries];
    newEntries[entryIndex][field] = value;
    setEntries(newEntries);
  };

  const handleTankNameChange = (entryIndex, tankIndex, field, value) => {
    const newEntries = [...entries];
    
    if (field === 'qty') {
      const maxQuantity =  newEntries[entryIndex].material[tankIndex].quantity|| 0; // Get the max quantity for the tank
     
  
      if (value <= maxQuantity) {
        newEntries[entryIndex].tanknames[tankIndex][field] = value;
        setEntries(newEntries);
      } else {
        // Optionally, you can show an error message or notification here
        toast.error(`Entered quantity ${value} exceeds available quantity ${maxQuantity}`);
      }
    } else {
      newEntries[entryIndex].tanknames[tankIndex][field] = value;
      setEntries(newEntries);
    }
  };
  
  console.log('entrei',entries);
  const handleP1Change = (entryIndex, p1Index, field, value) => {
    const newEntries = [...entries];
    console.log('val',value);
    console.log('field',field);
    if(field === "p1Material"){
      const selectedMaterial = value ? value.label : null;
      console.log('seelle',selectedMaterial);
      const correspondingTanks = materialTankMap[selectedMaterial] || [];
      console.log('correspondingTanks',correspondingTanks);
      const defaultTank = correspondingTanks.length > 0 ? correspondingTanks[0] : null;
      console.log('defaultTank',defaultTank);
      // Update tanknames with the default tank or null
      newEntries[entryIndex].p1Tanks[p1Index] = defaultTank ? {p1Tankname: defaultTank } : { tankname: null };
      const updatedQuantity = tankCapacities[defaultTank.value] || 0;
      newEntries[entryIndex].p1Tanks[p1Index].capacity = updatedQuantity;
      console.log('updatedQuantity',updatedQuantity);

      newEntries[entryIndex].p1Tanks[p1Index][field] = value;
      setEntries(newEntries);
  
    }
    if (field === 'p1Qty') {
      const maxQuantity =  newEntries[entryIndex].p1Tanks[p1Index].capacity|| 0; 
      // Get the max quantity for the tank
      console.log('maxQuantity',maxQuantity);
  
      if (value <= maxQuantity) {
        newEntries[entryIndex].p1Tanks[p1Index][field] = value;
        setEntries(newEntries);
      } else {
        // Optionally, you can show an error message or notification here
        toast.error(`Entered quantity ${value} tank capacity is  ${maxQuantity}`);
      }
    }
    if (field === 'p1Tankname'){
      const updatedQuantity = tankCapacities[value.value] || 0;
      newEntries[entryIndex].p1Tanks[p1Index].capacity = updatedQuantity;
      console.log('p1TanknameupdatedQuantity',updatedQuantity);

      newEntries[entryIndex].p1Tanks[p1Index][field] = value;
      setEntries(newEntries);
    }
    
    
  };
  const handleMaterialChange = (entryIndex, materialIndex, selectedOption) => {
    const newEntries = [...entries];
    const selectedMaterial = selectedOption ? selectedOption.label : null;
    console.log('selectedMaterial', selectedMaterial);

    // Update the selected material
    newEntries[entryIndex].material[materialIndex] = { material: selectedOption };

    // Find the corresponding tanks for the selected material
    const correspondingTanks = materialTankMap[selectedMaterial] || [];
    const defaultTank = correspondingTanks.length > 0 ? correspondingTanks[0] : null;

    // Update tanknames with the default tank or null
    newEntries[entryIndex].tanknames[materialIndex] = defaultTank ? { tankname: defaultTank } : { tankname: null };

    // Update the quantity
    const updatedQuantity = materialQuantities[selectedMaterial] || 0;
    newEntries[entryIndex].material[materialIndex].quantity = updatedQuantity; // Set the quantity in the material object

    console.log('updatedQuantity', updatedQuantity);
    console.log('newEntries', newEntries);

    setEntries(newEntries);
  };
  

  const handleOtherMaterialChange = (entryIndex, materialIndex, field, value) => {
    const newEntries = [...entries];
    newEntries[entryIndex].otherMaterials[materialIndex][field] = value;
    setEntries(newEntries);
  };

  const addInputToFocusedRow = () => {
    const newEntries = [...entries];
    newEntries[0].material.push({ material: null });
    newEntries[0].tanknames.push({ tankname: null, qty: '' });
    newEntries[0].p1Tanks.push({ p1Tankname: null, p1Material: null, p1Qty: '' });
    newEntries[0].otherMaterials.push({ material: null, qty: '' });
    setEntries(newEntries);
  };
  // const queueEntry = () => {
  //   const filteredEntries = entries.map(entry => {
  //     // Check if material has at least one non-null value
  //     const hasMaterial = entry.material.some(material => material.material !== null);
     
  //     // Check if tanknames has at least one non-null tankname and at least one non-empty qty
  //     const hasTanknames = entry.tanknames.some(tank => tank.tankname !== null && tank.qty !== '');
     
  //     // Check if reactor is not null
  //     const hasReactor = entry.reactor !== null;
     
  //     // Check if p1Tanks has at least one non-null p1Tankname, p1Material, and p1Qty
  //     const hasP1Tanks = entry.p1Tanks.some(p1 => p1.p1Tankname !== null && p1.p1Material !== null && p1.p1Qty !== '');
     
  //     // Check if otherMaterials has at least one non-null material and non-empty qty
  //     const hasOtherMaterials = entry.otherMaterials.some(material => material.material !== null && material.qty !== '');
     
  //     // Only include entries that meet all conditions
  //     return hasMaterial && hasTanknames && hasReactor && hasP1Tanks
  //       ? {
  //           material: entry.material.filter(material => material.material !== null),
  //           tanknames: entry.tanknames.filter(tank => tank.tankname !== null && tank.qty !== ''),
  //           reactor: entry.reactor,
  //           p1Tanks: entry.p1Tanks.filter(p1 => p1.p1Tankname !== null && p1.p1Material !== null && p1.p1Qty !== ''),
  //           otherMaterials: entry.otherMaterials.filter(material => material.material !== null && material.qty !== '')
  //         }
  //       : null;
  //   }).filter(entry => entry !== null);
 
  //   // If no valid entries, show alert
  //   if (filteredEntries.length === 0) {
  //     alert('Please fill out all required fields before submitting.');
  //     return;
  //   }
 
  //   // Update queued data with valid entries
  //   setQueuedData(prevState => ({
  //     ...prevState,
  //     queuedEntries: [...(prevState.queuedEntries || []), ...filteredEntries]
  //   }));
 
  //   // Reset entries
  //   setEntries([
  //     {
  //       material: [{ material: null }],
  //       tanknames: [{ tankname: null, qty: '' }],
  //       reactor: null,
  //       p1Tanks: [{ p1Tankname: null, p1Material: null, p1Qty: '' }],
  //       otherMaterials: [{ material: null, qty: '' }]
  //     }
  //   ]);
  // };
  const queueEntry = () => {
    let hasInvalidEntries = false;
   
    const filteredEntries = entries.map(entry => {
      console.log('Processing entry:', entry);
   
      const hasMaterial = entry.material.some(material => material.material !== null);
   
      const hasValidTanknamesForFilledMaterials = entry.material.every((material, index) => {
        if (material.material !== null) {
          if (index < entry.tanknames.length) {
            const matchingTank = entry.tanknames[index];
            return matchingTank &&
              matchingTank.tankname &&
              matchingTank.tankname.value &&
              matchingTank.qty &&
              matchingTank.qty !== '';
          }
          return false;
        }
        return true;
      });
   
      const hasReactor = entry.reactor !== null;
   
      const validateP1Tanks = (entry) => {
        const isAnyRowFullyFilled = entry.p1Tanks.some(p1 =>
          p1.p1Tankname && p1.p1Tankname.value &&
          p1.p1Material && p1.p1Material.value &&
          p1.p1Qty !== undefined && p1.p1Qty !== ''
        );
   
        const areAllValid = entry.p1Tanks.every(p1 => {
          if (p1.p1Material && p1.p1Material.value) {
            return p1.p1Tankname && p1.p1Tankname.value &&
                   p1.p1Qty !== undefined && p1.p1Qty !== '';
          }
          return true;
        });
   
        return isAnyRowFullyFilled && areAllValid;
      };
   
      const areAllEntriesValid = validateP1Tanks(entry);
      console.log('Are all entries valid:', areAllEntriesValid);
   
      const hasOtherMaterials = entry.otherMaterials.some(material => 
        material.material !== null && 
        material.qty !== ''
      );
   
      if (!(hasMaterial && hasValidTanknamesForFilledMaterials && hasReactor && areAllEntriesValid)) {
        hasInvalidEntries = true;
        console.log('Entry is invalid:', entry);
        return null;
      }
   
      return {
        material: entry.material.filter(material => material.material !== null),
        tanknames: entry.tanknames.filter(tank => tank.tankname !== null && tank.qty !== ''),
        reactor: entry.reactor,
        p1Tanks: entry.p1Tanks.filter(p1 => p1.p1Tankname !== null && p1.p1Material !== null && p1.p1Qty !== ''),
        otherMaterials: entry.otherMaterials.filter(material => material.material !== null && material.qty !== '')
      };
    }).filter(entry => entry !== null);
   
    console.log('Filtered entries:', filteredEntries);
   
    if (hasInvalidEntries) {
      toast('Please fill out all required fields before submitting.');
    } else if (filteredEntries.length > 0) {
      setQueuedData(prevState => ({
        ...prevState,
        queuedEntries: [...(prevState.queuedEntries || []), ...filteredEntries]
      }));
      setEntries([{
        material: [{ material: null }],
        tanknames: [{ tankname: null, qty: '' }],
        reactor: null,
        p1Tanks: [{ p1Tankname: null, p1Material: null, p1Qty: '' }],
        otherMaterials: [{ material: null, qty: '' }]
      }]);
    }
  };
  
  
  
  
  
  
  
 
  
  

  // const handleSubmit = async () => {
  //   setIsSubmit(true)
  //   try {
  //     // Create payload with batch number included
  //     const payload = {
  //       batchNo:queuedData.batchNo, // Include batch number in the payload
  //       entries: queuedData.queuedEntries.map(entry => ({
  //         ...entry,
         
  //       }))
  //     };
  // if (payload.entries.length > 0){
  //     // Send the payload to the backend
  //     const response = await axios.post(`${config.apiUrl}/batchprocess/submit`, payload);
      
  //     toast.success('Data submitted successfully');
  //     setIsSubmit(false)
  //     setQueuedData([]); // Clear the entries after successful submission
  
  //     // Fetch a new batch number after submission
  //     const batchNumberResponse = await axios.get(`${config.apiUrl}/batchprocess/next-batch-number`);
  //     // setBatchNo(batchNumberResponse.data.nextBatchNo);
  //     setQueuedData({
  //       batchNo: batchNumberResponse.data.nextBatchNo
  //     });
  //   }else{
  //     setIsSubmit(false)
  //     toast.error('fill the all fields')
  //   }
      
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.message) {
  //       setIsSubmit(false)
  //       toast.error(`Error submitting data: ${error.response.data.message}`);
  //     } else {
  //       setIsSubmit(false)
  //       toast.error('Error submitting data');
  //     }
  //     console.error('Error submitting data:', error);
  //     setIsSubmit(false)
  //   }
  // };
  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      // Create payload with batch number included
      const payload = {
        batchNo: queuedData.batchNo, // Include batch number in the payload
        entries: queuedData.queuedEntries.map(entry => ({
          ...entry
        }))
      };
      console.log('payload',payload.entries.length);
  
      if (payload.entries.length > 0) {
        // Send the payload to the backend
        const response = await axios.post(`${config.apiUrl}/batchprocess/submit`, payload);
        
        toast.success('Data submitted successfully');
        
        // Clear the entries after successful submission
        setQueuedData({
          batchNo: await axios.get(`${config.apiUrl}/batchprocess/next-batch-number`).then(res => res.data.nextBatchNo),
          queuedEntries: [] // Clear the entries as well
        });
  
      } else {
        toast.error('Fill in all fields');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Error submitting data: ${error.response.data.message}`);
      } else {
        toast.error('Fill in all fields');
      }
      console.error('Error submitting data:', error);
    } finally {
      setIsSubmit(false);
    }
  };
  

  const removeQueuedEntry = (index) => {
    // Clone the queuedEntries and remove the entry at the specified index
    const newQueuedEntries = [...queuedData.queuedEntries];
    const removedEntry = newQueuedEntries.splice(index, 1)[0];
   
    // Update the queuedEntries state
    setQueuedData(prevState => ({
      ...prevState,
      queuedEntries: newQueuedEntries
    }));
   
    // Check if the initial entry is empty
    const initialEntry = entries[0];
    const isInitialEntryNull = initialEntry.material[0].material === null &&
      initialEntry.tanknames[0].tankname === null &&
      initialEntry.tanknames[0].qty === '' &&
      initialEntry.reactor === null &&
      initialEntry.p1Tanks[0].p1Tankname === null &&
      initialEntry.p1Tanks[0].p1Material === null &&
      initialEntry.p1Tanks[0].p1Qty === '' &&
      initialEntry.otherMaterials[0].material === null &&
      initialEntry.otherMaterials[0].qty === '';
   
    // Update the entries state based on the initial entry check
    if (isInitialEntryNull) {
      setEntries([removedEntry]);
    } else {
      // Otherwise, add the removed entry to the existing entries
      setEntries(prevEntries => [...prevEntries, removedEntry]);
    }
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent', // Ensure background is transparent
      border: 'none', // Remove all borders
      borderBottom: '1px solid black', // Add a thicker bottom border with a different color
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the indicator separator
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'gray', // Customize dropdown indicator color if needed
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      width: '200px', // Remove border from the dropdown menu
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px', // Optional: Set a maximum height for the dropdown menu
      overflowY: 'auto', // Optional: Add vertical scrolling if the content overflows
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px',
      backgroundColor: state.isSelected ? '#e0e0e0' : 'white', // Highlight selected item
      '&:hover': {
        backgroundColor: '#f0f0f0', // Change background on hover
      }
    }),
 
   
  };

  console.log('queued',queuedData);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
       {isSubmit && (
        <div className="absolute inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
          <LoadingSpinner />
        </div>
      )}
      <h3 className="text-xl font-bold mb-2" style={{ fontFamily: 'Arial, sans-serif' }}>Batch Process</h3>
      <div className="flex-1 overflow-auto">
        <table className="w-full bg-transparent border-gray-300 relative ">
          <thead className="sticky top-0 bg-gray-800 text-white z-10">
            <tr>
              <th colSpan="4" className=" w-4/12 border border-gray-300 px-3 py-2  text-xs uppercase font-semibold ">Raw Material</th>
              <th  className="w-1/12 px-3 py-2 text-xs uppercase font-semibold">Reactor</th>
              <th colSpan="3" className="w-4/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Product</th>
              <th colSpan="2" className=" w-3/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Others</th>
            
              
            </tr>
            <tr className='text-black'>
              <th className="w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-orange-200">Material</th>
              <th className=" w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-orange-200 ">Tank</th>
              <th className="w-1/12  border border-gray-300 px-3 py-2 text-xs  font-semibold bg-orange-200">CURR QTY(Lts)</th>
              <th className="w-1/12  border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-orange-200">Qty(Lts)</th>
              <th className=" w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-blue-200">Tank</th>
              <th className="w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-green-200">Material</th>
              <th className=" w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold  bg-green-200">Tank</th>
            
              <th className="w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-green-200">Qty(LTs)</th>
              <th className="w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-yellow-200">Material</th>
              <th className="w-1/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-yellow-200">Qty(LTs)</th>
              {/* <th className="  bg-white px-3 py-2 text-xs uppercase font-semibold "></th> */}
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, entryIndex) => (
              <tr key={entryIndex}>
                <td className=" w-1/12 border border-gray-300 px-3 py-2 text-xs bg-orange-200 ">
                {entry.material.map((material, materialIndex) => (
                    <div key={materialIndex} className="flex items-center bg-transparent">
                      <Select
                        className='w-full '
                        styles={customStyles}
                        options={materialData}
                        value={material.material}
                        onChange={(selectedOption) => handleMaterialChange(entryIndex, materialIndex, selectedOption)}
                        components={{ Option: CustomOption }} // Apply custom components
                      />
                    </div>
                  ))}
                </td>
                <td className=" w-1/12 border border-gray-300 px-3 py-2 text-xs bg-orange-200 ">
                  {entry.tanknames.map((tank, tankIndex) => (
                    <div key={tankIndex} className="flex items-center">
                      <Select
                       className='w-full'
                       styles={customStyles}
                        options={tankData}
                        value={tank.tankname}
                        onChange={(selectedOption) => handleTankNameChange(entryIndex, tankIndex, 'tankname', selectedOption)}
                      />
                    </div>
                  ))}
                </td>
                <td className=" w-1/12 border border-gray-300 px-3 py-2 text-xs bg-orange-200 ">
                {entry.material.map((material, materialIndex) => (
                <input
                  key={materialIndex}
                  type="number"
                  readOnly
                  value={material.quantity || 0} /// Show the quantity
                  className="w-full mt-2 border-b border-b-black px-2 py-1 bg-transparent"
                />
              ))}
                </td>
                <td className=" w-1/12 border border-gray-300 px-3 py-2 text-xs bg-orange-200 ">
                {entry.tanknames.map((tank, tankIndex) => (
      <input
        key={tankIndex}
        type="number"
        value={tank.qty || ''}
        onChange={(e) => handleTankNameChange(entryIndex, tankIndex, 'qty', e.target.value)}
        className="w-full mt-2 border-b border-b-black px-2 py-1 bg-transparent"
      />
    ))}
                </td>
                <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-blue-200">
                  <Select
                  className='w-full'
                  styles={customStyles}
                    options={reactorData}
                    value={entry.reactor}
                    onChange={(selectedOption) => handleEntryChange(entryIndex, 'reactor', selectedOption)}
                  />
                  
                </td>
                <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-green-200">
      {entry.p1Tanks.map((p1, p1Index) => (
        <Select
          className='w-full'
          styles={customStyles}
          key={p1Index}
          options={materialData}
          value={p1.p1Material}
          onChange={(selectedOption) => handleP1Change(entryIndex, p1Index, 'p1Material', selectedOption)}
          
        />
      ))}
    </td>
    <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-green-200">
      {entry.p1Tanks.map((p1, p1Index) => (
        <div key={p1Index} className="flex items-center">
          <Select
            className='w-full'
            styles={customStyles}
            options={tankData}
            value={p1.p1Tankname}
                        onChange={(selectedOption) => handleP1Change(entryIndex, p1Index, 'p1Tankname', selectedOption)}
          />
        </div>
      ))}
    </td>
    <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-green-200">
      {entry.p1Tanks.map((p1, p1Index) => (
        <input
          key={p1Index}
          type="number"
          value={p1.p1Qty || ''}
          onChange={(e) => handleP1Change(entryIndex, p1Index, 'p1Qty', e.target.value)}
          className="w-full mt-2 px-2 py-1 bg-transparent border-b border-b-black"
        />
      ))}
    </td>



               
                <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-yellow-200">
                  {entry.otherMaterials.map((material, materialIndex) => (
                    <div key={materialIndex} className="flex items-center">
                      <Select
                       className='w-full'
                       styles={customStyles}
                        options={materialData}
                        value={material.material}
                        onChange={(selectedOption) => handleOtherMaterialChange(entryIndex, materialIndex, 'material', selectedOption)}
                      />
                    </div>
                  ))}
                </td>
                <td className="w-1/12 border border-gray-300 px-3 py-2 text-xs bg-yellow-200">
                  {entry.otherMaterials.map((material, materialIndex) => (
                    <input
                      key={materialIndex}
                      type="text"
                      value={material.qty}
                      onChange={(e) => handleOtherMaterialChange(entryIndex, materialIndex, 'qty', e.target.value)}
                      className="w-full mt-2  px-2 py-1 bg-transparent border-b border-b-black"
                    />
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-2 text-right">
        <button
                  className="bg-gray-800 hover:bg-gray-900 text-white font-semibold py-1 px-2 rounded mr-2"
                  onClick={addInputToFocusedRow}
                >
                  Add Row
                </button>
          <button
            className="bg-gray-800 hover:bg-gray-900 text-white font-semibold py-1 px-2 rounded mr-2"
            onClick={queueEntry}
          >
           Ok
          </button>
          
        </div>
        
        <div className="mt-4">
          
        <table className="w-full bg-transparent border-gray-300 overflow-auto z-0">
  <thead className="sticky top-0 bg-gray-800 text-white">
    <tr>
      <th rowSpan="2" className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Batchno</th>
      <th colSpan="3" className="border w-4/12 border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Raw Material</th>
      <th colSpan="1" className="w-1/12 px-3 py-2 text-xs uppercase font-semibold">Reactor</th>
      <th colSpan="3" className="w-4/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Product</th>
      <th colSpan="2" className="w-3/12 border border-gray-300 px-3 py-2 text-xs uppercase font-semibold">Others</th>
      <th className="bg-white text-xs "></th>
      
    </tr>
    <tr>
      <th className="border border-gray-300 px-3 py-2 uppercase text-xs text-gray-800 bg-orange-200">Material</th>
      <th className="border border-gray-300 px-3 py-2 uppercase text-xs text-gray-800 bg-orange-200">Tank</th>
      <th className="border border-gray-300 px-3 py-2 uppercase text-xs text-gray-800 bg-orange-200">Qty(LTs)</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-blue-200 text-gray-800">Tank</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-green-200 text-gray-800">Material</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-green-200 text-gray-800">Tank</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-green-200 text-gray-800">Qty(LTs)</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-yellow-200 text-gray-800">Material</th>
      <th className="border border-gray-300 px-3 py-2 text-xs uppercase font-semibold bg-yellow-200 text-gray-800">Qty(LTs)</th>
      <th className="  text-xs  bg-white"></th>
    </tr>
  </thead>
  <tbody className='text-center'>
  {
    queuedData.queuedEntries && queuedData.queuedEntries.length > 0 ? (
      queuedData.queuedEntries.map((entry, entryIndex) => {
        // Set default values if properties are undefined
        const materials = entry.material || [];
        const tanknames = entry.tanknames || [];
        const p1Tanks = entry.p1Tanks || [];
        const otherMaterials = entry.otherMaterials || [];

        // Calculate the maximum number of rows for the current entry
        const maxRows = Math.max(materials.length, tanknames.length, p1Tanks.length, otherMaterials.length);

        // Create an array to represent rows
        return Array.from({ length: maxRows }).map((_, rowIndex) => (
          <tr key={`${entryIndex}-${rowIndex}`}>
            {/* Display the batch number only once in the first row of the table */}
            {entryIndex === 0 && rowIndex === 0 && (
              <td className="border border-gray-300 px-3 py-2 text-xs bg-gray-100" rowSpan={queuedData.queuedEntries.reduce((acc, cur) => {
                const curRows = Math.max(
                  (cur.material || []).length,
                  (cur.tanknames || []).length,
                  (cur.p1Tanks || []).length,
                  (cur.otherMaterials || []).length
                );
                return acc + curRows;
              }, 0)}>
                {queuedData.batchNo}
              </td>
            )}

            <td className="border border-gray-300 px-3 py-2 text-xs bg-orange-200">
              {materials[rowIndex]?.material?.label || ''}
            </td>
            <td className="border border-gray-300 px-3 py-2 text-xs bg-orange-200">
              {tanknames[rowIndex]?.tankname?.label || ''}
            </td>
            <td className="border border-gray-300 px-3 py-2 text-xs bg-orange-200">
              {tanknames[rowIndex]?.qty || ''}
            </td>
            {rowIndex === 0 && entry.reactor && (
              <td className="border border-gray-300 px-3 py-2 text-xs bg-blue-200" rowSpan={maxRows}>
                {entry.reactor?.label || ''}
              </td>
            )}
             <td className="border border-gray-300 px-3 py-2 text-xs bg-green-200">
              {p1Tanks[rowIndex]?.p1Material?.label || ''}
            </td>
            <td className="border border-gray-300 px-3 py-2 text-xs bg-green-200">
              {p1Tanks[rowIndex]?.p1Tankname?.label || ''}
            </td>
           
            <td className="border border-gray-300 px-3 py-2 text-xs bg-green-200">
              {p1Tanks[rowIndex]?.p1Qty || ''}
            </td>
            <td className="border border-gray-300 px-3 py-2 text-xs bg-yellow-200">
              {otherMaterials[rowIndex]?.material?.label || ''}
            </td>
            <td className="border border-gray-300 px-3 py-2 text-xs bg-yellow-200">
              {otherMaterials[rowIndex]?.qty || ''}
            </td>
            {rowIndex === 0 && (
              <td className="  text-xs" rowSpan={maxRows}>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded"
                  onClick={() => removeQueuedEntry(entryIndex)}
                >
                  X
                </button>
              </td>
            )}
          </tr>
        ));
      })
    ) : (
      <tr>
        <td colSpan="11" className=" px-3 py-2 text-xs bg-white">
          No entries available
        </td>
      </tr>
    )
  }
</tbody>


</table>
  
       </div>
        <div className='text-right mt-2'>
          <button
           className={`${isSubmit?`bg-gray-700`:'bg-gray-800'} hover:bg-gray-900 text-white font-semibold py-1 px-2 rounded mr-2`}
            onClick={handleSubmit}
            disabled={isSubmit}
          >
            Submit
          </button>
          </div>
      </div>
    </div>
  );
};
export default StockEntry;