import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const SaveModal = ({ closeModal, setIsSaving, handleSaveData, selectedDataType, setSelectedDataType, setIsOutWard }) => {
  const [inwardData, setInwardData] = useState([]);
  const [outwardData, setOutwardData] = useState([]);

  // Fetch inward and outward data
  useEffect(() => {
    axios.get(`${config.apiUrl}/saveio/getsaveinward`)
      .then((response) => setInwardData(response.data))
      .catch((err) => console.error(err));

    axios.get(`${config.apiUrl}/saveio/getsaveoutward`)
      .then((response) => setOutwardData(response.data))
      .catch((err) => console.error(err));
  }, []);

  const handleCheckboxClick = (id) => {
    axios.get(`${config.apiUrl}/saveio/${selectedDataType}/${id}`)
      .then((response) => {
        const fetchedData = response.data;

        if (selectedDataType === 'outward') {
          setIsOutWard(true);
        }

        // Format the date to dd-mm-yyyy
        const formattedDate = new Date(fetchedData.date).toLocaleDateString('en-GB').split('/').reverse().join('-');

        // Map fetched data to formData
        const updatedFormData = {
          purchaseOrderNo: fetchedData.purchaseOrderNo || '',
          invoiceNo: fetchedData.invoiceNo || '',
          ewayBillNo: fetchedData.ewayBillNo || '',
          date: formattedDate,
          vehicleNo: fetchedData.vehicle_no || '',
          driverName: fetchedData.driver_name || '',
          inandout: fetchedData.inandout || '',
          location: fetchedData.location || '',
          fuelName: fetchedData.fuel_name || '',
          grossWeight: fetchedData.gross_weight.toFixed(2) || '0.00',
          tareWeight: fetchedData.tare_weight.toFixed(2) || '0.00',
          netWeight: fetchedData.net_weight.toFixed(2) || '0.00',
          uom: fetchedData.uom || '',
          color: fetchedData.color || '',
          density: fetchedData.density.toFixed(2) || '0.00',
          remarks: fetchedData.remarks || '',
          issave: false
        };

        handleSaveData(updatedFormData);
        setIsSaving(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error fetching data!');
        setIsSaving(true);
      });
  };

  const handleCheckboxChange = (e) => {
    setSelectedDataType(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-xl shadow-lg w-[50vw] h-[70vh] relative overflow-hidden">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} className="h-5 w-5 text-red-500" />
        </button>
        <h3 className="text-xl font-semibold mb-4">Saved Rows</h3>

        <div className="mb-4">
  <label className="mr-4 ">
    <input
      type="radio"
      value="inward"
      checked={selectedDataType === 'inward'}
      onChange={handleCheckboxChange}
      className=''
    />
    <span className="ml-2">Inward Data</span> {/* Added span for gap */}
  </label>
  <label className="">
    <input
      type="radio"
      value="outward"
      checked={selectedDataType === 'outward'}
      onChange={handleCheckboxChange}
    />
    <span className="ml-2">Outward Data</span> {/* Added span for gap */}
  </label>
</div>


        {/* Table for selected data type */}
        <div className="overflow-x-auto h-[calc(100%-3rem)] ">
          <div className="overflow-y-auto h-full max-h-[300px]">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium text-gray-900 w-16">S.No</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium text-gray-900 w-32">Date</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium text-gray-900 w-40">Invoice No</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium text-gray-900 w-40">Fuel Name</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium text-gray-900 w-20">Select</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-xs">
                {(selectedDataType === 'inward' ? inwardData : outwardData).length > 0 ? (
                  (selectedDataType === 'inward' ? inwardData : outwardData).map((row, index) => (
                    <tr key={row.id}>
                      <td className="border border-gray-400 p-2  text-gray-900">{index + 1}</td>
                      <td className="border border-gray-400 p-2  text-gray-900">{formatDate(row.date)}</td>
                      <td className="border border-gray-400 p-2  text-gray-900">{row.invoiceNo}</td>
                      <td className="border border-gray-400 p-2  text-gray-900">{row.fuel_name}</td>
                      <td className="border border-gray-400 p-2  text-gray-900">
                        <input type="checkbox" onChange={() => handleCheckboxClick(row.id)} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center p-4 text-sm text-gray-500">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModal;
