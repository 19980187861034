import React from 'react';

const TankTable = ({ stockData }) => {
  console.log(stockData);
  
  // Define a mapping for material types to their corresponding styles and labels
  const materialTypeStyles = {
    'rawmaterial': {
      label: 'Raw Material',
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-800'
    },
    'product': {
      label: 'Product',
      bgColor: 'bg-green-100',
      textColor: 'text-green-800'
    },
    'solvent': {
      label: 'Solvent',
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-800'
    },
    'productaftermixing': {
      label: 'Product After Mixing',
      bgColor: 'bg-purple-100',
      textColor: 'text-purple-800'
    },
    'Product After Mixing': {
      label: 'Product After Mixing',
      bgColor: 'bg-purple-100',
      textColor: 'text-purple-800'
    },
    'I/P': {
      label: 'I/P',
      bgColor: 'bg-teal-200',
      textColor: 'text-gray-800'
    },
    'default': {
      label: 'Unknown',
      bgColor: 'bg-gray-100',
      textColor: 'text-gray-800'
    }
  };

  return (
    <div className="overflow-y-auto max-h-full w-full">
      <table className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead className="bg-gradient-to-r from-blue-400 to-purple-500 text-white text-center text-xs whitespace-nowrap sticky top-0 z-10">
          <tr>
            <th scope="col" className="px-2 py-2">S.No</th>
            <th scope="col" className="px-2 py-2">Material</th>
            <th scope="col" className="px-2 py-2">Tank</th>
            <th scope="col" className="px-2 py-2">Tank Capacity (KL)</th>
            <th scope="col" className="px-2 py-2">Used Space (KL)</th>
            <th scope="col" className="px-2 py-2">Free Space (KL)</th>
            <th scope="col" className="px-2 py-2">Material Type</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-xs text-center text-gray-500">
          {stockData.length > 0 ? (
            stockData.map((tank, index) => {
              // Ensure capacity and currentquantity are valid numbers
              const capacity = Number(tank.capacity) || 0;
              const currentquantity = Number(tank.currentquantity) || 0;
              const freeSpace = (capacity - currentquantity).toFixed(2);

              // Get the style for the material type, or use 'default' if not found
              const { label, bgColor, textColor } = materialTypeStyles[tank.materialtype.toLowerCase()] || materialTypeStyles['default'];

              return (
                <tr key={tank.id} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                  <td className="px-2 py-2 whitespace-nowrap">{index + 1}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{tank.oilname}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{tank.tankname}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{capacity.toFixed(2)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{currentquantity.toFixed(2)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{freeSpace}</td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${bgColor} ${textColor}`}>
                      {label}
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7" className="px-2 py-2 text-center text-gray-500">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TankTable;
